import { ColumnModel, PredicateModel } from "@syncfusion/ej2-react-grids";
import { Predicate } from "@syncfusion/ej2-data";
import { QueryAttributeJM, RAFOperator } from "../models/Common/QueryAttributeJM";
import { isArray, isNotNullAndUndefined, isNullOrUndefined, IsNullOrWhiteSpace, setZeroHours } from "./utils";
import { MomentFormats, SFColumnType } from "../../constants/Common/Constants";
import moment from "moment";
import { RAFCustomFilter, RAFCustomOperator } from "../RAFViewPanels/RAFFilterColumn/RAFCustomFilter";
import { RuleModel } from "@syncfusion/ej2-react-querybuilder";
export const formatSFDateOption: object = { type: 'date', format: 'dd/MM/yyyy' };

//export function getRAFOperatorBySFOperator(sfOperator: string): string {
//    let retVal: string = "";
//    switch (sfOperator) {
//        case "startswith": retVal = "BeginsWith"; break;
//        case "endswith": retVal = "EndsWith"; break;
//        case "contains": retVal = "Contains"; break;
//        case "equal": retVal = "Equal"; break;
//        case "notequal": retVal = "NotEqual"; break;
//        case "greaterthan": retVal = "GreaterThan"; break;
//        case "greaterthanorequal": retVal = "GreaterThanOrEqual"; break;
//        case "lessthan": retVal = "LesserThan"; break;
//        case "lessthanorequal": retVal = "LesserThanOrEqual"; break;
//        case "Between": retVal = "Between"; break;
//        case "NotBetween": retVal = "NotBetween"; break;
//        case "in": retVal = "In"; break;
//        case "notin": retVal = "NotIn"; break;
//        case "isempty": retVal = "IsEmpty"; break;
//        case "isnotempty": retVal = "IsNotEmpty"; break;
//        case "isnull": retVal = "IsNull"; break;
//        case "isnotnull": retVal = "IsNotNull"; break;
//        default:
//    }
//    return retVal;
//}

export function getRAFOperatorBySFOperator(sfOperator: string): RAFCustomOperator {
    let retVal: RAFCustomOperator;
    switch (sfOperator) {
        case "startswith": retVal = RAFCustomOperator.BeginsWith; break;
        case "endswith": retVal = RAFCustomOperator.EndsWith; break;
        case "contains": retVal = RAFCustomOperator.Contains; break;
        case "equal": retVal = RAFCustomOperator.Equal; break;
        case "notequal": retVal = RAFCustomOperator.NotEqual; break;
        case "greaterthan": retVal = RAFCustomOperator.GreaterThan; break;
        case "greaterthanorequal": retVal = RAFCustomOperator.GreaterThanOrEqual; break;
        case "lessthan": retVal = RAFCustomOperator.LesserThan; break;
        case "lessthanorequal": retVal = RAFCustomOperator.LesserThanOrEqual; break;
        case "Between": retVal = RAFCustomOperator.Between; break;
        case "NotBetween": retVal = RAFCustomOperator.NotBetween; break;
        case "in": retVal = RAFCustomOperator.In; break;
        case "notin": retVal = RAFCustomOperator.NotIn; break;
        //case "isempty": retVal = RAFCustomOperator.IsEmpty; break;
        case "isempty": retVal = RAFCustomOperator.IsNullOrEmpty; break;
        case "isnotempty": retVal = RAFCustomOperator.IsNotEmpty; break;
        case "notcontains": retVal = RAFCustomOperator.NotContains; break;
        case "isnull": retVal = RAFCustomOperator.IsNull; break;
        case "isnotnull": retVal = RAFCustomOperator.IsNotNull; break;
        default:
    }
    return retVal;
}

export function getSFOperatorByRAFOperator(rafOperator: RAFCustomOperator): string {
    let retVal = "";
    switch (rafOperator) {
        case RAFCustomOperator.BeginsWith: retVal = "startswith"; break;
        case RAFCustomOperator.EndsWith: retVal = "endswith"; break;
        case RAFCustomOperator.Contains: retVal = "contains"; break;
        case RAFCustomOperator.Equal: retVal = "equal"; break;
        case RAFCustomOperator.NotEqual: retVal = "notequal"; break;
        case RAFCustomOperator.GreaterThan: retVal = "greaterthan"; break;
        case RAFCustomOperator.GreaterThanOrEqual: retVal = "greaterthanorequal"; break;
        case RAFCustomOperator.LesserThan: retVal = "lessthan"; break;
        case RAFCustomOperator.LesserThanOrEqual: retVal = "lessthanorequal"; break;
        case RAFCustomOperator.Between: retVal = "Between"; break;
        case RAFCustomOperator.NotBetween: retVal = "NotBetween"; break;
        case RAFCustomOperator.In: retVal = "in"; break;
        case RAFCustomOperator.NotIn: retVal = "notin"; break;
        case RAFCustomOperator.IsEmpty: retVal = "isempty"; break;
        case RAFCustomOperator.IsNullOrEmpty: retVal = "isempty"; break;
        case RAFCustomOperator.IsNotEmpty: retVal = "isnotempty"; break;
        case RAFCustomOperator.NotContains: retVal = "notcontains"; break;
        case RAFCustomOperator.IsNull: retVal = "isnull"; break;
        case RAFCustomOperator.IsNotNull: retVal = "isnotnull"; break;
        default:
    }
    return retVal;
}

export function getDisplayNameBySFOperator(sfOperator: string): string {
    let retVal: string = sfOperator;
    switch (sfOperator) {
        case "startswith": retVal = "begins with"; break;
        case "endswith": retVal = "ends with"; break;
        case "contains": retVal = "contains"; break;
        case "equal": retVal = "is"; break;
        case "notequal": retVal = "is not"; break;
        case "greaterthan": retVal = "greater than"; break;
        case "greaterthanorequal": retVal = "greater than or equal"; break;
        case "lessthan": retVal = "lesser than"; break;
        case "lessthanorequal": retVal = "lesser than or equal"; break;
        case "Between": retVal = "between"; break;
        case "NotBetween": retVal = "not between"; break;
        case "in": retVal = "in"; break;
        case "notin": retVal = "not in"; break;
        case "isempty": retVal = "is empty"; break;
        case "isnotempty": retVal = "is not empty"; break;
        case "notcontains": retVal = "does not contain"; break;
        case "isnull": retVal = "is null"; break;
        case "isnotnull": retVal = "is not null"; break;
        default:
    }
    return retVal;
}

export function getDisplayNameByOperator(operator: RAFCustomOperator): string {
    let retVal = "";
    switch (operator) {
        case RAFCustomOperator.BeginsWith: retVal = "begins with"; break;
        case RAFCustomOperator.EndsWith: retVal = "ends with"; break;
        case RAFCustomOperator.Contains: retVal = "contains"; break;
        case RAFCustomOperator.Equal: retVal = "is"; break;
        case RAFCustomOperator.NotEqual: retVal = "is not"; break;
        case RAFCustomOperator.GreaterThan: retVal = "greater than"; break;
        case RAFCustomOperator.GreaterThanOrEqual: retVal = "greater than or equal"; break;
        case RAFCustomOperator.LesserThan: retVal = "lessthan"; break;
        case RAFCustomOperator.LesserThanOrEqual: retVal = "less than or equal"; break;
        case RAFCustomOperator.Between: retVal = "between"; break;
        case RAFCustomOperator.NotBetween: retVal = "not between"; break;
        case RAFCustomOperator.In: retVal = "in"; break;
        case RAFCustomOperator.NotIn: retVal = "not in"; break;
        case RAFCustomOperator.IsEmpty: retVal = "is empty"; break;
        case RAFCustomOperator.IsNullOrEmpty: retVal = "is empty"; break;
        case RAFCustomOperator.IsNotEmpty: retVal = "is not empty"; break;
        case RAFCustomOperator.NotContains: retVal = "does not contain"; break;
        case RAFCustomOperator.IsNull: retVal = "is null"; break;
        case RAFCustomOperator.IsNotNull: retVal = "is not null"; break;
        default:
    }
    return retVal;
}

/*
export function getCustomFilterBySFGridFilter(where: PredicateModel[]): RAFCustomFilter {
    let outVal: RAFCustomFilter = {};
    if (isNotNullAndUndefined(where) && where.length > 0) {
        const whereObj: PredicateModel = where[0];
        if (isNotNullAndUndefined(whereObj) && isNotNullAndUndefined(whereObj["condition"]) && whereObj["predicates"]) {
            outVal.Condition = (whereObj["condition"] === "and") ? RAFOperator.AndCondition : RAFOperator.OrCondition;
            const predicateModels: PredicateModel[] = whereObj["predicates"];
            if (isNotNullAndUndefined(predicateModels) && predicateModels.length > 0) {
                outVal.Rules = [];
                for (var i = 0; i < predicateModels.length; i++) {
                    const fieldPredicate: PredicateModel = predicateModels[i];
                    if (isNotNullAndUndefined(fieldPredicate["predicates"])) {



                        const predicateModels2: PredicateModel[] = fieldPredicate["predicates"];

                        for (var j = 0; j < predicateModels2.length; j++) {
                            const fieldPredicate2: PredicateModel = predicateModels2[j];
                            if (isNotNullAndUndefined(fieldPredicate2["predicates"])) {
                                outVal.Rules.push(getCustomFilterBySFGridFilter(fieldPredicate2["predicates"]))
                            }
                            else if (isNotNullAndUndefined(fieldPredicate2.value)) {
                                let objField: RAFCustomFilter = {};
                                objField.Field = fieldPredicate2.field;
                                objField.Operator = getRAFOperatorBySFOperator(fieldPredicate2.operator);
                                let filterVal: string[] = [];

                                if (moment(fieldPredicate2.value.toString(), moment.ISO_8601, true).isValid())
                                    filterVal.push(moment(fieldPredicate2.value.toString().slice(0, -1), moment.ISO_8601, true).format(MomentFormats.DATE));
                                else
                                    filterVal.push(fieldPredicate2.value.toString());
                                objField.Value = filterVal;
                                outVal.Rules.push(objField);
                            }
                        }





                    }
                    else if (isNotNullAndUndefined(fieldPredicate.value)) {
                        let objField: RAFCustomFilter = {};
                        objField.Field = fieldPredicate.field;
                        objField.Operator = getRAFOperatorBySFOperator(fieldPredicate.operator);
                        let filterVal: string[] = [];
                        if (moment(fieldPredicate.value.toString(), moment.ISO_8601, true).isValid())
                            filterVal.push(moment(fieldPredicate.value.toString().slice(0, -1), moment.ISO_8601, true).format(MomentFormats.DATE));
                        else
                            filterVal.push(fieldPredicate.value.toString());
                        objField.Value = filterVal;
                        outVal.Rules.push(objField);
                    }
                }
            }
        }
    }
    return outVal;
}
*/

export function getCustomFilterBySFGridFilter(where: Predicate[]): RAFCustomFilter {
    let outVal: RAFCustomFilter = {};
    if (isNotNullAndUndefined(where) && where.length > 0) {
        const whereObj: Predicate = where[0];
        if (isNotNullAndUndefined(whereObj) && isNotNullAndUndefined(whereObj["condition"]) && whereObj["predicates"]) {
            outVal.Condition = (whereObj["condition"] === "and") ? RAFOperator.AndCondition : RAFOperator.OrCondition;
            const predicateModels: Predicate[] = whereObj["predicates"];
            if (isNotNullAndUndefined(predicateModels) && predicateModels.length > 0) {
                outVal.Rules = [];
                for (var i = 0; i < predicateModels.length; i++) {
                    const fieldPredicate: Predicate = predicateModels[i];
                    if (isNotNullAndUndefined(fieldPredicate["predicates"])) {



                        const predicateModels2: Predicate[] = fieldPredicate["predicates"];

                        for (var j = 0; j < predicateModels2.length; j++) {
                            const fieldPredicate2: Predicate = predicateModels2[j];
                            if (isNotNullAndUndefined(fieldPredicate2["predicates"])) {
                                outVal.Rules.push(getCustomFilterBySFGridFilter(fieldPredicate2["predicates"]));
                            }
                            else if (isNotNullAndUndefined(fieldPredicate2.value) && fieldPredicate2.value !== 'None') {
                                let objField: RAFCustomFilter = {};
                                objField.Field = fieldPredicate2.field;
                                objField.Operator = getRAFOperatorBySFOperator(fieldPredicate2.operator);
                                let filterVal = [];

                                let objValue = fieldPredicate2.value;
                                if (isNotNullAndUndefined(fieldPredicate2['ejpredicate']) && isNotNullAndUndefined(fieldPredicate2['ejpredicate']['value'])) {
                                    objValue = fieldPredicate2['ejpredicate']['value'];
                                }
                                if (moment(objValue.toString(), moment.ISO_8601, true).isValid() && moment(objValue.toString().slice(0, -1), moment.ISO_8601, true).isValid()) {
                                    //const fieldPredicate2Value = setZeroHours(new Date(fieldPredicate2.value as any));
                                    ////filterVal.push(moment(fieldPredicate2.value.toString().slice(0, -1), moment.ISO_8601, true).format(MomentFormats.DATE));
                                    //filterVal.push(fieldPredicate2Value.toISOString());
                                    filterVal.push(moment(objValue as any).toISOString());
                                }
                                else
                                    filterVal.push(fieldPredicate2.value.toString());
                                objField.Value = filterVal;
                                outVal.Rules.push(objField);
                            } else if (isNotNullAndUndefined(fieldPredicate2.value) && fieldPredicate2.value === 'None') {
                                let objField: RAFCustomFilter = {};
                                objField.Field = fieldPredicate2.field;
                                objField.Operator = RAFCustomOperator.IsNull;
                                outVal.Rules.push(objField);
                            }
                        }
                    }
                    else if (isNotNullAndUndefined(fieldPredicate.value) && fieldPredicate.value !== 'None') {
                        let objField: RAFCustomFilter = {};
                        objField.Field = fieldPredicate.field;
                        objField.Operator = getRAFOperatorBySFOperator(fieldPredicate.operator);
                        let filterVal = [];

                        let objValue = fieldPredicate.value;
                        if (isNotNullAndUndefined(fieldPredicate['ejpredicate']) && isNotNullAndUndefined(fieldPredicate['ejpredicate']['value'])) {
                            objValue = fieldPredicate['ejpredicate']['value'];
                        }

                        if (moment(objValue.toString(), moment.ISO_8601, true).isValid() && moment(objValue.toString().slice(0, -1), moment.ISO_8601, true).isValid()) {
                            ////filterVal.push(moment(fieldPredicate.value.toString().slice(0, -1), moment.ISO_8601, true).format(MomentFormats.DATE));
                            //const fieldPredicate2Value = setZeroHours(new Date(fieldPredicate.value as any));
                            //filterVal.push(fieldPredicate2Value.toISOString());
                            filterVal.push(moment(objValue as any).toISOString());
                        }
                        else
                            filterVal.push(fieldPredicate.value.toString());

                        objField.Value = filterVal;
                        outVal.Rules.push(objField);
                    } else if (isNotNullAndUndefined(fieldPredicate.value) && fieldPredicate.value === 'None') {
                        let objField: RAFCustomFilter = {};
                        objField.Field = fieldPredicate.field;
                        objField.Operator = RAFCustomOperator.IsNull;
                        outVal.Rules.push(objField);
                    }
                }
            }

        }
        else {
            outVal.Rules = [];
            outVal.Condition = RAFOperator.AndCondition;
            for (var j = 0; j < where.length; j++) {
                const fieldPredicate2: Predicate = where[j];
                if (isNotNullAndUndefined(fieldPredicate2["predicates"])) {
                    outVal.Rules.push(getCustomFilterBySFGridFilter(fieldPredicate2["predicates"]));
                }
                else if (isNotNullAndUndefined(fieldPredicate2.value)) {

                    let objField: RAFCustomFilter = {};
                    objField.Field = fieldPredicate2.field;
                    objField.Operator = getRAFOperatorBySFOperator(fieldPredicate2.operator);
                    let filterVal: string[] = [];

                    let objValue = fieldPredicate2.value;
                    if (isNotNullAndUndefined(fieldPredicate2['ejpredicate']) && isNotNullAndUndefined(fieldPredicate2['ejpredicate']['value'])) {
                        objValue = fieldPredicate2['ejpredicate']['value'];
                    }


                    if (moment(objValue.toString(), moment.ISO_8601, true).isValid() && moment(objValue.toString().slice(0, -1), moment.ISO_8601, true).isValid()) {
                        //filterVal.push(moment(fieldPredicate2.value.toString().slice(0, -1), moment.ISO_8601, true).format(MomentFormats.DATE));
                        filterVal.push(moment(objValue as any).toISOString());
                    }
                    else
                        filterVal.push(fieldPredicate2.value.toString());

                    objField.Value = filterVal;
                    outVal.Rules.push(objField);
                }
            }

        }
    }
    return outVal;
}

export function getCustomFilterBySFGridFilter1(where: PredicateModel[]): RAFCustomFilter {
    //console.log('where', where);
    let outVal: RAFCustomFilter = {};
    if (isNotNullAndUndefined(where) && where.length > 0) {
        outVal.Rules = [];
        for (var i = 0; i < where.length; i++) {
            const fieldPredicate: PredicateModel = where[i];
            if (isNotNullAndUndefined(fieldPredicate["predicates"])) {



                const predicateModels2: PredicateModel[] = fieldPredicate["predicates"];

                for (var j = 0; j < predicateModels2.length; j++) {
                    const fieldPredicate2: PredicateModel = predicateModels2[j];
                    if (isNotNullAndUndefined(fieldPredicate2["predicates"])) {
                        outVal.Rules.push(getCustomFilterBySFGridFilter(fieldPredicate2["predicates"]));
                    }
                    else if (isNotNullAndUndefined(fieldPredicate2.value)) {
                        let objField: RAFCustomFilter = {};
                        objField.Field = fieldPredicate2.field;
                        objField.Operator = getRAFOperatorBySFOperator(fieldPredicate2.operator);
                        let filterVal: string[] = [];

                        if (moment(fieldPredicate2.value.toString(), moment.ISO_8601, true).isValid() && moment(fieldPredicate2.value.toString(), moment.ISO_8601, true).isValid())
                            filterVal.push(moment(fieldPredicate2.value.toString(), moment.ISO_8601, true).format(MomentFormats.DATE));
                        else
                            filterVal.push(fieldPredicate2.value.toString());
                        objField.Value = filterVal;
                        outVal.Rules.push(objField);
                    }
                }





            }
            else if (isNotNullAndUndefined(fieldPredicate.value)) {
                let objField: RAFCustomFilter = {};
                objField.Field = fieldPredicate.field;
                objField.Operator = getRAFOperatorBySFOperator(fieldPredicate.operator);
                let filterVal: string[] = [];
                if (moment(fieldPredicate.value.toString(), moment.ISO_8601, true).isValid() && moment(fieldPredicate.value.toString(), moment.ISO_8601, true).isValid())
                    filterVal.push(moment(fieldPredicate.value.toString(), moment.ISO_8601, true).format(MomentFormats.DATE));
                else
                    filterVal.push(fieldPredicate.value.toString());
                objField.Value = filterVal;
                outVal.Rules.push(objField);
            }
        }
    }
    else {
        return null;
    }
    return outVal;
}

export function getGridFilterByCustomFilter(rule: RAFCustomFilter): PredicateModel[] {
    let outVal: PredicateModel[] = [];
    if (isNotNullAndUndefined(rule)) {
        if (rule.Rules && isNotNullAndUndefined(rule.Rules)) {
            const ruleModels: RAFCustomFilter[] = rule.Rules;
            for (var i = 0; i < ruleModels.length; i++) {
                const objField: RAFCustomFilter = ruleModels[i];
                if (isNotNullAndUndefined(objField) && !IsNullOrWhiteSpace(objField.Field) && isNotNullAndUndefined(objField.Value)) {
                    for (var j = 0; j < objField.Value.length; j++) {

                        let predicateModel: PredicateModel = {};

                        predicateModel.field = objField.Field;
                        predicateModel.operator = getSFOperatorByRAFOperator(objField.Operator);
                        //let filterVal: string[] = [];

                        if (isNotNullAndUndefined(objField.Value[0]))
                            predicateModel.value = objField.Value[0];

                        outVal.push(predicateModel);
                    }

                }
            }
        }
    }
    return outVal;
}


export function getGridFilterPredicateByCustomFilter(rule: RAFCustomFilter): Predicate {
    //console.log('getGridFilterPredicateByCustomFilter1', rule);
    if (isNullOrUndefined(rule) || isNullOrUndefined(rule.Rules))
        return null;
    let outVal: Predicate = new Predicate('', 'equal', '');
    outVal.predicates = [];
    if (isNotNullAndUndefined(rule)) {
        if (rule.Rules && isNotNullAndUndefined(rule.Rules)) {
            const ruleModels: RAFCustomFilter[] = rule.Rules;
            for (var i = 0; i < ruleModels.length; i++) {
                const objField: RAFCustomFilter = ruleModels[i];
                if (isNotNullAndUndefined(objField) && !IsNullOrWhiteSpace(objField.Field) && isNotNullAndUndefined(objField.Value)) {
                    for (var j = 0; j < objField.Value.length; j++) {
                        if (isNotNullAndUndefined(objField.Value[0])) {
                            let predicateModel: Predicate = new Predicate(objField.Field, getSFOperatorByRAFOperator(objField.Operator), objField.Value[0]);
                            outVal.predicates.push(predicateModel);
                        }
                    }
                }
            }
        }
    }
    //console.log('getGridFilterPredicateByCustomFilter2', outVal);
    return outVal;
}

export function getSFRulesByCustomFilter(rule: RAFCustomFilter): RuleModel {
    let outVal: RuleModel = {};
    if (isNotNullAndUndefined(rule)) {
        if (rule.Rules && isNotNullAndUndefined(rule.Rules)) {
            outVal.condition = (rule.Condition === RAFOperator.AndCondition) ? "and" : "or";
            outVal.rules = [];
            const ruleModels: RAFCustomFilter[] = rule.Rules;
            for (var i = 0; i < ruleModels.length; i++) {
                outVal.rules.push(getSFRulesByCustomFilter(ruleModels[i]));
            }
        }
        else if (isNotNullAndUndefined(rule.Value) && isNotNullAndUndefined(rule.Value[0])) {
            let objField: RuleModel = {};
            objField.field = rule.Field;
            objField.type = (rule.Type === SFColumnType.datetime ? SFColumnType.date : rule.Type);
            objField.operator = getSFOperatorByRAFOperator(Number(rule.Operator));
            if (isNotNullAndUndefined(rule.Value) && isNotNullAndUndefined(rule.Value[0]))
                objField.value = rule.Value.length > 1 ? rule.Value : rule.Value[0];
            outVal = (objField);
        }
    }
    return outVal;
}

export function removeCustomValueFromCustomFilter(filter: RAFCustomFilter): RAFCustomFilter {
    if (filter.Rules) {
        filter.Rules = filter.Rules.filter(rule => {
          if (rule.Value) {
            return !rule.Value.some(value => value.startsWith('${'));
          }
          return true;
        });
    
        filter.Rules.forEach(rule => removeCustomValueFromCustomFilter(rule));
      }
      return filter;
  }

export function getCustomFilterBySFRules(rule: RuleModel): RAFCustomFilter {
    let outVal: RAFCustomFilter = {};
    if (isNotNullAndUndefined(rule)) {
        if (rule.rules && isNotNullAndUndefined(rule.rules)) {
            outVal.Condition = (rule.condition === "and") ? RAFOperator.AndCondition : RAFOperator.OrCondition;
            outVal.Rules = [];
            const ruleModels: RuleModel[] = rule.rules;
            for (var i = 0; i < ruleModels.length; i++) {
                outVal.Rules.push(getCustomFilterBySFRules(ruleModels[i]));
            }
        }
        else if (isNotNullAndUndefined(rule.value)) {
            let objField: RAFCustomFilter = {};
            objField.Field = rule.field;
            objField.Type = rule.type;
            objField.Operator = getRAFOperatorBySFOperator(rule.operator);
            let filterVal: string[] = [];
            if (isArray(rule.value)) {
                filterVal = rule.value as string[];
            }
            else {
                filterVal.push(rule.value.toString());
            }
            objField.Value = filterVal;
            outVal = (objField);
        }
    }

    return outVal;
}

export function getCustomValueFilterBySFRules(rule: RuleModel): RAFCustomFilter {
    let outVal: RAFCustomFilter = {};
    if (isNotNullAndUndefined(rule)) {
        if (rule.rules && isNotNullAndUndefined(rule.rules)) {
            outVal.Condition = (rule.condition === "and") ? RAFOperator.AndCondition : RAFOperator.OrCondition;
            outVal.Rules = [];
            const ruleModels: RuleModel[] = rule.rules;
            for (var i = 0; i < ruleModels.length; i++) {
               // Create a new rule with the same field and `${FieldName}`
    let newRule: RAFCustomFilter = {};
    newRule.Field = ruleModels[i].field;
    newRule.Value = [`$\{${ruleModels[i].field}\}`];
    newRule.Type = ruleModels[i].type
    newRule.Operator = RAFCustomOperator.NotEqual
    outVal.Rules.push(newRule);
                outVal.Rules.push(getCustomFilterBySFRules(ruleModels[i]));
            }
        }
        else if (isNotNullAndUndefined(rule.value)) {
            let objField: RAFCustomFilter = {};
            objField.Field = rule.field;
            objField.Type = rule.type;
            objField.Operator = getRAFOperatorBySFOperator(rule.operator);
            let filterVal: string[] = [];
            if (isArray(rule.value)) {
                filterVal = rule.value as string[];
            }
            else {
                filterVal.push(rule.value.toString());
            }
            objField.Value = filterVal;
            outVal = (objField);
        }
    }

    return outVal;
}

export function ConstructFilterMessage(filterColumnsModel?: PredicateModel[], selectedGridColumns?: ColumnModel[], attributes?: QueryAttributeJM[]): string {
    let retVal = "";
    if (isNotNullAndUndefined(filterColumnsModel) && filterColumnsModel.length > 0) {
        for (var i = 0; i < filterColumnsModel.length; i++) {
            const col: PredicateModel = filterColumnsModel[i];

            let value = col.value;
            const operator: string = col.operator;
            let operatorDisplayName: string = getDisplayNameBySFOperator(operator);

            const field: string = col.field;
            let displayName: string = field;
            let columnModel: ColumnModel = {};
            if (isNotNullAndUndefined(selectedGridColumns)) {
                columnModel = selectedGridColumns.find(x => x.field === field);
                if (isNotNullAndUndefined(columnModel) && !IsNullOrWhiteSpace(columnModel.field)) {
                    displayName = columnModel.headerText;
                }
            }

            if (col.type === SFColumnType.date) {
                value = moment((value as Date)).format(MomentFormats.DATE);
                for (var j = 0; j < filterColumnsModel.length; j++) {
                    const col1: PredicateModel = filterColumnsModel[j];
                    if (i !== j && col1.field === col.field) {
                        const isafter = moment((col.value as Date)).isAfter(col1.value as Date);
                        operatorDisplayName = getDisplayNameBySFOperator(isafter ? 'lessthan' : 'greaterthan');
                    }
                }
            }

            // if (columnModel.type === SFColumnType.dropdown) {
            //     let selectedField = attributes && attributes.find(x => x.PropertyName === columnModel.field);
            //     if (isNotNullAndUndefined(selectedField) && isNotNullAndUndefined(selectedField.ValueJson)) {
            //         let currentChoiceList = selectedField.ValueJson.find(x => x.DisplayName === value);
            //         value = currentChoiceList && currentChoiceList.DisplayName;
            //     }
            // }

            if (value === true) {
                value = 'Yes';
            }
            if (value === false) {
                value = 'No';
            }

            if (isNotNullAndUndefined(value)) {
                if (i === 0) {

                    retVal = `${displayName} ${operatorDisplayName} '${value}'`;
                }
                else {
                    retVal = `${retVal} and ${displayName} ${operatorDisplayName} '${value}'`;
                }
            }
        }
    }
    return retVal;
}


import moment from "moment";
import React, { PropsWithChildren } from "react";
import { IsNotNullOrWhiteSpace, convertUTCDateToLocalTimezone, isNotEmptyArray, isNotNullAndUndefined } from "../helpers/utils";
import { QueryAttributeJM } from "../models/Common/QueryAttributeJM";
import { RAFDataType, RAFUIType } from "../models/Common/RAFDataType";

interface IProps {
    fieldValue: any;
    queryAttribute: QueryAttributeJM;
}

function RAFCustomFieldValue({ fieldValue, queryAttribute, ...props }: PropsWithChildren<IProps>) {

    const emptyText = 'NA';

    const getCustomFieldValue = (fieldValue: any, dataType: string, uiType: string) => {
        if (dataType === RAFDataType.Text) {
            if (uiType === RAFUIType.HtmlEditor) {
                return (
                    <span dangerouslySetInnerHTML={{ __html: fieldValue ?? emptyText }} />
                );
            } else {
                return (
                    <span>
                        {fieldValue ?? emptyText}
                    </span>
                );
            }
        } else if (dataType === RAFDataType.Dropdown) {
            const values = fieldValue as string[];
            const valueJson = queryAttribute.ValueJson;
            const displayValues = isNotEmptyArray(values)
                ? valueJson
                    .filter(item => values.includes(item.Name) || values.includes(item.DisplayName))
                    .map(item => item.DisplayName)
                : null;
            if (queryAttribute.IsMultiSelect === true) {
                return (
                    <span>
                        {isNotEmptyArray(displayValues) ? displayValues.toString() : fieldValue ?? emptyText}
                    </span>
                );
            } else {
                const valueJson = queryAttribute.ValueJson;
                const inputValue = valueJson.find(x => x.Name === fieldValue || x.DisplayName === fieldValue);
                return (
                    <span>
                        {inputValue ? inputValue.DisplayName : fieldValue ?? emptyText}
                    </span>
                );
            }
        } else if (dataType === RAFDataType.Date || dataType === RAFDataType.DateTime) {
            const dateFormat = dataType === RAFDataType.DateTime ? "DD/MM/YYYY hh:mm A" : "DD/MM/YYYY";
            const date = convertUTCDateToLocalTimezone(fieldValue);
            const displayValue = IsNotNullOrWhiteSpace(date) ? moment(date).format(dateFormat) : null;
            return (
                <span>
                    {displayValue ?? emptyText}
                </span>
            );
        } else if (dataType === RAFDataType.Boolean) {
            let displayText = "No";
            if (fieldValue) {
                displayText = "Yes";
            } else {
                displayText = "No";
            }
            return (
                <span>
                    {displayText}
                </span>
            );
        } else {
            return (
                <span>
                    {fieldValue}
                </span>
            );
        }
    };

    function getFieldValueOrEmptyText() {
        try {
            return isNotNullAndUndefined(fieldValue) ? fieldValue.toString() : emptyText;
        } catch (error) {
            console.error(error);
            return emptyText; // return emptyText or some default value in case of an error
        }
    }

    if (isNotNullAndUndefined(queryAttribute)) {
        const dataType = queryAttribute.DataType;
        const uiType = queryAttribute.UIType;
        return (
            getCustomFieldValue(fieldValue, dataType, uiType)
        );
    } else {
        return (
            <span>
                {getFieldValueOrEmptyText()}
            </span>
        );
    }

};

export default React.memo(RAFCustomFieldValue);
import React, { useState, useEffect } from 'react';
import { GoogleMap, useJsApiLoader, Marker, Circle, Libraries } from '@react-google-maps/api';

const libraries: Libraries = ['places'];
const mapContainerStyle = {
    width: '100%',
    height: '300px',
};

interface RAFGoogleMapsProps {
    lat?: number | string;
    lng?: number | string;
    onLocationChange?: (location: { lat: number, lng: number; }) => void;
}

const RAFGoogleMaps: React.FC<RAFGoogleMapsProps> = ({ lat, lng, onLocationChange }) => {
    const REACT_APP_GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

    const { isLoaded, loadError } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: REACT_APP_GOOGLE_MAPS_API_KEY || '',
        libraries
    });

    const parseToFloat = (value: string | number | undefined): number => {
        return value !== undefined && !isNaN(Number(value)) ? parseFloat(value.toString()) : 0;
    };

    const [center, setCenter] = useState({
        lat: parseToFloat(lat),
        lng: parseToFloat(lng),
    });
    const [permissionDenied, setPermissionDenied] = useState(false);
    const [showMap, setShowMap] = useState(false);



    const checkPermissionAndLoad = () => {
        if (!('geolocation' in navigator)) {
            setPermissionDenied(true);
            return;
        }

        navigator.permissions?.query({ name: 'geolocation' }).then((result) => {
            if (result.state === 'denied') {
                setPermissionDenied(true);
            } else {
                navigator.geolocation.getCurrentPosition((position) => {
                    const newCenter = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    };
                    setCenter(newCenter);
                    onLocationChange && onLocationChange(newCenter);
                }, (error) => {
                    console.error('Error getting location', error);
                }, {
                    enableHighAccuracy: true,
                    timeout: 5000,
                    maximumAge: 0
                });
            }
        }).catch((error) => {
            console.error('Error checking geolocation permissions', error);
        });
    };

    useEffect(() => {
        if (!lat || !lng) {
            checkPermissionAndLoad();
        }
    }, [lat, lng, onLocationChange]);

    if (loadError) {
        return <div>Error loading maps</div>;
    }

    if (!isLoaded) {
        return <div>Loading maps...</div>;
    }

    if (permissionDenied) {
        return (
            <div>
                Please enable geolocation permission in your browser settings.
                <button type='button' className="e-tbar-btn e-tbtn-txt e-control e-btn e-lib e-primary" onClick={checkPermissionAndLoad}>Retry</button>
            </div>
        );
    }

    return (
        <div>
            <div>
                {center.lat && center.lng ? (
                    <div>
                        Latitude: {center.lat}, Longitude: {center.lng}
                    </div>
                ) : (
                    <div>Loading location...</div>
                )}
            </div>
            <button type='button' className="e-tbar-btn e-tbtn-txt e-control e-btn e-lib e-primary" onClick={() => setShowMap(!showMap)}>
                {showMap ? 'Hide Location' : 'Show Location'}
            </button>
            {showMap && (
                <div style={{ marginTop: '10px' }}>
                    <GoogleMap
                        mapContainerStyle={mapContainerStyle}
                        zoom={17}
                        center={center}
                        options={{
                            streetViewControl: false,
                            mapTypeControlOptions: {
                                mapTypeIds: [],
                            },
                            gestureHandling: 'none'
                        }}
                    >
                        <Marker
                            position={center}
                            icon={{
                                path: window.google.maps.SymbolPath.CIRCLE,
                                scale: 8,
                                fillOpacity: 1,
                                fillColor: '#5384ED',
                                strokeWeight: 2,
                                strokeColor: '#ffffff',
                                strokeOpacity: 1,
                            }}
                        />
                        <Circle
                            center={center}
                            radius={100}
                            options={{
                                fillColor: '#5384ED',
                                fillOpacity: 0.2,
                                strokeColor: '#5384ED',
                                strokeOpacity: 0.4,
                                strokeWeight: 1,
                                clickable: false,
                                draggable: false,
                                editable: false,
                                visible: true,
                                zIndex: 1,
                            }}
                        />
                    </GoogleMap>
                </div>
            )}
        </div>
    );
};

export default RAFGoogleMaps;

import { ItemModel, MenuEventArgs } from "@syncfusion/ej2-navigations";
import { DialogComponent, DialogUtility } from "@syncfusion/ej2-react-popups";
import React, { PropsWithChildren, Reducer, useEffect, useReducer } from "react";
import { useRecoilState } from "recoil";
import { RolePermissionsContext } from "../../RAFAuthentication/RAFRolePermissionsContextProvider";
import { RAFWorkspaceRelatedtoType } from "../../constants/Common/RMConstants";
import { atomSelectedViewState } from "../../RAFModules/Common/List/IndexHelper";
import AddRelatedPortalItem from "../../RAFModules/Common/Workspace/WorkspaceItem/AddRelatedPortalItem";
import { DeleteRecord, getDependenciesByViewID, getSessionStorage, hideProgress, removeSessionStorage, showProgress } from "../helpers/AppHelper";
import { RAFButtonConstant,RAFReportType, RAFReportTypeDisplayName, RAFTreeViewMenu, StorageKey } from "../../constants/Common/Constants";
import { isNotEmptyArray, isNotNullAndUndefined, IsNullOrWhiteSpace } from "../helpers/utils";
import { RAFViewRow } from "../models/Common/RAFViewRow";
import RAFButtonComponent from "../Navigation/RAFButtonComponent";
import { showWarningToast } from "../Utility/RAFToastComponent";
import RAFViewFilterPanel from "./RAFViewFilterPanel";
import { RAFEntityName } from "../../constants/Common/EntityConstants";


interface IProps {
    viewID: string;
    moduleName?: string;
    itemType?: string;
    onSave?: (recentViewId: string, itemEntity: string) => void;
    buttons?: { action: any, type: 'default' | 'iconBtn'; }[];
    onDelete?: (moduleName) => void;
    onClose?: () => void;
}

interface IState {
    viewType: string;
    viewRow: RAFViewRow;
    moreMenuOptions: ItemModel[];
    isCloneView: boolean;
    isRenameView: boolean;
    isViewEditMode: boolean;
    showViewFilterDialogContent: boolean;
    showAddToWorkspaceDialogContent: boolean;
}

function RAFViewMoreMenuOptions({ ...props }: PropsWithChildren<IProps>) {
    const [selectedViewDropdownValue, setSelectedViewState] = useRecoilState(
        atomSelectedViewState(props.moduleName)
    );


    const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
        (state, newState) => ({ ...state, ...newState }),
        {
            viewRow: null,
            viewType: null,
            moreMenuOptions: null,
            isCloneView: false,
            isRenameView: false,
            isViewEditMode: false,
            showViewFilterDialogContent: false,
            showAddToWorkspaceDialogContent: false,
        }
    );

    useEffect(() => {
        refresh();
    }, [props.viewID]);

    let deleteViewDialog;

    const refresh = () => {
        const { viewID, moduleName } = props;
        const viewsStorage = getSessionStorage(
            StorageKey.allViews_modulename + moduleName,
            true
        );
        const allViews: RAFViewRow[] = !IsNullOrWhiteSpace(viewsStorage)
            ? JSON.parse(viewsStorage)
            : null;
        const viewRow = isNotEmptyArray(allViews) && isNotNullAndUndefined(viewID) ? allViews.find(x => x.UID === viewID) : null;
        if (isNotNullAndUndefined(viewRow)) {
            let moreMenuOptions: ItemModel[] = [];
            if (
                isNotNullAndUndefined(viewRow.UID)
            ) {
                moreMenuOptions.push(
                    {
                        id: "RenameView",
                        text: "Rename View",
                    },
                    {
                        id: "EditView",
                        text: "Edit View",
                    }
                );
                if (viewRow.ViewType === RAFReportType.Table || viewRow.ViewType === RAFReportType.Default) {
                    moreMenuOptions.push(
                        {
                            id: "CloneView",
                            text: "Clone View",
                        },
                        {
                            id: "AddWorkspace",
                            text: "Add to portal",
                        },
                    );
                }
            }
            setState({ moreMenuOptions, viewRow });
        } else {
            setState({ moreMenuOptions: null });
        }
    };


    function onViewItemMoreMenuClicked(
        args: MenuEventArgs
    ) {
        const { viewRow } = state;
        if (isNotNullAndUndefined(args) && isNotNullAndUndefined(args.item)) {
            const selectedOption = args.item.id;
            if (selectedOption === "CreateView") {
                manageViewMenuItemClick(
                    viewRow.ViewType,
                    false,
                    null,
                    false,
                    false
                );
            } else if (selectedOption === "RenameView") {
                manageViewMenuItemClick(
                    viewRow.ViewType,
                    true,
                    viewRow,
                    false,
                    true
                );
            } else if (selectedOption === "EditView") {
                manageViewMenuItemClick(
                    viewRow.ViewType,
                    true,
                    viewRow,
                    false,
                    false
                );
            } else if (selectedOption === "CloneView") {
                manageViewMenuItemClick(
                    viewRow.ViewType,
                    true,
                    viewRow,
                    true,
                    true
                );
            } else if (selectedOption === "AddWorkspace") {
                if (viewRow.ViewType === RAFReportType.Table || viewRow.ViewType === RAFReportType.Default) {
                    openAddToWorkspaceDialog(viewRow);
                }
            } else if (
                selectedOption === `Add${RAFReportTypeDisplayName.Visualization}`
            ) {
                manageViewMenuItemClick(
                    RAFReportType.Analytic,
                    false,
                    viewRow,
                    false
                );
            }
        }
    }
    function viewActionBtnClick(
        action
    ) {
        const { viewRow } = state;
        if (isNotNullAndUndefined(action)) {
            const selectedOption = action.Id;
            if (selectedOption === RAFButtonConstant.Create.Id) {
                manageViewMenuItemClick(
                    viewRow.ViewType,
                    false,
                    null,
                    false,
                    false
                );
            } else if (selectedOption === RAFButtonConstant.Rename.Id) {
                manageViewMenuItemClick(
                    viewRow.ViewType,
                    true,
                    viewRow,
                    false,
                    true
                );
            } else if (selectedOption === RAFButtonConstant.Edit.Id) {
                manageViewMenuItemClick(
                    viewRow.ViewType,
                    true,
                    viewRow,
                    false,
                    false
                );
            } else if (selectedOption === RAFButtonConstant.Clone.Id) {
                manageViewMenuItemClick(
                    viewRow.ViewType,
                    true,
                    viewRow,
                    true,
                    true
                );
            } else if (selectedOption === RAFButtonConstant.Delete.Id) {
                DeleteFilterClicked();
            } else if (selectedOption === RAFButtonConstant.AddWorkspace.Id) {
                if (viewRow.ViewType === RAFReportType.Table || viewRow.ViewType === RAFReportType.Default) {
                    openAddToWorkspaceDialog(viewRow);
                }
            } else if (
                selectedOption === `Add${RAFReportTypeDisplayName.Visualization}`
            ) {
                manageViewMenuItemClick(
                    RAFReportType.Analytic,
                    false,
                    viewRow,
                    false
                );
            }
        }
    }

    const manageViewMenuItemClick = (
        type?: string,
        isViewEditMode?: boolean,
        objItem?: RAFTreeViewMenu,
        isCloneView?: boolean,
        isRenameView?: boolean
    ) => {
        if (type === RAFReportType.Table || type === RAFReportType.Default || type === RAFReportType.Group) {
            ViewFilterPanelClicked(
                (type === RAFReportType.Table || type === RAFReportType.Default) ? "default" : "group",
                isViewEditMode,
                objItem,
                isCloneView,
                isRenameView
            );
        } else if (type === RAFReportType.Dashboard) {
            //   dashboardViewPanelDialogOpen(isViewEditMode, objItem);
        } else {
            //    reportViewPanelDialogOpen(type, isViewEditMode, objItem, isCloneView);
        }
    };


    //addToWorkspace dialog start
    const openAddToWorkspaceDialog = (selectedView: RAFTreeViewMenu) => {
        setState({ showAddToWorkspaceDialogContent: true });
        document.body.classList.add("overflow-hidden");
    };

    const addToWorkspaceContent = () => {
        if (state.showAddToWorkspaceDialogContent === true) {
            const { viewRow } = state;
            return (
                <AddRelatedPortalItem
                    entity={props.moduleName}
                    isSystem={viewRow.IsSystem}
                    relatedto={viewRow.DisplayName}
                    relatedtoType={RAFWorkspaceRelatedtoType.View}
                    relatedtoUID={viewRow.UID}
                    title={viewRow.DisplayName}
                    onClose={() => addToWorkspaceContentDialogClose()}
                    isActive={isNotNullAndUndefined(viewRow.UID) ? true : false}
                />
            );
        } else {
            return <div></div>;
        }
    };

    const addToWorkspaceContentDialogClose = () => {
        setState({ showAddToWorkspaceDialogContent: false });
        document.body.classList.remove("overflow-hidden");
    };
    //addToWorkspace dialog ends

    //ViewFilterPanel dialog start
    const ViewFilterPanelClicked = (
        viewType: string,
        isViewEditMode: boolean,
        selectedView?: RAFTreeViewMenu,
        isCloneView?: boolean,
        isRenameView?: boolean
    ) => {
        setState({
            showViewFilterDialogContent: true,
            viewType,
            isViewEditMode,
            //  selectedView,
            isCloneView,
            isRenameView,
        });
        document.body.classList.add("overflow-hidden");
    };

    function viewFilterPanelDialogClose(): void {
        setState({ showViewFilterDialogContent: false });
        document.body.classList.remove("overflow-hidden");
    }

    const onViewSaved = (recentViewId) => {
        setSelectedViewState(null);
        if (props.onSave) {
            props.onSave(recentViewId, props.moduleName);
        }
    };

    function viewFilterPanelContent() {
        if (state.showViewFilterDialogContent === true) {
            const { viewRow } = state;

            return (
                <RolePermissionsContext.Consumer>
                    {({ permissionValue, hasPermissions }) => {
                        return (
                            <RAFViewFilterPanel
                                title="Display Columns"
                                buttonText="Apply"
                                //mode="selectcolumns"
                                requiredFieldCount={2}
                                onSave={(viewId) => onViewSaved(viewId)}
                                onCreate={(viewId) => {
                                    onViewSaved(viewId);
                                }}
                                onDelete={() => onViewSaved(null)}
                                onClose={viewFilterPanelDialogClose.bind(this)}
                                moduleName={props.moduleName}
                                viewId={state.isViewEditMode ? viewRow.UID : null}
                                viewType={state.viewType}
                                isShowInList
                                permissionValue={permissionValue}
                                isRenameView={state.isRenameView === true ? true : false}
                                isCloneView={state.isCloneView === true ? true : false}
                            />
                        );
                    }}
                </RolePermissionsContext.Consumer>
            );
        } else {
            return <div></div>;
        }
    }
    //ViewFilterPanel dialog end

    //View Delete start

    const DeleteFilterClicked = () => {
        deleteViewDialog = DialogUtility.confirm({
            animationSettings: { effect: "Fade" },
            cancelButton: { text: "No" },
            closeOnEscape: false,
            content: "Are you sure want to delete?",
            okButton: { text: "Yes", click: DeleteView.bind(this) },
            title: " Delete View",
            position: { X: "center", Y: "center" },
            cssClass: "raf-delete_alert_dialog alert-dialog",
            // cssClass: "alert-dialog",
        });
    };

    function DeleteView() {
        // let { viewId } = state;
        if (isNotNullAndUndefined(props.viewID)) {
            // let progressDiv = showProgress("body", true);
            let progressDiv = showProgress(".raf-delete_alert_dialog.e-dialog");
            getDependenciesByViewID(props.viewID).then((dependenciesItem) => {
                let isDepended =
                    isNotNullAndUndefined(dependenciesItem) && dependenciesItem.length > 0
                        ? true
                        : false;
                if (isDepended !== true) {
                    DeleteRecord(props.viewID, RAFEntityName.View)
                        .then((response) => {
                            hideProgress(progressDiv);
                            deleteViewDialog.hide();
                            if (response === true) {
                                removeSessionStorage(
                                    StorageKey.recentViewId_modulename + props.moduleName
                                );
                                removeSessionStorage(
                                    StorageKey.allViews_modulename + props.moduleName
                                );
                                props.onDelete(props.moduleName);
                                props.onClose();
                            } else {
                                showWarningToast("Sorry something went wrong !");
                            }
                        })
                        .catch((error) => error);
                } else {
                    hideProgress(progressDiv);
                    let viewType = [];
                    dependenciesItem.forEach((item) => {
                        if (isNotNullAndUndefined(item)) {
                            viewType.push(item.Type);
                        }
                    });
                    showWarningToast(
                        "You cannot delete this view. This view is referred in " +
                        viewType.toString() +
                        "."
                    );
                }
            });
        } else {
            showWarningToast("Sorry this record cannot be deleted");
        }
    }
    //View Delete end

    const { viewRow } = state;

    if (isNotNullAndUndefined(viewRow) && isNotNullAndUndefined(viewRow.UID)) {
        return (
            <div
                className="col-auto viewActionBtns"
                key={props.viewID}
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                }}
            >

                {props.itemType === RAFWorkspaceRelatedtoType.Module ? (
                    <div>
                        {/* <RAFButtonComponent
                            action={RAFButtonConstant.Create}
                            onClick={() => manageViewMenuItemClick(
                                RAFReportType.Default,
                                false,
                                null,
                                false,
                                false
                            )}
                            idString={props.moduleName}
                            iconBtn
                            className="custom-button-sm e-round primary-custom-button text-white"
                        /> */}
                    </div>
                ) :
                    (
                        isNotNullAndUndefined(props.buttons) && isNotEmptyArray(props.buttons) && props.buttons.length > 0 ?
                            <div className="row gx-2">
                                {props.buttons.map((button, index) => {
                                    return (
                                        <div className="col-auto" key={index}>
                                            <RAFButtonComponent action={button.action}
                                                {...button.type === 'iconBtn' ? { iconBtn: true } : { iconBtn: false }}
                                                onClick={() => viewActionBtnClick(button.action)}
                                                className={`e-outline${button.action.Id === RAFButtonConstant.Delete.Id ? ' e-danger' : ""}`}
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                            :
                            <RAFButtonComponent
                                action={RAFButtonConstant.Edit}
                                onClick={() => manageViewMenuItemClick(
                                    viewRow.ViewType,
                                    true,
                                    viewRow,
                                    false,
                                    false
                                )}
                                idString={props.moduleName}
                                iconBtn
                                className="custom-button-sm e-round primary-custom-button text-white"
                            />
                        // <DropDownButtonComponent
                        //     items={state.moreMenuOptions}
                        //     iconCss="fas fa-ellipsis-vertical"
                        //     className="e-sidebar__right__actionBtn e-caret-hide hover-show-child-div"
                        //     select={onViewItemMoreMenuClicked.bind(this)}
                        // />
                    )}
                <div>
                    {state.showViewFilterDialogContent && (
                        <DialogComponent
                            visible={state.showViewFilterDialogContent}
                            {...(isNotNullAndUndefined(state.isRenameView) &&
                                state.isRenameView === true
                                ? { header: state.isCloneView ? "Clone View" : "Rename View" }
                                : {})}
                            {...(isNotNullAndUndefined(state.isRenameView) &&
                                state.isRenameView === true
                                ? { showCloseIcon: true }
                                : {})}
                            cssClass={
                                state.isRenameView
                                    ? "centerDialog-sm createEditForm form-center-dialog"
                                    : "rightDialog createEditForm full-height form-center-dialog viewFilterDialog viewFilterDialog-md"
                            }
                            //height="100%"
                            overlayClick={viewFilterPanelDialogClose.bind(this)}
                            isModal
                            target="body"
                            closeOnEscape={false}
                            close={viewFilterPanelDialogClose.bind(this)}
                            content={viewFilterPanelContent.bind(this)}
                            id={`viewFilterPanelDialog_${props.moduleName}`}
                        ></DialogComponent>
                    )}
                    {state.showAddToWorkspaceDialogContent && (
                        <DialogComponent
                            header={"Add To Portal"}
                            visible={state.showAddToWorkspaceDialogContent}
                            showCloseIcon
                            cssClass="centerDialog-sm createEditForm form-center-dialog"
                            id="addToWorkspaceDialogContent"
                            //cssClass="rightDialog createEditForm full-height"
                            content={addToWorkspaceContent.bind(this)}
                            isModal
                            target="body"
                            closeOnEscape={false}
                            close={addToWorkspaceContentDialogClose.bind(this)}
                        ></DialogComponent>
                    )}
                </div>
            </div>
        );
    } else {
        return (
            <div></div>
        );
    }
}

export default React.memo(RAFViewMoreMenuOptions);
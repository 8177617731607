import { createContext, PropsWithChildren, Reducer, useEffect, useReducer } from "react";
import { QueryAttributeJM } from "..//models/Common/QueryAttributeJM";
import { getAllAttributes, getFields, hideProgress, showProgress } from "../helpers/AppHelper";
import {
  ConvertSystemName,
  isNotNullAndUndefined,
  IsNullOrWhiteSpace,
  wrapChildrenWith,
} from "../helpers/utils";
import { AttributeRelatedList } from "../models/Common/AttributeRelatedList";

interface IProps {
  moduleName?: string;
  progressDivId?: string;
}

interface IState {
  isLoading: boolean;
  attributeRelatedList: AttributeRelatedList,
  queryAttributes: QueryAttributeJM[];
}

export const RAFAttributesContext = createContext<{
  attributeRelatedList?: AttributeRelatedList;
  queryAttributes?: QueryAttributeJM[];
}>({ attributeRelatedList: null, queryAttributes: null });

export default function RAFAttributeRelatedListProvider({
  moduleName,
  progressDivId,
  children,
}: PropsWithChildren<IProps>) {

  const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      isLoading: true,
      attributeRelatedList: null,
      queryAttributes: null,
    }
  );

  useEffect(() => {
    refresh();
  }, [moduleName]);

  const refresh = async () => {
    if (IsNullOrWhiteSpace(moduleName)) {
      setState({ isLoading: false });
      return;
    }

    if (state.isLoading === false) {
      setState({ isLoading: true });
    }

    let progressDiv = isNotNullAndUndefined(progressDivId)
      ? showProgress(progressDivId)
      : showProgress("body", true);

    const [attributeRelatedList, queryAttributes] = await Promise.all([
      getAllAttributes(ConvertSystemName(moduleName)),
      getFields(ConvertSystemName(moduleName)),
    ]);

    hideProgress(progressDiv);
    setState({ attributeRelatedList, queryAttributes, isLoading: false });
  };

  if (state.isLoading === false) {
    let children1 = wrapChildrenWith(children, {
      attributeRelatedList: state.attributeRelatedList,
      queryAttributes: state.queryAttributes,
    });

    return (
      <RAFAttributesContext.Provider
        value={{
          attributeRelatedList: state.attributeRelatedList,
          queryAttributes: state.queryAttributes,
        }}
      >
        {children1 && children1}
      </RAFAttributesContext.Provider>
    );
  } else {
    return null;
  }
}

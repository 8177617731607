import { debounce, getComponent } from "@syncfusion/ej2-base";
import { DataManager, Query } from "@syncfusion/ej2-data";
import {
  ButtonComponent,
  CheckBox,
  RadioButtonComponent,
} from "@syncfusion/ej2-react-buttons";
import {
  ChangeEventArgs,
  DragEventArgs,
  DropDownList,
  FieldSettingsModel,
  FilteringEventArgs,
  ListBoxComponent,
} from "@syncfusion/ej2-react-dropdowns";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { MenuEventArgs, TabComponent } from "@syncfusion/ej2-react-navigations";
import {
  AnimationSettingsModel,
  DialogComponent,
  DialogUtility,
  Tooltip,
  TooltipEventArgs
} from "@syncfusion/ej2-react-popups";
import {
  ColumnsModel,
  QueryBuilderComponent,
  RuleChangeEventArgs,
  RuleModel,
  ShowButtonsModel,
} from "@syncfusion/ej2-react-querybuilder";
import createDecorator from "final-form-calculate";
import moment from "moment";
import * as R from "ramda";
import React, { Fragment } from "react";
import * as ReactDOMClient from "react-dom/client";
import { FormRenderProps } from "react-final-form";
import { msalInstance } from "../..";
import {
  DeleteRecord,
  IsSuperAdmin,
  defaultViewDataColumns,
  getDependenciesByViewID,
} from "../../RAFComponents/helpers/AppHelper";
import {
  RAFDataType,
  RAFUIType,
} from "../../RAFComponents/models/Common/RAFDataType";
import { EntityRow } from "../../RAFMaster/RMModules/Entity/EntityRow";
import { getEntityByName } from "../../RAFMaster/helpers/RMutils";
import {
  Constants,
  ContentType,
  MomentFormats,
  RAFButtonConstant,
  RAFLayout,
  RAFReportType,
  RAFViewVisiblity,
  SFColumnType,
  StorageKey,
  SystemVariable,
} from "../../constants/Common/Constants";
import { RAFEntityName } from "../../constants/Common/EntityConstants";
import { PermissionConstants } from "../../constants/Common/PermissionConstants";
import { NavigateParamsProps } from "../../router";
import RAFCheckBox from "../Inputs/RAFCheckBox";
import RAFChoiceOption from "../Inputs/RAFChoiceOption";
import RAFForm from "../Inputs/RAFForm";
import RAFNumber from "../Inputs/RAFNumber";
import RAFRadioButtonList from "../Inputs/RAFRadioButtonList";
import RAFTextBox from "../Inputs/RAFTextBox";
import { RAFDataManager, RAFUrlAdaptor } from "../Inputs/RAFUrlAdaptor";
import RAFCollapse from "../Navigation/RAFCollapse";
import { showWarningToast } from "../Utility/RAFToastComponent";
import {
  getAllViewsByModuleName,
  getClientTerminologies,
  getFields,
  getSessionStorage,
  hideProgress,
  removeSessionStorage,
  showProgress,
  tabAnimationSettings,
} from "../helpers/AppHelper";
import { hasPermission } from "../helpers/PermissionHelper";
import {
  getRAFOperatorBySFOperator,
  getSFOperatorByRAFOperator,
} from "../helpers/SFGridUtils";
import {
  ConvertSystemName,
  IsNullOrWhiteSpace,
  deepEqual,
  getSFColumnTypeByRAFDatatype,
  getSaveRequest,
  isArray,
  isEmptyOrNull,
  isNotNullAndUndefined,
  isNullOrUndefined,
  isString,
  mapOrder,
  propertyOf,
} from "../helpers/utils";
import { GetClientTerm } from "../models/Common/ClientTerminologyJM";
import {
  QueryAttributeJM,
  RAFOperator,
  ValueJson,
} from "../models/Common/QueryAttributeJM";
import {
  GroupByJM,
  RAFViewRow,
  SortBy,
  ViewSelect,
} from "../models/Common/RAFViewRow";
import { RolePermissionRow } from "../models/Common/RolePermissionRow";
import * as repositoryActions from "../store/actions/repositoryActions";
import RAFCustomDateFilter from "./RAFCustomDateFilter";
import { RAFCustomFilter } from "./RAFFilterColumn/RAFCustomFilter";
import RAFGroupSortBy from "./RAFGroupSortBy";
import RAFSortBy from "./RAFSortBy";
import RAFViewMoreMenuOptions from "./RAFViewMoreMenuOptions";
import "./ViewFilterStyle.scss";

interface IProps {
  onClose?: () => void;
  onCreate?: (
    viewId?: string,
    moduleName?: string,
    viewName?: string,
    viewClone?: boolean
  ) => void;
  onSave?: (
    viewId?: string,
    moduleName?: string,
    viewName?: string,
    viewClone?: boolean
  ) => void;
  onDelete?: (moduleName: string) => void;
  mode?: "selectcolumns" | "wherecolumns";
  title?: string;
  buttonText?: string;
  requiredFieldCount?: number;
  moduleName?: string;
  hasClientTerms?: boolean;
  hideColumnChooser?: boolean;
  viewType?: string;
  viewId?: string;
  newViewDisplayName?: string;
  isShowInList?: boolean;
  isViewManagement?: boolean;
  permissionValue?: RolePermissionRow;
  isCloneView?: boolean;
  isRenameView?: boolean;
}

class RAFViewFilterPanel extends React.Component<NavigateParamsProps & IProps> {
  _isMounted = false;
  searchTextbox: TextBoxComponent;
  animationSettings: AnimationSettingsModel;
  private hasClientTerms = false;

  constructor(props) {
    super(props);
    if (isNotNullAndUndefined(props.hasClientTerms))
      this.hasClientTerms = props.hasClientTerms;
  }

  state = {
    //allAttributes: [],
    selectedTabIndex: 0,
    filteredAttributes: [],
    filteredAttributes1: [],
    filteredAttributes2: [],
    clientTerms: [],
    whereAttributes: [],
    enableGroup: true,
    enableCondition: false,
    isDesending: false,
    //isPrivate: false,
    filterJson: {},
    //viewName: '',
    viewClone: false,
    showSubmitFilterDialog: false,
    rafViewRow: {},
    centerDlgHeaderTitle: "Clone View",
    viewType: isNotNullAndUndefined(this.props.viewType)
      ? this.props.viewType
      : null,
    viewId: isNotNullAndUndefined(this.props.viewId) ? this.props.viewId : null,
    tabComponentKey: Math.random(),
    listBoxComponentKey: Math.random(),
  };

  qryBldrObj: QueryBuilderComponent | null;
  dropDownObj: DropDownList;
  valueElement: HTMLInputElement;
  checkboxObj: CheckBox;
  //lookupObj: AutoComplete;
  lookupObj: DropDownList;
  //dateRangeObj: DateRangePicker;
  filterRule: RuleModel;
  radioButtonObj: RadioButtonComponent;
  private deleteViewDialog: any;
  private ButtonOptions: ShowButtonsModel = {
    groupDelete: true,
    groupInsert: true,
    ruleDelete: true,
  };

  public rafForm: FormRenderProps | null;
  public rafForm1: FormRenderProps | null;

  public submitButton: ButtonComponent;

  public today: Date = new Date(new Date().toDateString());
  public weekStart: Date = new Date(
    new Date(
      new Date().setDate(new Date().getDate() - ((new Date().getDay() + 7) % 7))
    ).toDateString()
  );
  public weekEnd: Date = new Date(
    new Date(
      new Date().setDate(
        new Date(
          new Date().setDate(
            new Date().getDate() - ((new Date().getDay() + 7) % 7)
          )
        ).getDate() + 6
      )
    ).toDateString()
  );
  public monthStart: Date = new Date(
    new Date(new Date().setDate(1)).toDateString()
  );
  public monthEnd: Date = this.today;
  public lastStart: Date = new Date(
    new Date(
      new Date(new Date().setMonth(new Date().getMonth() - 1)).setDate(1)
    ).toDateString()
  );
  public lastEnd: Date = this.today;
  public yearStart: Date = new Date(
    new Date(new Date().setDate(new Date().getDate() - 365)).toDateString()
  );
  public yearEnd: Date = this.today;
  private moduleName = isNotNullAndUndefined(this.props.moduleName)
    ? this.props.moduleName
    : null;
  private isAdmin = IsSuperAdmin();
  private isNewView: boolean = isNotNullAndUndefined(this.props.viewId)
    ? false
    : true;
  private newViewDisplayName;
  private listObj1: ListBoxComponent;
  private listObj2: ListBoxComponent;
  private fields: FieldSettingsModel = {
    text: "DisplayName",
    value: "AttributeUID",
  };

  rafviewDisplayname = "";

  componentDidMount = () => {
    this._isMounted = true;
    //isCloneView = true && isRenameView = true  -- clone view
    //isCloneView = false && isRenameView = true  -- rename view
    //isCloneView = false && isRenameView = false && viewID !== null  -- update view
    //isCloneView = false && isRenameView = false && viewID == null  -- add view

    this.refresh(
      this.state.viewId,
      false,
      this.props.moduleName,
      isNotNullAndUndefined(this.props.viewId) ? false : true,
      this.props.newViewDisplayName
    );
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate = (prevProps) => {
    if (
      !deepEqual(prevProps.viewId, this.props.viewId) ||
      !deepEqual(prevProps.viewType, this.props.viewType)
    ) {
      if (this._isMounted) {
        this.setState({
          viewId: this.props.viewId,
          viewType: this.props.viewType,
          tabComponentKey: Math.random(),
        });
      }
    }
  };

  TabSelected = (selectedTabIndex) => {
    if (this._isMounted) {
      this.setState(
        {
          isLoading: true,
        },
        () => {
          setTimeout(() => {
            this.setState({ selectedTabIndex }, () => {
              if (selectedTabIndex === 1) {
                let { whereAttributes } = this.state;
                if (this.qryBldrObj) {
                  this.qryBldrObj.columns =
                    this.convertfilterModel(whereAttributes);
                  this.qryBldrObj.refresh();
                  if (isNotNullAndUndefined(this.filterRule)) {
                    this.qryBldrObj.setRules(this.filterRule);
                  }
                }
              }
            });
          }, 100);
        }
      );
    }
  };

  refresh = (
    viewId?: string,
    viewClone?: boolean,
    moduleName?: string,
    isNewView?: boolean,
    viewDisplayName?: string
  ) => {
    this.isNewView = isNewView;
    this.newViewDisplayName = isNotNullAndUndefined(viewDisplayName)
      ? viewDisplayName
      : null;
    this.moduleName = moduleName;
    this.getAllAttributes(viewId, moduleName);

    /*let elementToFocus = null;
        elementToFocus = document.querySelector("input[name$='FieldToFocus']");
        setTimeout(() => {
            if (elementToFocus !== null) {
                elementToFocus.focus();
            }
            if (isNotNullAndUndefined(this.searchTextbox)) {
                this.searchTextbox.value = '';
            }
        }, 500)*/
  };

  getAllAttributes = (viewId, moduleName) => {
    let progressDiv = showProgress(`#view_manage_dlg_${this.props.moduleName}`);
    getFields(moduleName, null)
      .then((fields) => {
        getEntityByName(moduleName)
          .then((entityRow) => {
            hideProgress(progressDiv);
            let clonedAttributes = R.clone(fields);
            const whereAttributes = clonedAttributes.filter(
              (x) => x.ShowInWhere === true
            );
            this.getViewById(viewId, whereAttributes, moduleName, entityRow);
          })
          .catch((error) => error);
      })
      .catch((error) => error);
  };

  getViewById = (
    id,
    allAttributes: QueryAttributeJM[],
    moduleName,
    entityRow?: EntityRow
  ) => {
    let progressDiv = showProgress(`#view_manage_dlg_${this.props.moduleName}`);
    let rafViewRow: RAFViewRow = new RAFViewRow();
    if (isNotNullAndUndefined(id)) {
      //Update view

      //let allViewsStorage = getSessionStorage(StorageKey.allViews_modulename + moduleName, true);
      getAllViewsByModuleName(moduleName).then((allViews) => {
        //if (isNotNullAndUndefined(allViewsStorage) && !IsNullOrWhiteSpace(allViewsStorage)) {
        //const allViews: RAFViewRow[] = JSON.parse(allViewsStorage);
        if (isNotNullAndUndefined(allViews)) {
          rafViewRow = allViews.find((x) => x.UID === id);
        }
        //}
        if (isNotNullAndUndefined(rafViewRow)) {
          if (
            isNotNullAndUndefined(rafViewRow.SettingsJson) &&
            isNullOrUndefined(rafViewRow.SettingsJson.ShowInList)
          ) {
            rafViewRow.SettingsJson.ShowInList = isNotNullAndUndefined(
              this.props.isShowInList
            )
              ? this.props.isShowInList
              : false;
          }
          // if (
          //   isNotNullAndUndefined(rafViewRow.SettingsJson) &&
          //   isNotNullAndUndefined(rafViewRow.SettingsJson.MobileColumnCount)
          // ) {
          //   rafViewRow.SettingsJson.MobileColumnCount = null;
          // }

          //this.rafView = rafViewRow;
          if (isNotNullAndUndefined(allAttributes)) {
            let selectColumnsArray = [];
            if (isNotNullAndUndefined(rafViewRow.SelectColumnjson)) {
              selectColumnsArray = rafViewRow.SelectColumnjson.SelectColumns;
            }

            let filterJson;
            if (isNotNullAndUndefined(rafViewRow.FilterJson)) {
              filterJson = rafViewRow.FilterJson;
            }

            //if (isNotNullAndUndefined(filterJson) && filterJson.Rules.length > 0) {
            //    this.filterRule = this.getSFRulesByCustomFilter(filterJson);
            //    this.qryBldrObj.setRules(this.filterRule);
            //}
            //else {
            //    let epmtyRule: RuleModel = {
            //        'condition': 'and',
            //        'rules': [{
            //            'field': '',
            //            'type': 'string',
            //            'operator': 'equal',
            //            'rules': []
            //        }]
            //    };
            //    //setTimeout(() => { this.qryBldrObj && this.qryBldrObj.setRules(epmtyRule); }, 100);
            //}

            let updatedAttributes: QueryAttributeJM[] = R.clone(allAttributes);
            updatedAttributes = updatedAttributes.map((x) => {
              x.isSelectedColumn =
                selectColumnsArray.findIndex((y) => y === x.PropertyName) >= 0;
              return x;
            });

            updatedAttributes.sort(function (x, y) {
              return x.isSelectedColumn === y.isSelectedColumn
                ? 0
                : x.isSelectedColumn
                  ? -1
                  : 1;
            });

            const updatedAttributes1: QueryAttributeJM[] = mapOrder(
              updatedAttributes,
              selectColumnsArray,
              propertyOf<QueryAttributeJM>("PropertyName")
            );

            let visibleColumns = updatedAttributes1.filter(
              (x) => x.isSelectedColumn === true
            );
            let unSelectedColumns: QueryAttributeJM[] =
              updatedAttributes1.filter(
                (x) => x.isSelectedColumn === false && x.IsEditable === true
              );
            unSelectedColumns.sort((x, y) =>
              x.DisplayName > y.DisplayName ? 1 : -1
            );

            let unSelectedSystemColumns: QueryAttributeJM[] =
              updatedAttributes1.filter(
                (x) => x.isSelectedColumn === false && x.IsEditable === false
              );
            unSelectedColumns.sort((x, y) =>
              x.DisplayName > y.DisplayName ? 1 : -1
            );

            this.rafviewDisplayname = rafViewRow.DisplayName;

            if (isNotNullAndUndefined(rafViewRow)) {
              rafViewRow = this.setGroupByFields(rafViewRow);
            }
            if (this.hasClientTerms) {
              getClientTerminologies(moduleName)
                .then((clientTerminologies) => {
                  hideProgress(progressDiv);
                  if (this._isMounted) {
                    this.setState(
                      {
                        whereAttributes: updatedAttributes1,
                        filteredAttributes: visibleColumns,
                        filteredAttributes1: unSelectedColumns,
                        filteredAttributes2: unSelectedSystemColumns,
                        clientTerms: clientTerminologies,
                        rafViewRow: rafViewRow,
                        tabComponentKey: Math.random(),
                      },
                      () => {
                        this.filterRule =
                          this.getSFRulesByCustomFilter(filterJson);
                        if (this.qryBldrObj) {
                          this.qryBldrObj.columns =
                            this.convertfilterModel(updatedAttributes1);
                          this.qryBldrObj.refresh();
                          if (
                            isNotNullAndUndefined(filterJson) &&
                            filterJson.Rules.length > 0
                          ) {
                            this.qryBldrObj.setRules(this.filterRule);
                          }
                        }
                      }
                    );
                  }
                })
                .catch((error) => error);
            } else {
              if (this._isMounted) {
                hideProgress(progressDiv);
                this.setState(
                  {
                    whereAttributes: updatedAttributes1,
                    filteredAttributes: visibleColumns,
                    filteredAttributes1: unSelectedColumns,
                    filteredAttributes2: unSelectedSystemColumns,
                    rafViewRow: rafViewRow,
                    tabComponentKey: Math.random(),
                  },
                  () => {
                    this.filterRule = this.getSFRulesByCustomFilter(filterJson);
                    if (this.qryBldrObj) {
                      this.qryBldrObj.columns =
                        this.convertfilterModel(updatedAttributes1);
                      this.qryBldrObj.refresh();
                      if (
                        isNotNullAndUndefined(filterJson) &&
                        filterJson.Rules.length > 0
                      ) {
                        this.qryBldrObj.setRules(this.filterRule);
                      }
                    }
                  }
                );
              }
            }
          }
        }
      });
    } else {
      //create view
      if (isNotNullAndUndefined(allAttributes)) {
        if (
          isNotNullAndUndefined(entityRow) &&
          isNotNullAndUndefined(entityRow.UID)
        ) {
          rafViewRow.EntityUID = entityRow.UID;
          rafViewRow.Entity = entityRow.DisplayName;
        }
        rafViewRow.ViewType = this.state.viewType;
        rafViewRow.Isdefault = false;
        rafViewRow.DisplayName = this.newViewDisplayName;
        rafViewRow.IsSystem =
          this.props.isViewManagement === true ? true : false;
        let isPrivate = hasPermission(
          this.props.permissionValue,
          PermissionConstants.ManageViewPrivate
        );
        rafViewRow.SettingsJson = {
          IsPrivate: isPrivate,
          ParamFields: null,
          ShowInList: isNotNullAndUndefined(this.props.isShowInList)
            ? this.props.isShowInList
            : false,
        };
        let selectColumnsArray = [];

        /*let epmtyRule: RuleModel = {
                    'condition': 'and',
                    'rules': [{
                        'field': '',
                        'type': 'string',
                        'operator': 'equal',
                        'rules': []
                    }]
                };*/

        let updatedAttributes: QueryAttributeJM[] = R.clone(allAttributes);

        updatedAttributes = updatedAttributes.map((x) => {
          x.isSelectedColumn =
            selectColumnsArray.findIndex((y) => y === x.PropertyName) >= 0;
          return x;
        });

        updatedAttributes.sort(function (x, y) {
          return x.isSelectedColumn === y.isSelectedColumn
            ? 0
            : x.isSelectedColumn
              ? -1
              : 1;
        });

        const updatedAttributes1: QueryAttributeJM[] = mapOrder(
          updatedAttributes,
          selectColumnsArray,
          propertyOf<QueryAttributeJM>("PropertyName")
        );

        let unSelectedColumns: QueryAttributeJM[] = updatedAttributes1.filter(
          (x) =>
            x.isSelectedColumn === false &&
            x.IsDefault === false &&
            x.IsEditable === true
        );
        unSelectedColumns.sort((x, y) =>
          x.DisplayName > y.DisplayName ? 1 : -1
        );

        let unSelectedSystemColumns: QueryAttributeJM[] =
          updatedAttributes1.filter(
            (x) =>
              x.isSelectedColumn === false &&
              x.IsDefault === false &&
              x.IsEditable === false
          );
        unSelectedSystemColumns.sort((x, y) =>
          x.DisplayName > y.DisplayName ? 1 : -1
        );

        let defaultColumn: QueryAttributeJM = updatedAttributes.find(
          (x) => x.IsDefault === true
        );

        let filteredAttributes: QueryAttributeJM[] = [];
        if (isNotNullAndUndefined(defaultColumn)) {
          defaultColumn.isSelectedColumn = true;
          filteredAttributes.push(defaultColumn);
        }

        if (this._isMounted) {
          hideProgress(progressDiv);
          this.setState(
            {
              whereAttributes: updatedAttributes,
              filteredAttributes,
              filteredAttributes1: unSelectedColumns,
              filteredAttributes2: unSelectedSystemColumns,
              rafViewRow: rafViewRow,
              tabComponentKey: Math.random(),
            },
            () => {
              if (this.qryBldrObj) {
                this.qryBldrObj.columns =
                  this.convertfilterModel(updatedAttributes);
                this.qryBldrObj.refresh();
                //setTimeout(() => { this.qryBldrObj && this.qryBldrObj.setRules(epmtyRule); }, 100);
              }
            }
          );
        }
      }
    }
  };

  getCustomFilterBySFRules = (rule: RuleModel): RAFCustomFilter => {
    let outVal: RAFCustomFilter = {};
    if (isNotNullAndUndefined(rule)) {
      if (rule.rules && isNotNullAndUndefined(rule.rules)) {
        outVal.Condition =
          rule.condition === "and"
            ? RAFOperator.AndCondition
            : RAFOperator.OrCondition;
        outVal.Rules = [];
        const ruleModels: RuleModel[] = rule.rules;
        for (var i = 0; i < ruleModels.length; i++) {
          outVal.Rules.push(this.getCustomFilterBySFRules(ruleModels[i]));
        }
      }
      //else if (isNotNullAndUndefined(rule.value)) {
      else {
        let objField: RAFCustomFilter = {};
        objField.Field = rule.field;
        objField.Type = rule.type;
        objField.Operator = getRAFOperatorBySFOperator(rule.operator);
        let filterVal: string[] = [];
        if (isNotNullAndUndefined(rule.value)) {
          if (isArray(rule.value)) {
            filterVal = rule.value as string[];
          } else {
            filterVal.push(rule.value.toString());
          }
        }
        objField.Value = filterVal;
        outVal = objField;
      }
    }
    return outVal;
  };

  getSFRulesByCustomFilter = (rule: RAFCustomFilter): RuleModel => {
    let outVal: RuleModel = {};
    if (isNotNullAndUndefined(rule)) {
      if (rule.Rules && isNotNullAndUndefined(rule.Rules)) {
        outVal.condition =
          rule.Condition === RAFOperator.AndCondition ? "and" : "or";
        outVal.rules = [];
        const ruleModels: RAFCustomFilter[] = rule.Rules;
        for (var i = 0; i < ruleModels.length; i++) {
          outVal.rules.push(this.getSFRulesByCustomFilter(ruleModels[i]));
        }
      }
      //else if (isNotNullAndUndefined(rule.Value) && isNotNullAndUndefined(rule.Value[0])) {
      else {
        let objField: RuleModel = {};
        objField.field = rule.Field;
        objField.type =
          rule.Type === SFColumnType.datetime ? SFColumnType.date : rule.Type;
        objField.operator = getSFOperatorByRAFOperator(rule.Operator);
        if (
          isNotNullAndUndefined(rule.Value) &&
          isNotNullAndUndefined(rule.Value[0])
        )
          objField.value = rule.Value.length > 1 ? rule.Value : rule.Value[0];
        outVal = objField;
      }
    }
    return outVal;
  };

  OnSubmitFilterClicked = (rafView?: RAFViewRow, progressDivId?: string) => {
    //let rafView: RAFViewRow = rafViewRow;

    // if (this.props.isViewManagement !== true && rafView.IsSystem === true) {
    //     rafView.IsSystem = false;
    // }
    let rafCustomFilter: RAFCustomFilter = null;
    if (isNotNullAndUndefined(this.filterRule)) {
      rafCustomFilter = this.getCustomFilterBySFRules(this.filterRule);
    }
    if (isNotNullAndUndefined(rafCustomFilter)) {
      rafView.FilterJson = rafCustomFilter;
    } else {
      rafView.FilterJson = null;
    }

    if (
      isNotNullAndUndefined(rafView) &&
      isNotNullAndUndefined(rafView.OrderByJson)
    ) {
      rafView.OrderByJson = rafView.OrderByJson.filter((x) => x.Field !== null);
    }
    //selected columns
    let selectedCount = this.state.filteredAttributes.filter(
      (x) => x.isSelectedColumn === true
    ).length;
    if (this.props.requiredFieldCount > 0) {
      if (this.isAdmin) {
        if (selectedCount > 0) {
          this.ColumnChooserChanged(this.state.filteredAttributes, rafView);
        } else {
          showWarningToast("You should select atleast 1 column(s)");
          return;
        }
      } else if (selectedCount >= this.props.requiredFieldCount) {
        this.ColumnChooserChanged(this.state.filteredAttributes, rafView);
      } else {
        showWarningToast(
          "You should select atleast " +
          this.props.requiredFieldCount +
          " column(s)"
        );
        return;
      }
    } else {
      this.ColumnChooserChanged(this.state.filteredAttributes, rafView);
    }
    if (this.state.viewType === RAFReportType.Group) {
      if (isNotNullAndUndefined(rafView.GroupByJson)) {
        let item = rafView.GroupByJson.filter((item) => !(item.Field === null));
        if (item.length < 1) {
          showWarningToast(
            "Group cannot be empty, select atleast one field",
            "Warning"
          );
          return;
        }
      }
    }
    rafView = this.removeEmptyGroupByFields(rafView);

    let data;

    if (
      IsNullOrWhiteSpace(rafView.ViewName) ||
      isEmptyOrNull(rafView.ViewName)
    ) {
      showWarningToast("View name cannot be empty.", "Warning");
      return;
    }

    let isViewExist: boolean = this.CheckViewExist(
      rafView.UID,
      rafView.ViewName,
      this.moduleName,
      this.props.isCloneView
    );
    if (isViewExist === true) {
      //showWarningToast('A view with the name you specified already exists. Specify a different view name.', 'Warning');
      return;
    }
    if (this.props.isCloneView === true) {
      delete rafView.UID;
      rafView.IsSystem = false;
      data = getSaveRequest(rafView, null);
    } else {
      data = getSaveRequest(rafView, rafView.UID);
    }
    let progressDiv = isNotNullAndUndefined(progressDivId)
      ? showProgress("#" + progressDivId)
      : showProgress(`#view_manage_dlg_${this.props.moduleName}`);
    repositoryActions
      .postDataAndGetResponse(
        `${Constants.baseAPIUrl}View/Save`,
        data,
        { ...this.props },
        ContentType.applicationJson
      )
      .then((response) => {
        hideProgress(progressDiv);
        if (
          isNotNullAndUndefined(response) &&
          isNotNullAndUndefined(response.data)
        ) {
          if (
            response.data.EntityId !== null &&
            response.data.EntityId !== undefined &&
            response.data.EntityId !== ""
          ) {
            rafView.UID = response.data.EntityId;
            removeSessionStorage(
              StorageKey.allViews_modulename + this.moduleName
            );
            if (this.props.requiredFieldCount > 0) {
              if (selectedCount > 0) {
                //this.cloneFilterDialog.close();
                if (this.props.isCloneView === true) {
                  this.props.onSave(
                    response.data.EntityId,
                    this.moduleName,
                    rafView.DisplayName,
                    this.props.isCloneView
                  );
                } else {
                  if (rafView.DisplayName === this.rafviewDisplayname) {
                    if (this.isNewView) {
                      this.props.onCreate(
                        response.data.EntityId,
                        this.moduleName,
                        response.data.ObjectName
                      );
                    } else {
                      this.props.onSave(
                        response.data.EntityId,
                        this.moduleName,
                        response.data.ObjectName
                      );
                    }
                  } else {
                    if (this.isNewView) {
                      this.props.onCreate(
                        response.data.EntityId,
                        this.moduleName,
                        response.data.ObjectName
                      );
                    } else {
                      this.props.onSave(
                        response.data.EntityId,
                        this.moduleName,
                        rafView.DisplayName,
                        true
                      );
                    }
                  }
                }
                this.props.onClose();
              }
            }
          } else if (
            response.data.Error !== null &&
            response.data.Error !== undefined &&
            response.data.Error !== ""
          ) {
            alert("Unable to save");
          }
        }
      })
      .catch((error) => error);
  };

  removeEmptyGroupByFields = (rafView) => {
    if (isNotNullAndUndefined(rafView.GroupByJson)) {
      rafView.GroupByJson = rafView.GroupByJson.filter(
        (item) => !(item.Field === null)
      );
    }
    return rafView;
  };

  CheckViewExist = (
    viewId?: string,
    viewName?: string,
    moduleName?: string,
    viewClone?: boolean
  ) => {
    if (isNotNullAndUndefined(viewName)) {
      const loggedinUserId = msalInstance.currentUserId;
      const allViewsStorage = getSessionStorage(
        StorageKey.allViews_modulename + moduleName,
        true
      );
      let rafViewRow: RAFViewRow = new RAFViewRow();
      if (
        isNotNullAndUndefined(allViewsStorage) &&
        !IsNullOrWhiteSpace(allViewsStorage)
      ) {
        const allViews: RAFViewRow[] = JSON.parse(allViewsStorage);
        if (isNotNullAndUndefined(allViews)) {
          rafViewRow = allViews.find(
            (x) =>
              isNotNullAndUndefined(x.ViewName) &&
              x.ViewName.toLowerCase() === viewName.toLowerCase()
          );
        }
      }

      if (
        isNotNullAndUndefined(rafViewRow) &&
        isNotNullAndUndefined(rafViewRow.UID)
      ) {
        //view already exist
        if (viewClone === true) {
          if (rafViewRow.IsSystem === true) {
            showWarningToast(
              "This is a system view. Specify a different view name.",
              "Warning"
            );
          } else {
            if (rafViewRow.CreatedByUID === loggedinUserId) {
              showWarningToast(
                "A view with the name you specified already exists. Specify a different view name.",
                "Warning"
              );
            }
            //else if (rafViewRow.SettingsJson.ShowInList === false) {
            //    showWarningToast("This is either a system view or this name is already used by another user. Specify a different view name.")
            //}
            else {
              showWarningToast(
                "This view name is already taken by another user. Specify a different view name.",
                "Warning"
              );
            }
          }
          return true;
        } else {
          if (isNotNullAndUndefined(viewId)) {
            if (rafViewRow.UID === viewId) {
              return false;
            } else {
              if (rafViewRow.IsSystem === true) {
                showWarningToast(
                  "This is a system view. Specify a different view name.",
                  "Warning"
                );
              } else {
                if (rafViewRow.CreatedByUID === loggedinUserId) {
                  showWarningToast(
                    "A view with the name you specified already exists. Specify a different view name.",
                    "Warning"
                  );
                }
                //else if (rafViewRow.SettingsJson.ShowInList === false) {
                //    showWarningToast("This is either a system view or this name is already used by another user. Specify a different view name.")
                //}
                else {
                  showWarningToast(
                    "This view name is already taken by another user. Specify a different view name.",
                    "Warning"
                  );
                }
              }
              return true;
            }
          } else {
            if (rafViewRow.IsSystem === true) {
              showWarningToast(
                "This is a system view. Specify a different view name.",
                "Warning"
              );
            } else {
              if (rafViewRow.CreatedByUID === loggedinUserId) {
                showWarningToast(
                  "A view with the name you specified already exists. Specify a different view name.",
                  "Warning"
                );
              }
              //else if (rafViewRow.SettingsJson.ShowInList === false) {
              //    showWarningToast("This is either a system view or this name is already used by another user. Specify a different view name.")
              //}
              else {
                showWarningToast(
                  "This view name is already taken by another user. Specify a different view name.",
                  "Warning"
                );
              }
            }
            return true;
          }
        }
      } else return false;
    } else return false;
  };

  setGroupByFields = (rafViewRow) => {
    if (rafViewRow.GroupByJson && rafViewRow.GroupByJson.length > 0) {
      if (rafViewRow.GroupByJson && rafViewRow.GroupByJson.length === 2) {
        rafViewRow.GroupByJson.push({ Field: null, AggregateFunction: null });
      } else if (
        rafViewRow.GroupByJson &&
        rafViewRow.GroupByJson.length === 1
      ) {
        rafViewRow.GroupByJson.push(
          { Field: null, AggregateFunction: null },
          { Field: null, AggregateFunction: null }
        );
      }
    } else {
      let groypByJson: GroupByJM[] = [
        { Field: null, AggregateFunction: null },
        { Field: null, AggregateFunction: null },
        { Field: null, AggregateFunction: null },
      ];
      rafViewRow.GroupByJson = groypByJson;
    }
    return rafViewRow;
  };

  getLookupData = (
    skip: number,
    take: number,
    url: string,
    containsText?: string
  ): Promise<DataManager> => {
    //state.where
    return repositoryActions
      .postDataAndGetResponse(
        url,
        {
          ContainsText: containsText,
          //ContainsField: this.nameField
          Skip: skip,
          Take: take,
        },
        null,
        ContentType.applicationJson
      )
      .then((response) => {
        let objData = response.data;
        if (
          isNotNullAndUndefined(objData) &&
          isNotNullAndUndefined(objData.Entities)
        ) {
          return {
            result: objData.Entities,
          };
        } else {
          return {
            result: undefined,
          };
        }
      })
      .catch((error) => error);
  };

  convertfilterModel = (attributes: QueryAttributeJM[]): ColumnsModel[] => {
    let retVal: ColumnsModel[] = [];
    let filterVal = null;
    for (var i = 0; i < attributes.length; i++) {
      const attrib = attributes[i];
      if (attrib.ShowInWhere) {
        let obj: ColumnsModel = {};
        obj.field = attrib.PropertyName;
        obj.type = getSFColumnTypeByRAFDatatype(attrib.DataType);
        if (
          attrib.DataType === RAFDataType.Text ||
          attrib.DataType === RAFDataType.AutoNumber ||
          attrib.DataType === RAFDataType.Formula
        ) {
          obj.operators = [
            { key: "Starts With", value: "startswith" },
            { key: "Ends With", value: "endswith" },
            { key: "Contains", value: "contains" },
            { key: "Is", value: "equal" },
            { key: "Is Not", value: "notequal" },
            { key: "Is Empty", value: "isempty" },
            { key: "Is Not Empty", value: "isnotempty" },
            { key: "Does Not Contain", value: "notcontains" },
          ];
        }
        if (attrib.DataType === RAFDataType.Date) {
          //obj.format = 'dd/MM/yyyy';
          obj.type = SFColumnType.datetime;
          //obj.template = {
          //    create: () => {
          //        this.valueElement = document.createElement('input');
          //        this.valueElement.setAttribute('type', 'text');
          //        //this.valueElement.id = props.PropertyName;
          //        return this.valueElement;

          //    },
          //    destroy: (args: { elementId: string }) => {
          //        this.dateRangeObj = getComponent(document.getElementById(args.elementId), 'daterangepicker') as DateRangePicker;
          //        if (this.dateRangeObj) {
          //            this.dateRangeObj.destroy();
          //        }
          //    },
          //    //write: (args: { elements: Element, values: Date[], operator: string }) => {
          //    write: (args: { elements: Element, values: string | string[], operator: string }) => {
          //        let startDate: Date = null;
          //        let endDate: Date = null;
          //        if (isNotNullAndUndefined(args.values)) {
          //            if (!isArray(args.values) || isString(args.values)) {
          //                const dateRange: string[] = args.values.toString().split(",");
          //                if (isNotNullAndUndefined(dateRange)) {
          //                    if (dateRange.length > 1) {
          //                        startDate = moment(dateRange[0], MomentFormats.DATE, true).toDate();
          //                        endDate = moment(dateRange[1], MomentFormats.DATE, true).toDate();
          //                    }
          //                    else if (dateRange.length === 1) {
          //                        startDate = moment(dateRange[0], MomentFormats.DATE, true).toDate();
          //                    }
          //                }
          //            }
          //            else {
          //                const dateRange: string[] = args.values as string[];
          //                if (isNotNullAndUndefined(dateRange)) {
          //                    if (dateRange.length > 1) {
          //                        startDate = moment(dateRange[0], MomentFormats.DATE, true).toDate();
          //                        endDate = moment(dateRange[1], MomentFormats.DATE, true).toDate();
          //                    }
          //                    else if (dateRange.length === 1) {
          //                        startDate = moment(dateRange[0], MomentFormats.DATE, true).toDate();
          //                    }
          //                }
          //            }
          //        }
          //        this.dateRangeObj = new DateRangePicker({
          //            //value: args.values ? args.values as Date[] : null,
          //            startDate: startDate,
          //            endDate: endDate,
          //            presets: this.presetDateModel,
          //            format: RAFDatePickerViewFormat.DATE,
          //            change: (e: any) => {
          //                //console.log(e);
          //                if (isNotNullAndUndefined(e.value)) {
          //                    //let retVal = formatValue(e.value[0]) + " - " + formatValue(e.value[1]);
          //                    //console.log(retVal);
          //                    //this.qryBldrObj.notifyChange(retVal, e.element);
          //                    let filterVal: string[] = [];
          //                    filterVal.push(moment(e.startDate).format(MomentFormats.DATE).toString());
          //                    filterVal.push(moment(e.endDate).format(MomentFormats.DATE).toString());
          //                    //console.log('filterVal', filterVal);
          //                    this.qryBldrObj.notifyChange(filterVal, e.element);
          //                }
          //            }
          //        });
          //        this.dateRangeObj.appendTo('#' + args.elements.id);
          //    }
          //}
          obj.template = {
            create: () => {
              //this.valueElement = document.createElement('input');
              //this.valueElement.setAttribute('type', 'text');
              ////this.valueElement.id = props.PropertyName;
              //return this.valueElement;

              return document.createElement("div");
            },
            destroy: () => {
              //debugger;
            },
            //write: (args: { elements: Element, values: Date[], operator: string }) => {
            write: (args: {
              elements: Element;
              values: string | string[];
              operator: string;
            }) => {
              let startDate: Date = null;
              let endDate: Date = null;
              if (isNotNullAndUndefined(args.values)) {
                if (!isArray(args.values) || isString(args.values)) {
                  const dateRange: string[] = args.values.toString().split(",");
                  if (isNotNullAndUndefined(dateRange)) {
                    if (dateRange.length > 1) {
                      startDate = moment(
                        dateRange[0],
                        MomentFormats.DATE,
                        true
                      ).toDate();
                      endDate = moment(
                        dateRange[1],
                        MomentFormats.DATE,
                        true
                      ).toDate();
                    } else if (dateRange.length === 1) {
                      startDate = moment(
                        dateRange[0],
                        MomentFormats.DATE,
                        true
                      ).toDate();
                    }
                  }
                } else {
                  const dateRange: string[] = args.values as string[];
                  if (isNotNullAndUndefined(dateRange)) {
                    if (dateRange.length > 1) {
                      startDate = moment(
                        dateRange[0],
                        MomentFormats.DATE,
                        true
                      ).toDate();
                      // endDate = moment(dateRange[1], MomentFormats.DATE, true).toDate();
                    } else if (dateRange.length === 1) {
                      // startDate = moment(dateRange[0], MomentFormats.DATE, true).toDate();
                    }
                  }
                }
              }
              //this.dateRangeObj = new DateRangePicker({
              //    //value: args.values ? args.values as Date[] : null,
              //    startDate: startDate,
              //    endDate: endDate,
              //    presets: this.presetDateModel,
              //    format: RAFDatePickerViewFormat.DATE,
              //    change: (e: any) => {
              //        //console.log(e);
              //        if (isNotNullAndUndefined(e.value)) {
              //            //let retVal = formatValue(e.value[0]) + " - " + formatValue(e.value[1]);
              //            //console.log(retVal);
              //            //this.qryBldrObj.notifyChange(retVal, e.element);
              //            let filterVal: string[] = [];
              //            filterVal.push(moment(e.startDate).format(MomentFormats.DATE).toString());
              //            filterVal.push(moment(e.endDate).format(MomentFormats.DATE).toString());
              //            //console.log('filterVal', filterVal);
              //            this.qryBldrObj.notifyChange(filterVal, e.element);
              //        }
              //    }
              //});
              //this.dateRangeObj.appendTo('#' + args.elements.id);
              const root = ReactDOMClient.createRoot(args.elements);

              root.render(
                <RAFCustomDateFilter
                  values={args.values}
                  //ref={(scope) => { this.dObj = scope; }}
                  onChange={(value: string[] | string, element: Element) => {
                    //console.log("RAFCustomDateFilter value=", value);
                    this.qryBldrObj.notifyChange(value, element);
                  }}
                />
              );
            },
          };
          obj.operators = [
            //{ key: 'Between', value: 'Between' },
            //{ key: 'Not Between', value: 'NotBetween' }
            { key: "Is", value: "equal" },
            { key: "Is Not", value: "notequal" },
            { key: "Is Empty", value: "isnull" },
            { key: "Is Not Empty", value: "isnotnull" },
            { key: "Is Greater Than", value: "greaterthan" },
            { key: "Is Greater Than Or Equal", value: "greaterthanorequal" },
            { key: "Is Less Than", value: "lessthan" },
            { key: "Is Less Than Or Equal", value: "lessthanorequal" },
          ];
        }
        if (attrib.DataType === RAFDataType.Dropdown) {
          obj.template = {
            create: () => {
              this.valueElement = document.createElement("input");
              this.valueElement.setAttribute("type", "text");
              return this.valueElement;
            },
            destroy: (args: { elementId: string; }) => {
              this.dropDownObj = getComponent(
                document.getElementById(args.elementId),
                "dropdownlist"
              ) as DropDownList;
              if (this.dropDownObj) {
                this.dropDownObj.destroy();
              }
            },
            write: (args: {
              elements: Element;
              values: string[] | string;
              operator: string;
            }) => {
              let ds: { [key: string]: Object; }[] = [];
              let fields: object = { text: "label", value: "value" };
              if (isNotNullAndUndefined(attrib.ValueJson)) {
                attrib.ValueJson.map((opt: ValueJson) => {
                  ds.push({
                    label: opt.DisplayName,
                    value:
                      isNotNullAndUndefined(attrib.UIType) &&
                        (attrib.UIType === RAFUIType.RadioButton ||
                          attrib.UIType === RAFUIType.ToggleButton ||
                          attrib.UIType === RAFUIType.SmileyToggle)
                        ? opt.Name
                        : opt.DisplayName,
                  });
                });
              }

              this.dropDownObj = new DropDownList({
                dataSource: ds,
                fields: fields,
                value: args.values ? (args.values as string) : false,
                change: (e: any) => {
                  this.qryBldrObj.notifyChange(e.itemData.value, e.element);
                },
              });
              this.dropDownObj.appendTo("#" + args.elements.id);
            },
          };
          obj.operators = [
            { key: "Is", value: "equal" },
            { key: "Is Not", value: "notequal" },
            { key: "Is Empty", value: "isempty" },
            { key: "Is Not Empty", value: "isnotempty" },
          ];
        }
        if (attrib.DataType === RAFDataType.Boolean) {
          obj.template = {
            create: () => {
              this.valueElement = document.createElement("input");
              this.valueElement.setAttribute("type", "text");
              return this.valueElement;
            },
            destroy: (args: { elementId: string; }) => {
              this.dropDownObj = getComponent(
                document.getElementById(args.elementId),
                "dropdownlist"
              ) as DropDownList;
              if (this.dropDownObj) {
                this.dropDownObj.destroy();
              }
            },
            write: (args: {
              elements: Element;
              values: string[] | string;
              operator: string;
            }) => {
              let ds: { [key: string]: Object; }[] = [
                { label: "Yes", value: "true" },
                { label: "No", value: "false" },
              ];
              let fields: object = { text: "label", value: "value" };
              this.dropDownObj = new DropDownList({
                dataSource: ds,
                fields: fields,
                value: args.values ? (args.values as string) : false,
                change: (e: any) => {
                  this.qryBldrObj.notifyChange(e.itemData.value, e.element);
                },
              });
              this.dropDownObj.appendTo("#" + args.elements.id);
            },
          };
          obj.operators = [
            { key: "Is", value: "equal" },
            { key: "Is Not", value: "notequal" },
          ];
        }
        //if (attrib.DataType === RAFDataType.Boolean) {
        //    obj.template = {
        //        create: () => {
        //            this.valueElement = document.createElement('input');
        //            this.valueElement.setAttribute('type', 'checkbox');
        //            return this.valueElement;
        //        },
        //        destroy: (args: { elementId: string }) => {
        //            (getComponent(document.getElementById(args.elementId), 'checkbox') as CheckBox).destroy();
        //        },
        //        write: (args: { elements: Element, values: string }) => {
        //            this.checkboxObj = new CheckBox({
        //                change: (e: any) => {
        //                    this.qryBldrObj.notifyChange(e.checked, e.event.target);
        //                },
        //                checked: args.values === 'true' ? true : false,
        //                created: (e:any) => {
        //                    setTimeout(() => {
        //                        this.qryBldrObj && this.qryBldrObj.notifyChange(args.values === 'true' ? true : false, this.checkboxObj.element);
        //                    },100)
        //                },

        //            });

        //            this.checkboxObj.appendTo('#' + args.elements.id);
        //        }
        //    }
        //    obj.operators = [
        //        { key: 'Is', value: 'equal' },
        //        { key: 'Is Not', value: 'notequal' }];
        //}

        /*
                if(attrib.DataType === RAFDataType.Lookup) {
                    let url;
                    //if (isNotNullAndUndefined(attrib.ValueJson)) {
                    //    const firstItem = attrib.ValueJson[0];
                    //    url = isNotNullAndUndefined(firstItem.Name) ? firstItem.Name.replace(/_/g, "").replace(/\s/g, "") + "/Lookup" : null;
                    //}

                    if (isNotNullAndUndefined(attrib.RelatedEntities)) {
                        const firstItem = attrib.RelatedEntities;
                        url = isNotNullAndUndefined(firstItem) ? firstItem.replace(/_/g, "").replace(/\s/g, "") + "/Lookup" : null;
                    }

                    obj.template = {
                        create: () => {
                            this.valueElement = document.createElement('input');
                            this.valueElement.setAttribute('type', 'text');
                            return this.valueElement;
                        },
                        destroy: (args: { elementId: string }) => {
                            this.lookupObj = getComponent(document.getElementById(args.elementId), 'autocomplete') as AutoComplete;
                            if (this.lookupObj) {
                                this.lookupObj.destroy();
                            }
                        },
                        write: (args: { elements: Element, values: string[] | string, operator: string }) => {
                            this.lookupObj = new AutoComplete({
                                value: args.values ? args.values as string : null,
                                change: (e: ChangeEventArgs) => {
                                    this.qryBldrObj.notifyChange((e.itemData !== null ? e.itemData['Value'] : null), e.element);
                                },
                                dataSource: this.data,
                                fields: this.fields,
                                filterType: 'Contains',
                                showPopupButton: true, highlight: true, showClearButton: true,
                                filtering: (e: FilteringEventArgs) => {
                                    let data: { [key: string]: Object; }[] = [];
                                    if (isNotNullAndUndefined(url)) {
                                        this.getLookupData(0, 10, url, e.text).then((dropdownData) => {
                                            if (isNotNullAndUndefined(dropdownData) && isNotNullAndUndefined(dropdownData['result'])) {
                                                for (let i: number = 0; i < dropdownData['result'].length; i++) {
                                                    data.push(dropdownData['result'][i] as any);
                                                }
                                            }
                                            e.updateData(data, null);
                                        });

                                    }
                                },
                                dataBound: () => {
                                    if (this.lookupObj && (this.lookupObj.dataSource instanceof Array
                                        && !(this.lookupObj.dataSource as object[]).length)) {
                                        if (isNotNullAndUndefined(url)) {

                                            this.getLookupData(0, 10, url, null).then((dropdownData) => {
                                                if (this.lookupObj) {
                                                    this.lookupObj.dataSource = dropdownData['result'];
                                                }
                                            });
                                        }
                                    }
                                }
                            });
                            this.lookupObj.appendTo('#' + args.elements.id);
                        }
                    }
                    obj.operators = [
                        { key: 'Is', value: 'equal' },
                        { key: 'Is Not', value: 'notequal' },
                        { key: 'Is Empty', value: 'isempty' },
                        { key: 'Is Not Empty', value: 'isnotempty' }];
                }
                */

        if (attrib.DataType === RAFDataType.Lookup) {
          let url;

          if (isNotNullAndUndefined(attrib.RelatedEntities)) {
            const firstItem = attrib.RelatedEntities;
            url = isNotNullAndUndefined(firstItem)
              ? firstItem.replace(/_/g, "").replace(/\s/g, "") + "/Lookup"
              : null;
          }

          obj.template = {
            create: () => {
              this.valueElement = document.createElement("input");
              this.valueElement.setAttribute("type", "text");
              this.valueElement.id = attrib.PropertyName;
              return this.valueElement;
            },
            destroy: (args: { elementId: string; }) => {
              if (args.elementId && document.getElementById(args.elementId)) {
                this.lookupObj = getComponent(
                  document.getElementById(args.elementId),
                  "dropdownlist"
                ) as DropDownList;
                if (this.lookupObj) {
                  this.lookupObj.destroy();
                }
              }
            },
            write: (args: {
              elements: Element;
              values: string[] | string;
              operator: string;
            }) => {
              const lookupFields = { text: "Value", value: "Value" };
              //let isFiltering = false;
              let skip = 0;
              let take: number = Constants.DropdownFetchCount;
              let filteredSkip = 0;
              let filteredTake: number = Constants.DropdownFetchCount;
              const lookupData: RAFDataManager = new RAFDataManager({
                adaptor: new RAFUrlAdaptor({
                  Skip: 0,
                  Take: Constants.DropdownFetchCount,
                }),
                crossDomain: true,
                url: Constants.baseAPIUrl + url,
                requestType: "POST",
                //enableCaching: true,
              });
              const setFilteringDebounce = debounce((args1) => {
                //  isFiltering = true;
                filteredSkip = 0;
                filteredTake = Constants.DropdownFetchCount;
                lookupData.adaptor = new RAFUrlAdaptor({
                  Skip: filteredSkip,
                  Take: filteredTake,
                });
                let query = new Query();
                query =
                  args1.text !== ""
                    ? query.where(
                      attrib.PropertyName,
                      "contains",
                      args1.text,
                      true
                    )
                    : query;
                args1.updateData(lookupData, query);
              }, 500);
              const actionBegin = (args1) => {
                if (args1.query === undefined) {
                  //temp fix to prevent repeated api calls
                  args1.cancel = true;
                }
              };
              const actionComplete = (e) => {
                if (
                  isNotNullAndUndefined(attrib.RelatedEntities) &&
                  attrib.RelatedEntities === RAFEntityName.User
                ) {
                  e.result.splice(0, 0, {
                    UID: SystemVariable.CURRENTUSER,
                    Value: SystemVariable.CURRENTUSER_DISPLAYNAME,
                  });
                }
                e.result = R.uniq(e.result);
              };
              const opened = () => {
                let listElement: HTMLElement = (lookupObj as any).list;
                listElement.addEventListener("scroll", (args) => {
                  if (
                    listElement.scrollTop + listElement.offsetHeight + 1 >=
                    listElement.scrollHeight
                  ) {
                    setScrollDebounce(args);
                  }
                });
              };
              const onCreated = () => {
                if (lookupObj) {
                  const tooltip = new Tooltip({
                    beforeRender: (args1: TooltipEventArgs) => {
                      if (isNotNullAndUndefined(tooltip)) {
                        tooltip.content = args1.target.textContent;
                      }
                    },
                    content: "Loading...",
                    position: "TopCenter",
                    target:
                      isNotNullAndUndefined(lookupObj) &&
                        isNotNullAndUndefined(lookupObj.element)
                        ? `#${lookupObj.element.id}_popup .e-list-item`
                        : ".e-list-item",
                  });
                  tooltip.appendTo("body");
                }
              };
              const onClosed = () => {
                let openTooltips = document.querySelectorAll(
                  "div.e-tooltip-wrap.e-popup-open"
                );
                if (isNotNullAndUndefined(openTooltips)) {
                  openTooltips.forEach((x) => {
                    x.classList.add("hidden");
                  });
                }
              };
              const setScrollDebounce = debounce(() => {
                let filterQuery = isNotNullAndUndefined(lookupObj.query)
                  ? lookupObj.query.clone()
                  : new Query();
                const filteredText =
                  lookupObj["searchKeyModule"]["element"]["value"];
                if (
                  isNotNullAndUndefined(filteredText) &&
                  filteredText !== ""
                ) {
                  filteredSkip += filteredTake;
                  filterQuery = filterQuery.where(
                    attrib.PropertyName,
                    "contains",
                    filteredText,
                    true
                  );
                  lookupData.adaptor = new RAFUrlAdaptor({
                    Skip: filteredSkip,
                    Take: filteredTake,
                  });
                } else {
                  skip += take;
                  lookupData.adaptor = new RAFUrlAdaptor({
                    Skip: skip,
                    Take: take,
                  });
                }
                lookupData
                  //.executeQuery(filterQuery.range(start, end))
                  .executeQuery(filterQuery)
                  .then((event) => {
                    //start = end;
                    //end += 5;
                    lookupObj.addItem(
                      (event as any).result as { [key: string]: Object; }[]
                    );
                  })
                  .catch();
              }, 500);
              const lookupObj: DropDownList = new DropDownList({
                value: isNotNullAndUndefined(args.values)
                  ? (args.values as string) === SystemVariable.CURRENTUSER
                    ? SystemVariable.CURRENTUSER_DISPLAYNAME
                    : (args.values as string)
                  : isNotNullAndUndefined(filterVal)
                    ? filterVal
                    : null,
                change: (e: ChangeEventArgs) => {
                  this.qryBldrObj.notifyChange(
                    e.itemData !== null
                      ? e.itemData["UID"] === SystemVariable.CURRENTUSER
                        ? e.itemData["UID"]
                        : e.itemData["Value"]
                      : null,
                    e.element
                  );
                },
                dataSource: lookupData,
                fields: lookupFields,
                filterType: "Contains",
                allowFiltering: true,
                showClearButton: true,
                filtering: (e: FilteringEventArgs) => {
                  e.preventDefaultAction = true;
                  setFilteringDebounce(e);
                  //if (isNotNullAndUndefined(url)) {
                  //    let query = new Query();
                  //    // frame the query based on search string with filter type.
                  //    query = (e.text !== "") ? query.where(attrib.PropertyName, "contains", e.text, true) : query;
                  //    // pass the filter data source, filter query to updateData method.
                  //    e.updateData(lookupData, query);
                  //}
                },
                actionBegin: actionBegin,
                actionComplete: actionComplete,
                created: onCreated,
                close: onClosed,
                open: (e) => {
                  opened();
                },
              });
              //this.lookupObj.appendTo('#' + args.elements.id);
              const objElement = document.querySelector("#" + args.elements.id);
              if (
                isNotNullAndUndefined(objElement) &&
                isNotNullAndUndefined(objElement.classList) &&
                !objElement.classList.contains("e-dropdownlist")
              ) {
                lookupObj.appendTo("#" + args.elements.id);
              }
            },
          };
          obj.operators = [
            { key: "Is", value: "equal" },
            { key: "Is Not", value: "notequal" },
            { key: "Is Empty", value: "isempty" },
            { key: "Is Not Empty", value: "isnotempty" },
          ];
        }

        //obj.label = attrib.DisplayName;
        obj.label = GetClientTerm(
          this.state.clientTerms,
          attrib.PropertyName,
          attrib.DisplayName
        );
        retVal.push(obj);
      }
    }
    return retVal;
  };

  updateRule(args: RuleChangeEventArgs): void {
    this.filterRule = args.rule;
  }

  onRuleDeleted(args): void {
    if (isNotNullAndUndefined(args)) {
      let groupId = args["groupID"];
      if (isNotNullAndUndefined(groupId)) {
        let groupElement = document.querySelector("[id*=" + groupId + "]");
        let type: string = args["type"];
        if (
          isNotNullAndUndefined(groupId) &&
          isNotNullAndUndefined(type) &&
          type === "deleteRule" &&
          groupId !== "group0"
        ) {
          let groupRule: RuleModel =
            this.qryBldrObj && this.qryBldrObj.getGroup(groupId);
          if (
            isNotNullAndUndefined(groupRule) &&
            isNotNullAndUndefined(groupRule.rules) &&
            groupRule.rules.length === 0
          ) {
            if (isNotNullAndUndefined(groupElement))
              this.qryBldrObj && this.qryBldrObj.deleteGroup(groupElement);
          }
        }
      }
    }
  }

  enableSubmitButton = (g: FormRenderProps | null) => {
    if (g) {
      if (isNotNullAndUndefined(this.submitButton)) {
        this.submitButton.disabled = !g.valid || g.submitting;
      }
    }
  };

  fitlerPanelDecorators = createDecorator({
    field: "DisplayName",
    updates: {
      ViewName: (minimumValue, allValues: RAFViewRow) =>
        IsNullOrWhiteSpace(allValues.UID) || this.props.isCloneView === true
          ? ConvertSystemName(allValues.DisplayName)
          : allValues.ViewName,
    },
  });

  // ColumnChooser

  onSortEnd = (oldIndex, newIndex, stateName) => {
    if (this._isMounted) {
      this.setState(
        (state) => {
          return {
            [stateName]: R.move(oldIndex, newIndex, state[stateName]),
          };
        },
        () => {
          if (isNotNullAndUndefined(this.searchTextbox)) {
            this.searchTextbox.value = "";
          }
        }
      );
    }
  };

  onCheckChange = (propertyName, checked) => {
    const items: QueryAttributeJM[] = this.state.whereAttributes;
    let rafViewRow: RAFViewRow = this.state.rafViewRow;
    let sortIndex =
      rafViewRow && rafViewRow.OrderByJson
        ? rafViewRow.OrderByJson.findIndex((x) => x.Field === propertyName)
        : -1;
    if (checked === false && sortIndex > -1) {
      rafViewRow.OrderByJson.splice(sortIndex, 1);
    }
    const updatedAttributes = items.map((item) => {
      if (item.PropertyName === propertyName && checked === true) {
        item.isSelectedColumn = checked;
      }
      return item;
    });
    const updatedAttributes1 = items.map((item) => {
      if (item.PropertyName === propertyName && checked === false) {
        item.isSelectedColumn = checked;
      }
      return item;
    });
    let visibleColumns = updatedAttributes.filter(
      (x) => x.isSelectedColumn === true
    );
    let unSelectedColumns = updatedAttributes1.filter(
      (x) => x.isSelectedColumn === false && x.IsEditable === true
    );
    unSelectedColumns.sort((x, y) =>
      x.PropertyName > y.PropertyName ? 1 : -1
    );

    let unSelectedSystemColumns = updatedAttributes1.filter(
      (x) => x.isSelectedColumn === false && x.IsEditable === false
    );
    unSelectedSystemColumns.sort((x, y) =>
      x.PropertyName > y.PropertyName ? 1 : -1
    );

    if (this._isMounted) {
      this.setState(
        {
          whereAttributes: updatedAttributes,
          filteredAttributes: visibleColumns,
          filteredAttributes1: unSelectedColumns,
          filteredAttributes2: unSelectedSystemColumns,
          rafViewRow: rafViewRow,
          listBoxComponentKey: Math.random(),
        },
        () => {
          if (isNotNullAndUndefined(this.searchTextbox)) {
            this.searchTextbox.value = "";
          }
        }
      );
    }
  };

  ColumnChooserChanged = (
    attributes: QueryAttributeJM[],
    rafViewRow: RAFViewRow
  ): RAFViewRow => {
    let rafView: RAFViewRow = rafViewRow;
    let selectcolumnsArray = [];
    let clonedAttributes = R.clone(attributes);
    clonedAttributes.forEach((item) => {
      if (
        isNotNullAndUndefined(item.isSelectedColumn) &&
        item.isSelectedColumn === true
      ) {
        selectcolumnsArray.push(item.PropertyName);
      }
    });

    let selectColumnjson: ViewSelect = {};
    if (
      isNotNullAndUndefined(rafView) &&
      isNotNullAndUndefined(rafView.SelectColumnjson)
    ) {
      selectColumnjson.DataColumns = rafView.SelectColumnjson.DataColumns;
      if (isNullOrUndefined(selectColumnjson.DataColumns)) {
        selectColumnjson.DataColumns = defaultViewDataColumns(this.moduleName);
      }
    }

    if (
      isNotNullAndUndefined(selectcolumnsArray) &&
      selectcolumnsArray.length > 0
    ) {
      //if (isNotNullAndUndefined(selectColumnjson))
      selectColumnjson.SelectColumns = selectcolumnsArray;
    }
    //else {
    //    rafView.SelectColumnjson = null;
    //}
    rafView.SelectColumnjson = selectColumnjson;

    return rafView;
  };

  CloseFilterDialog = () => {
    //if (this.props.requiredFieldCount > 0) {
    //    selectedCount >= this.props.requiredFieldCount ? this.props.onClose() : showWarningToast("You should select atleast " + this.props.requiredFieldCount + " column(s)");
    //    return;
    //}
    //else {
    //    this.props.onClose();
    //}
    this.props.onClose();
  };

  searchColumn = (value) => {
    const items: QueryAttributeJM[] = this.state.whereAttributes;
    let filteredAttributes = items.filter((x) =>
      x.DisplayName.toLowerCase().includes(value.toLowerCase())
    );
    let filteredAttributes1 = items.filter((x) =>
      x.DisplayName.toLowerCase().includes(value.toLowerCase())
    );
    let visibleColumns = filteredAttributes.filter(
      (x) => x.isSelectedColumn === true
    );
    let unSelectedColumns = filteredAttributes1.filter(
      (x) => x.isSelectedColumn === false && x.IsEditable === true
    );
    unSelectedColumns.sort((x, y) =>
      x.PropertyName > y.PropertyName ? 1 : -1
    );

    let unSelectedSystemColumns = filteredAttributes1.filter(
      (x) => x.isSelectedColumn === false && x.IsEditable === false
    );
    unSelectedColumns.sort((x, y) =>
      x.PropertyName > y.PropertyName ? 1 : -1
    );

    if (this._isMounted) {
      this.setState(
        {
          filteredAttributes: visibleColumns,
          filteredAttributes1: unSelectedColumns,
          filteredAttributes2: unSelectedSystemColumns,
        },

        () => {
          if (isNotNullAndUndefined(this.searchTextbox)) {
            this.searchTextbox.value = value;
          }
        }
      );
    }
  };

  private cloneFilterDialogClose(): void {
    //document.body.classList.remove('overflow-hidden');
    if (this._isMounted) {
      this.setState({ showSubmitFilterDialog: false }, () => {
        //this.viewClone = false;
        //this.cloneFilterDialog.hide();
      });
    }
  }

  CloneFilterDialogToggle = () => {
    let rafViewRow: RAFViewRow = this.rafForm.values; //  this.state.rafViewRow;
    if (this._isMounted) {
      this.setState(
        { showSubmitFilterDialog: true, centerDlgHeaderTitle: "Clone View" },
        () => {
          //this.viewClone = true;
          setTimeout(() => {
            if (
              isNotNullAndUndefined(this.rafForm1) &&
              isNotNullAndUndefined(this.rafForm1.form) &&
              isNotNullAndUndefined(this.rafForm.form.mutators)
            ) {
              this.rafForm1.form.mutators.setValue(
                "DisplayName",
                rafViewRow.DisplayName + "- Copy"
              ); //
              this.rafForm1.form.mutators.setValue(
                "SettingsJson.IsPrivate",
                rafViewRow.SettingsJson && rafViewRow.SettingsJson.IsPrivate
              );
            }
          }, 100);
        }
      );
      //document.body.classList.add('overflow-hidden');
    }
  };

  CloneFilterClicked1 = (value: RAFViewRow, selectedItemCount: number) => {
    let mobileColumnCount =
      isNotNullAndUndefined(value) &&
        isNotNullAndUndefined(value.SettingsJson) &&
        isNotNullAndUndefined(value.SettingsJson.MobileColumnCount)
        ? value.SettingsJson.MobileColumnCount
        : null;
    if (isNotNullAndUndefined(mobileColumnCount)) {
      if (mobileColumnCount >= 1 && mobileColumnCount <= selectedItemCount) {
        this.OnSubmitFilterClicked(value);
      } else {
        //showWarningToast("Mobile Column Count value must be between 1 and " + selectedItemCount);
        showWarningToast(
          "Mobile column count should be less than or equal to selected column count"
        );
      }
    } else {
      //showWarningToast("Mobile Column Count value must be between 1 and " + selectedItemCount);
      showWarningToast(
        "Mobile column count should be less than or equal to selected column count"
      );
    }
  };

  CloneFilterClicked = (value: RAFViewRow, selectedItemCount?: number) => {
    this.OnSubmitFilterClicked(value, "saveAsCopyContent_");
  };

  onSubmitFormClick = () => {
    let rafViewRow: RAFViewRow = this.rafForm.values;
    //selected columns
    let selectedCount = this.state.filteredAttributes.filter(
      (x) => x.isSelectedColumn === true
    ).length;
    if (!this.isNewView) {
      if (selectedCount >= this.props.requiredFieldCount) {
        this.OnSubmitFilterClicked(rafViewRow);
      } else {
        showWarningToast(
          `You should select atleast ${this.props.requiredFieldCount} column(s)`
        );
      }
    } else if (this.props.requiredFieldCount > 0) {
      if (this.isAdmin) {
        if (selectedCount > 0) {
          if (this._isMounted) {
            this.setState(
              {
                showSubmitFilterDialog: true,
                centerDlgHeaderTitle: isNotNullAndUndefined(rafViewRow.UID)
                  ? "Update - " + rafViewRow.DisplayName
                  : "Add Table View",
              },
              () => {
                setTimeout(() => {
                  if (
                    isNotNullAndUndefined(this.rafForm1) &&
                    isNotNullAndUndefined(this.rafForm1.form) &&
                    isNotNullAndUndefined(this.rafForm.form.mutators)
                  ) {
                    this.rafForm1.form.mutators.setValue(
                      "DisplayName",
                      rafViewRow.DisplayName
                    );
                    this.rafForm1.form.mutators.setValue(
                      "SettingsJson.IsPrivate",
                      rafViewRow.SettingsJson &&
                      rafViewRow.SettingsJson.IsPrivate
                    );
                  }
                }, 100);
              }
            );
          }
        } else {
          showWarningToast("You should select atleast 1 column(s)");
          return;
        }
      } else if (selectedCount >= this.props.requiredFieldCount) {
        if (this._isMounted) {
          this.setState(
            {
              showSubmitFilterDialog: true,
              centerDlgHeaderTitle: isNotNullAndUndefined(rafViewRow.UID)
                ? "Update - " + rafViewRow.DisplayName
                : "Add Table View",
            },
            () => {
              setTimeout(() => {
                if (
                  isNotNullAndUndefined(this.rafForm1) &&
                  isNotNullAndUndefined(this.rafForm1.form) &&
                  isNotNullAndUndefined(this.rafForm.form.mutators)
                ) {
                  this.rafForm1.form.mutators.setValue(
                    "DisplayName",
                    rafViewRow.DisplayName
                  );
                  this.rafForm1.form.mutators.setValue(
                    "SettingsJson.IsPrivate",
                    rafViewRow.SettingsJson && rafViewRow.SettingsJson.IsPrivate
                  );
                }
              }, 100);
            }
          );
        }
      } else {
        showWarningToast(
          "You should select atleast " +
          this.props.requiredFieldCount +
          " column(s)"
        );
        return;
      }
    } else {
      if (this._isMounted) {
        this.setState(
          {
            showSubmitFilterDialog: true,
            centerDlgHeaderTitle: isNotNullAndUndefined(rafViewRow.UID)
              ? "Update - " + rafViewRow.DisplayName
              : "Add Table View",
          },
          () => {
            setTimeout(() => {
              if (
                isNotNullAndUndefined(this.rafForm1) &&
                isNotNullAndUndefined(this.rafForm1.form) &&
                isNotNullAndUndefined(this.rafForm.form.mutators)
              ) {
                this.rafForm1.form.mutators.setValue(
                  "DisplayName",
                  rafViewRow.DisplayName
                );
                this.rafForm1.form.mutators.setValue(
                  "SettingsJson.IsPrivate",
                  rafViewRow.SettingsJson && rafViewRow.SettingsJson.IsPrivate
                );
              }
            }, 100);
          }
        );
      }
    }
  };

  DeleteFilterClicked = () => {
    this.deleteViewDialog = DialogUtility.confirm({
      animationSettings: { effect: "Fade" },
      cancelButton: { text: "No" },
      closeOnEscape: false,
      content: "Are you sure want to delete?",
      okButton: { text: "Yes", click: this.DeleteView.bind(this) },
      title: " Delete View",
      position: { X: "center", Y: "center" },
      cssClass: "raf-delete_alert_dialog alert-dialog",
      // cssClass: "alert-dialog",
    });
  };

  DeleteView() {
    let { viewId } = this.state;
    if (isNotNullAndUndefined(viewId)) {
      // let progressDiv = showProgress("body", true);
      let progressDiv = showProgress(".raf-delete_alert_dialog.e-dialog");
      getDependenciesByViewID(viewId).then((dependenciesItem) => {
        let isDepended =
          isNotNullAndUndefined(dependenciesItem) && dependenciesItem.length > 0
            ? true
            : false;
        if (isDepended !== true) {
          DeleteRecord(viewId, RAFEntityName.View)
            .then((response) => {
              hideProgress(progressDiv);
              this.deleteViewDialog.hide();
              if (response === true) {
                removeSessionStorage(
                  StorageKey.recentViewId_modulename + this.moduleName
                );
                removeSessionStorage(
                  StorageKey.allViews_modulename + this.moduleName
                );
                this.props.onDelete(this.moduleName);
                this.props.onClose();
              } else {
                showWarningToast("Sorry something went wrong !");
              }
            })
            .catch((error) => error);
        } else {
          hideProgress(progressDiv);
          let viewType = [];
          dependenciesItem.forEach((item) => {
            if (isNotNullAndUndefined(item)) {
              viewType.push(item.Type);
            }
          });
          showWarningToast(
            "You cannot delete this view. This view is referred in " +
            viewType.toString() +
            "."
          );
        }
      });
    } else {
      showWarningToast("Sorry this record cannot be deleted");
    }
  }

  onSortChange = (propertyName) => {
    let rafViewRow: RAFViewRow = this.state.rafViewRow;
    let sortDiv = document.querySelector("#sortDiv_" + propertyName);
    rafViewRow.OrderByJson = [];
    if (isNotNullAndUndefined(sortDiv) && isNotNullAndUndefined(rafViewRow)) {
      if (
        sortDiv.classList.contains("fa-sort-Asc") &&
        sortDiv.classList.contains("active")
      ) {
        rafViewRow.OrderByJson.push({ Field: propertyName, Descending: true });
      } else if (
        sortDiv.classList.contains("fa-sort-Desc") &&
        sortDiv.classList.contains("active")
      ) {
        rafViewRow.OrderByJson = [];
      } else {
        rafViewRow.OrderByJson.push({ Field: propertyName, Descending: false });
      }
    }
    if (this._isMounted) {
      this.setState(
        { rafViewRow: rafViewRow, listBoxComponentKey: Math.random() },
        () => {
          if (isNotNullAndUndefined(this.searchTextbox)) {
            this.searchTextbox.value = "";
          }
        }
      );
    }
  };

  saveAsCopyContent(allowpermission: boolean) {
    if (
      isNotNullAndUndefined(this.state.showSubmitFilterDialog) &&
      this.state.showSubmitFilterDialog === true
    ) {
      let { whereAttributes } = this.state;
      let rafViewRow: RAFViewRow = this.rafForm && this.rafForm.values;

      let allPermissionValue = isNotNullAndUndefined(this.props.permissionValue)
        ? this.props.permissionValue
        : null;

      let selectedItems =
        isNotNullAndUndefined(whereAttributes) && whereAttributes.length > 0
          ? whereAttributes.filter((x) => x.isSelectedColumn === true)
          : null;
      let selectedItemCount = isNotNullAndUndefined(selectedItems)
        ? selectedItems.length
        : 0;

      let initialMobileColumnCount =
        isNotNullAndUndefined(rafViewRow) &&
          isNotNullAndUndefined(rafViewRow.SettingsJson) &&
          isNotNullAndUndefined(rafViewRow.SettingsJson.MobileColumnCount)
          ? rafViewRow.SettingsJson.MobileColumnCount <= selectedItemCount
            ? rafViewRow.SettingsJson.MobileColumnCount
            : selectedItemCount
          : selectedItemCount;

      return (
        <div className="h-100" id="submitFormContent">
          <RAFForm
            type={RAFViewRow}
            initialValues={rafViewRow}
            formRef={(g) => {
              this.enableSubmitButton(g);
              return (this.rafForm1 = g);
            }}
            layout={RAFLayout.TwoColumnLayout}
            onSubmit={(value) =>
              this.CloneFilterClicked(value, selectedItemCount)
            }
            decorators={[this.fitlerPanelDecorators]}
            className="h-100"
          >
            <div className="e-dlg-content-outer">
              <div className="e-dlg-body-content">
                <div>
                  <RAFTextBox<RAFViewRow>
                    field="DisplayName"
                    label="Name"
                    showLabel={true}
                    required
                    placeholder="View Name"
                  />
                  <div className="row">
                    {IsSuperAdmin() === true ? (
                      <div className="col-12 d-none">
                        <RAFNumber<RAFViewRow>
                          field="SettingsJson.MobileColumnCount"
                          label="MobileColumn Count"
                          //placeholder={'between 1 and ' + selectedItemCount}
                          showLabel={true}
                          initialValue={initialMobileColumnCount}
                          minValue={1}
                          maxValue={selectedItemCount}
                          required
                        />
                      </div>
                    ) : (
                      ""
                    )}
                    {hasPermission(
                      allPermissionValue,
                      PermissionConstants.ManageViewPrivate
                    ) && (
                        <div className="col-12">
                          <RAFRadioButtonList
                            field="SettingsJson.IsPrivate"
                            showLabel={true}
                            label="Visiblity"
                            // uitype="userToggle"
                            uitype="customButton"
                            required
                          >
                            <RAFChoiceOption
                              label={RAFViewVisiblity.IsPrivate}
                              value
                            />
                            <RAFChoiceOption
                              label={RAFViewVisiblity.IsPublic}
                              value={false}
                            />
                          </RAFRadioButtonList>
                        </div>
                      )}
                  </div>
                  <div className="row">
                    {isNotNullAndUndefined(this.props.isViewManagement) &&
                      this.props.isViewManagement === true && (
                        <Fragment>
                          <div className="col-6">
                            <RAFCheckBox
                              field="SettingsJson.ShowInList"
                              showLabel={true}
                              uitype="switch"
                              label="Show In List"
                            />
                          </div>
                          <div className="col-6">
                            <RAFCheckBox<RAFViewRow>
                              field="IsSystem"
                              showLabel={true}
                              uitype="switch"
                              label="System View"
                            />
                          </div>
                        </Fragment>
                      )}
                  </div>
                </div>
              </div>
              <div className="e-dlg-footerContent justify-content-end d-flex">
                <div>
                  {allowpermission && allowpermission === true && (
                    <ButtonComponent
                      type="button"
                      onClick={() => {
                        this.rafForm1 && this.rafForm1.form.submit();
                      }}
                      cssClass="e-flat e-primary primary-btn me-2"
                    >
                      OK
                    </ButtonComponent>
                  )}
                  <ButtonComponent
                    type="button"
                    cssClass="e-flat"
                    onClick={this.cloneFilterDialogClose.bind(this)}
                  >
                    Cancel
                  </ButtonComponent>
                </div>
              </div>
            </div>
          </RAFForm>
        </div>
      );
    } else {
      return <div></div>;
    }
  }

  enableColumnGroupingSection() {
    document.getElementById("columnGroupingSection").classList.toggle("hidden");
  }

  onSelecting(e) {
    if (e.isSwiped) {
      e.cancel = true;
    }
  }

  onMoreMenuClicked(args: MenuEventArgs) {
    if (args.item.id === "Delete") {
      this.DeleteFilterClicked();
    }
  }

  public onDropGroup(args: DragEventArgs): void {
    if (isNotNullAndUndefined(args)) {
      this.onSortEnd(
        args.previousIndex,
        args.currentIndex,
        "filteredAttributes"
      );
      //args.items.map((item, index) => {
      //    if (!this.listObj1.getDataByValue(item[this.fields.text] as string)) {/*preventing item manipulation on drag and drop within same list box.*/
      //        console.log("item", item)
      //        //this.onSortEnd(oldIndex, newIndex, 'filteredAttributes') }}
      //        // this.modifiedDataA.addedRecords.push(item);
      //        // this.modifiedDataB.deletedRecords.push(item);
      //    }
      //})
    }
  }

  private attributesTemplate(data: QueryAttributeJM): any {
    let rafViewRow: RAFViewRow = this.state.rafViewRow;
    const { clientTerms } = this.state;
    let sortByField =
      rafViewRow &&
      rafViewRow.OrderByJson &&
      rafViewRow.OrderByJson.find((x) => x.Field === data.PropertyName);
    return (
      <div
        className="checkbox checkbox-primary SortableItem filterSection px-3 py-2"
        data-col-name={data.PropertyName}
      >
        <div className="row g-2">
          <div className="col-auto d-flex align-items-center jutify-content-center">
            <div className="dragIcon">
              <span
                className="fas fa-grip-dots-vertical"
                style={{ fontSize: "18px" }}
              ></span>
            </div>
          </div>
          <div className="col-auto d-flex align-items-center jutify-content-center">
            <span
              className={
                data.isSelectedColumn
                  ? "far fa-check-square checkboxIcon"
                  : "far fa-square checkboxIcon"
              }
              onClick={() => {
                this.onCheckChange(data.PropertyName, !data.isSelectedColumn);
              }}
            ></span>
          </div>
          <div className="col d-flex align-items-center jutify-content-center">
            <label
              htmlFor={`chkbox${data.PropertyName}`}
              className={data.isSelectedColumn ? "" : "font-normal"}
              style={{ cursor: "pointer" }}
            >
              {GetClientTerm(clientTerms, data.PropertyName, data.DisplayName)}
            </label>
          </div>
          <div className="col-auto d-flex align-items-center jutify-content-center">
            <div
              className={
                isNotNullAndUndefined(sortByField)
                  ? "sortIcon active"
                  : "sortIcon"
              }
              onClick={() => this.onSortChange(data.PropertyName)}
            >
              {isNotNullAndUndefined(sortByField) ? (
                <span
                  id={"sortDiv_" + data.PropertyName}
                  className={
                    sortByField && sortByField.Descending
                      ? "fa-icons fa-sort-Desc active"
                      : "fa-icons fa-sort-Asc active"
                  }
                  title={
                    sortByField && sortByField.Descending
                      ? "Sort By DESC"
                      : "Sort By ASC"
                  }
                ></span>
              ) : (
                <span
                  id={"sortDiv_" + data.PropertyName}
                  className="fa-icons fa-sort-Asc"
                  title="Click to Sort By ASC"
                ></span>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderTabContent = () => {
    // let { } = this.state;
    // this.isAdmin = false;
    // const myURl = getQueryString("admin");
    // if (isNotNullAndUndefined(myURl) && myURl === "1") {
    //     this.isAdmin = true;
    // }
    const whereAttributes = this.state.whereAttributes;
    let rafViewRow: RAFViewRow = this.state.rafViewRow;
    let orderByJson: SortBy[] = [{ Field: null, Descending: false }];
    let groypByJson: GroupByJM[] = [
      { Field: null, AggregateFunction: null },
      { Field: null, AggregateFunction: null },
      { Field: null, AggregateFunction: null },
    ];

    if (isNotNullAndUndefined(rafViewRow)) {
      if (rafViewRow.OrderByJson && rafViewRow.OrderByJson.length > 0) {
      } else {
        rafViewRow.OrderByJson = orderByJson;
      }
      if (rafViewRow.GroupByJson && rafViewRow.GroupByJson.length > 0) {
      } else {
        rafViewRow.GroupByJson = groypByJson;
      }
    }
    const {
      filteredAttributes,
      filteredAttributes1,
      filteredAttributes2,
      selectedTabIndex,
    } = this.state;
    let selectedItems =
      whereAttributes &&
      whereAttributes.filter((x) => x.isSelectedColumn === true);
    let selectedItemCount = selectedItems && selectedItems.length;
    let totalItemCount = whereAttributes && whereAttributes.length;

    if (selectedTabIndex === 0) {
      return (
        <div className="row g-0 wrapper-content bg-white m-0 columnChooserPanel customScrollBar">
          <div className="col">
            <div className="bg-transparent z-depth-0">
              <div className="mx-3 mt-3">
                <TextBoxComponent
                  ref={(t) => (this.searchTextbox = t)}
                  input={(e) => this.searchColumn(e.value)}
                  showClearButton
                  placeholder="Search Column"
                  autocomplete="off"
                />
              </div>
              <div
                className="filterPanelView mt-1"
                key={this.state.listBoxComponentKey}
              >
                <div className="">
                  <div className="sectionTitle">
                    Selected Columns ({selectedItemCount}/{totalItemCount})
                  </div>
                  {/*<SortableList */}
                  {/* * distance={1} */}
                  {/* * lockAxis="y" */}
                  {/* * items={filteredAttributes}*/}
                  {/* * clientTerms={clientTerms}*/}
                  {/* * mode={this.props.mode} */}
                  {/* * rafView={rafViewRow} */}
                  {/* * onCheckChange={(name, checked) => { this.onCheckChange(name, checked, 'visibleColumns') }} */}
                  {/* * onSortEnd={({ oldIndex, newIndex }) => { this.onSortEnd(oldIndex, newIndex, 'filteredAttributes') }} */}
                  {/* * onSortChange={(propertyName, isSelectedColumn) => this.onSortChange(propertyName, isSelectedColumn)} />*/}
                  <div className="px-3 mt-2">
                    <ListBoxComponent
                      ref={(scope) => {
                        this.listObj1 = scope;
                      }}
                      drop={this.onDropGroup.bind(this)}
                      dataSource={filteredAttributes}
                      allowDragAndDrop
                      fields={this.fields}
                      scope="combined-list"
                      itemTemplate={this.attributesTemplate.bind(this)}
                    />
                  </div>
                </div>
                <div className="available-columns">
                  <div className="sectionTitle">Available Columns</div>
                  {/*<SortableList1 distance={1} lockAxis="xy" items={filteredAttributes1} clientTerms={clientTerms} mode={this.props.mode} rafView={rafViewRow} onCheckChange={(name, checked) => { this.onCheckChange(name, checked, 'visibleColumns') }} onSortChange={(propertyName, isSelectedColumn) => this.onSortChange(propertyName, isSelectedColumn)} />*/}
                  <div className="px-3 mt-2">
                    <ListBoxComponent
                      drop={this.onDropGroup.bind(this)}
                      dataSource={filteredAttributes1}
                      allowDragAndDrop={false}
                      fields={this.fields}
                      itemTemplate={this.attributesTemplate.bind(this)}
                      scope="combined-list"
                    />
                  </div>
                </div>
                <div className="available-columns">
                  <div className="sectionTitle">System Columns</div>
                  {/*<SortableList1 distance={1} lockAxis="xy" items={filteredAttributes1} clientTerms={clientTerms} mode={this.props.mode} rafView={rafViewRow} onCheckChange={(name, checked) => { this.onCheckChange(name, checked, 'visibleColumns') }} onSortChange={(propertyName, isSelectedColumn) => this.onSortChange(propertyName, isSelectedColumn)} />*/}
                  <div className="px-3 mt-2">
                    <ListBoxComponent
                      drop={this.onDropGroup.bind(this)}
                      dataSource={filteredAttributes2}
                      allowDragAndDrop={false}
                      fields={this.fields}
                      itemTemplate={this.attributesTemplate.bind(this)}
                      scope="combined-list"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (selectedTabIndex === 1) {
      return (
        <div
          className="col-md-12 px-0 filterPanel customScrollBar"
          style={{ paddingTop: "0px" }}
        >
          <div className="p-3">
            <div
              className="d-flex flex-row text-dark align-items-center custom flex-wrap"
              style={{ width: "100%" }}
            >
              <div className="hidden">
                <RAFCheckBox
                  field="SettingsJson.IsPrivate"
                  label={"Visible only to me"}
                />
              </div>
            </div>
            <div className="mt-4 mb-3 hidden">
              <RAFSortBy<RAFViewRow>
                field="OrderByJson"
                label="Sort by"
                showLabel={false}
                attributesRow={whereAttributes}
              />
            </div>
            <div>
              <QueryBuilderComponent
                ref={(q) => (this.qryBldrObj = q)}
                width="100%"
                change={this.onRuleDeleted.bind(this)}
                ruleChange={this.updateRule.bind(this)}
                showButtons={this.ButtonOptions}
              />
            </div>
          </div>
        </div>
      );
    } else if (selectedTabIndex === 2) {
      return (
        <div
          className="col-md-12 px-0 filterPanel customScrollBar"
          style={{ paddingTop: "0px" }}
        >
          <div className="px-3">
            <div className="shadow-sm mb-3">
              <RAFCollapse
                title="Row Grouping"
                headerRowClassName="ps-2"
                contentRowClassName="grouping-row"
                layout={RAFLayout.OneColumnLayout}
              >
                <RAFGroupSortBy<RAFViewRow>
                  field="GroupByJson"
                  label="Sort by"
                  showLabel={false}
                  attributesRow={whereAttributes}
                />
              </RAFCollapse>
            </div>
            <div className="shadow-sm mb-3">
              {/*<RAFCollapse title="Column Grouping" headerRowClassName="ps-2" contentRowClassName="grouping-row" customButton={rightData} layout={RAFLayout.OneColumnLayout}>
                                <div id="columnGroupingSection">
                                    <RAFDropdown field="Group columns by">
                                        <RAFChoiceOption label="Account Name" value="Account Name" />
                                        <RAFChoiceOption label="Website" value="Website" />
                                        <RAFChoiceOption label="Owner" value="Owner" />
                                        <RAFChoiceOption label="Work Phone" value="Work Phone" />
                                    </RAFDropdown>
                                    <RAFDropdown field="Then by">
                                        <RAFChoiceOption label="Account Name" value="Account Name" />
                                        <RAFChoiceOption label="Website" value="Website" />
                                        <RAFChoiceOption label="Owner" value="Owner" />
                                        <RAFChoiceOption label="Work Phone" value="Work Phone" />
                                    </RAFDropdown>
                                    <RAFDropdown field="Then by">
                                        <RAFChoiceOption label="Account Name" value="Account Name" />
                                        <RAFChoiceOption label="Website" value="Website" />
                                        <RAFChoiceOption label="Owner" value="Owner" />
                                        <RAFChoiceOption label="Work Phone" value="Work Phone" />
                                    </RAFDropdown>
                                </div>
                            </RAFCollapse>*/}
            </div>
          </div>
        </div>
      );
    } else {
      return <div></div>;
    }
  };

  render() {
    // this.isAdmin = false;
    // const myURl = getQueryString("admin");
    // if (isNotNullAndUndefined(myURl) && myURl === "1") {
    //     this.isAdmin = true;
    // }

    const whereAttributes = this.state.whereAttributes;
    let rafViewRow: RAFViewRow = this.state.rafViewRow;
    let orderByJson: SortBy[] = [{ Field: null, Descending: false }];
    let groypByJson: GroupByJM[] = [
      { Field: null, AggregateFunction: null },
      { Field: null, AggregateFunction: null },
      { Field: null, AggregateFunction: null },
    ];

    if (isNotNullAndUndefined(rafViewRow)) {
      if (rafViewRow.OrderByJson && rafViewRow.OrderByJson.length > 0) {
      } else {
        rafViewRow.OrderByJson = orderByJson;
      }
      if (rafViewRow.GroupByJson && rafViewRow.GroupByJson.length > 0) {
      } else {
        rafViewRow.GroupByJson = groypByJson;
      }
    }
    // const { filteredAttributes, filteredAttributes1, clientTerms } = this.state;
    let selectedItems =
      whereAttributes &&
      whereAttributes.filter((x) => x.isSelectedColumn === true);
    //let selectedItemCount = selectedItems && selectedItems.length;
    // let totalItemCount = whereAttributes && whereAttributes.length;
    {
      /*let rightData =
            <TooltipComponent title="Show / Hide column grouping" position="BottomCenter" >
                <div className="d-flex align-items-center py-3 justify-content-end" onClick={() => this.enableColumnGroupingSection()}>
                    <label htmlFor="checked" className="m-0 pe-3 d-none"> Enable Column Grouping </label>
                    <SwitchComponent id="checked" checked ></SwitchComponent>
                </div>
            </TooltipComponent>*/
    }

    const loggedinUserId = msalInstance.currentUserId;

    let IsSystem = rafViewRow.IsSystem; //true not display
    let allowpermission = false;
    let isNewView = this.isNewView;

    //if (this.isAdmin || (isNotNullAndUndefined(rafViewRow.CreatedByUID) && rafViewRow.CreatedByUID === loggedinUserId)) {
    //    allowpermission = true;// true dispay
    //} else if (IsSystem) {
    //    allowpermission = false;
    //} else {
    //    allowpermission = true;
    //}

    if (
      this.isAdmin ||
      (isNotNullAndUndefined(rafViewRow.CreatedByUID) &&
        rafViewRow.CreatedByUID === loggedinUserId &&
        IsSystem === false)
    ) {
      allowpermission = true; // true dispay
    }

    if (isNewView === true) {
      allowpermission = true; // true dispay
    }

    let hideColumnChooser = false;

    if (isNotNullAndUndefined(this.props.hideColumnChooser)) {
      if (this.isAdmin) {
        hideColumnChooser = false;
      } else {
        hideColumnChooser = this.props.hideColumnChooser;
      }
    }

    let showGrouping = false;
    if (this.state.viewType === RAFReportType.Group) {
      // showGrouping = true;
    }

    let allPermissionValue = isNotNullAndUndefined(this.props.permissionValue)
      ? this.props.permissionValue
      : null;

    let selectedItemCount = isNotNullAndUndefined(selectedItems)
      ? selectedItems.length
      : 0;

    let initialMobileColumnCount =
      isNotNullAndUndefined(rafViewRow) &&
        isNotNullAndUndefined(rafViewRow.SettingsJson) &&
        isNotNullAndUndefined(rafViewRow.SettingsJson.MobileColumnCount)
        ? rafViewRow.SettingsJson.MobileColumnCount <= selectedItemCount
          ? rafViewRow.SettingsJson.MobileColumnCount
          : selectedItemCount
        : selectedItemCount;

    if (this.props.isCloneView) {
      rafViewRow.DisplayName = rafViewRow.DisplayName + "- Copy";
      rafViewRow.SettingsJson = {
        IsPrivate: rafViewRow.SettingsJson && rafViewRow.SettingsJson.IsPrivate,
        MobileColumnCount: initialMobileColumnCount,
        ShowInList: isNotNullAndUndefined(this.props.isShowInList)
          ? this.props.isShowInList
          : false,
      };
    }

    if (isNotNullAndUndefined(rafViewRow)) {
      return (
        <RAFForm
          className="h-100"
          type={RAFViewRow}
          initialValues={rafViewRow}
          formRef={(g) => {
            this.enableSubmitButton(g);
            return (this.rafForm = g);
          }}
          layout={RAFLayout.TwoColumnLayout}
          onSubmit={(value) => this.OnSubmitFilterClicked(value)}
          decorators={[this.fitlerPanelDecorators]}
        >
          <div
            className={this.props.isRenameView === true ? "" : "dlg-new-style"}
            id={`view_manage_dlg_${this.props.moduleName}`}
          >
            {this.props.isRenameView === false && (
              <div className="e-dlg-headerContent justify-content-between">
                {isNotNullAndUndefined(rafViewRow) &&
                  isNotNullAndUndefined(rafViewRow.UID) ? (
                  <div className="e-dlg-header" id="_title">
                    <span className="e-icons e-table table-view-color me-2"></span>
                    {this.props.isCloneView
                      ? "Clone - " + rafViewRow.DisplayName
                      : "Update - " + rafViewRow.DisplayName}
                  </div>
                ) : (
                  <div className="e-dlg-header" id="_title">
                    <span className="e-icons e-table table-view-color me-2"></span>
                    Add Table View
                  </div>
                )}

                <ButtonComponent
                  type="button"
                  cssClass="primary-custom-button"
                  iconCss="fas fa-xmark"
                  onClick={() => this.CloseFilterDialog()}
                ></ButtonComponent>
              </div>
            )}
            <div
              className={
                this.props.isRenameView === true
                  ? "e-dlg-body-content pe-4 ps-3 mx-0"
                  : "e-dlg-body-content mt-0 h-100 p-0 d-flex flex-column"
              }
            >
              {this.props.isRenameView === false && (
                <Fragment>
                  <TabComponent
                    key={this.state.tabComponentKey}
                    cssClass="e-fill custom-tab-footer-border active-bg-gray"
                    loadOn="Demand"
                    heightAdjustMode="Auto"
                    selectedItem={this.state.selectedTabIndex}
                    animation={tabAnimationSettings}
                    selecting={this.onSelecting.bind(this)}
                    selected={(e) => this.TabSelected(e.selectedIndex)}
                  >
                    <div className="e-tab-header border-bottom">
                      {!hideColumnChooser === true && (
                        <div> Display Columns </div>
                      )}
                      <div> Filters </div>
                      {this.state.viewType === RAFReportType.Group && <div> Grouping</div>}
                    </div>
                    <div className="e-content" style={{ height: "auto" }}></div>
                  </TabComponent>
                  <div
                    className="overflow-auto customScrollBar"
                    style={{ height: "auto" }}
                  >
                    {this.renderTabContent()}
                  </div>
                </Fragment>
              )}
              {this.props.isRenameView === true && (
                <div>
                  <div>
                    <RAFTextBox<RAFViewRow>
                      field="DisplayName"
                      label="Name"
                      showLabel={true}
                      required
                      placeholder="View Name"
                    />
                    <div className="row">
                      {/* {IsSuperAdmin() === true ? (
                          <div className="col-12">
                            <RAFNumber<RAFViewRow>
                              field="SettingsJson.MobileColumnCount"
                              label="MobileColumn Count"
                              //placeholder={'between 1 and ' + selectedItemCount}
                              showLabel={true}
                              initialValue={initialMobileColumnCount}
                              minValue={1}
                              maxValue={selectedItemCount}
                              required
                            />
                          </div>
                        ) : (
                          ""
                        )} */}
                      {hasPermission(
                        allPermissionValue,
                        PermissionConstants.ManageViewPrivate
                      ) && (
                          <div className="col-12">
                            <RAFRadioButtonList
                              field="SettingsJson.IsPrivate"
                              showLabel={false}
                              label="Visiblity"
                              // uitype="userToggle"
                              uitype="customButton"
                              required
                            >
                              <RAFChoiceOption
                                label={RAFViewVisiblity.IsPrivate}
                                value
                              />
                              <RAFChoiceOption
                                label={RAFViewVisiblity.IsPublic}
                                value={false}
                              />
                            </RAFRadioButtonList>
                          </div>
                        )}
                    </div>
                    <div className="row">
                      {isNotNullAndUndefined(this.props.isViewManagement) &&
                        this.props.isViewManagement === true && (
                          <Fragment>
                            <div className="col-6">
                              <RAFCheckBox
                                field="SettingsJson.ShowInList"
                                showLabel={true}
                                uitype="switch"
                                label="Show In List"
                              />
                            </div>
                            <div className="col-6">
                              <RAFCheckBox<RAFViewRow>
                                field="IsSystem"
                                showLabel={true}
                                uitype="switch"
                                label="System View"
                              />
                            </div>
                          </Fragment>
                        )}
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="e-dlg-footerContent justify-content-between d-flex">
              <div>
                {allowpermission &&
                  allowpermission === true &&
                  !isNewView &&
                  this.props.isCloneView !== true &&
                  this.props.isRenameView === false && (
                    <div className="row gx-2">
                      <div className="col-auto">
                        <RAFViewMoreMenuOptions
                          viewID={rafViewRow.UID}
                          buttons={[
                            {
                              action: RAFButtonConstant.Delete,
                              type: "iconBtn",
                            },
                            {
                              action: RAFButtonConstant.Clone,
                              type: "iconBtn",
                            },
                            {
                              action: RAFButtonConstant.AddWorkspace,
                              type: "iconBtn",
                            },
                          ]}
                          moduleName={this.moduleName}
                          onClose={this.props.onClose}
                          onDelete={this.props.onDelete}
                          onSave={this.props.onSave}
                        />
                      </div>
                      {/* <div className="col-auto">
                        <TooltipComponent content={`${RAFButtonConstant.Delete.DisplayName} View`}>
                          <RAFButtonComponent
                            action={RAFButtonConstant.Delete}
                            className="e-danger e-outline"
                            onClick={() => this.DeleteFilterClicked()}
                            iconBtn
                          />
                        </TooltipComponent>
                      </div>
                      <div className="col-auto">
                        <TooltipComponent content={`${RAFButtonConstant.Clone.DisplayName} View`}>
                          <RAFButtonComponent
                            action={RAFButtonConstant.Clone}
                            className="e-outline"
                            onClick={() => this.CloneFilterDialogToggle()}
                            iconBtn
                          />
                        </TooltipComponent>
                      </div>
                      <div className="col-auto">
                        <TooltipComponent content={`${RAFButtonConstant.Add.DisplayName} to Portal`}>
                          <RAFButtonComponent
                            action={RAFButtonConstant.Add}
                            className="e-outline"
                            // onClick={() => this.DeleteFilterClicked()}
                            iconBtn
                          />
                        </TooltipComponent>
                      </div> */}
                    </div>
                    // <DropDownButtonComponent
                    //   items={[
                    //     {
                    //       id: "Delete",
                    //       text: "Delete",
                    //       iconCss: "fas fa-trash",
                    //     },
                    //   ]}
                    //   cssClass="primary-custom-button"
                    //   select={this.onMoreMenuClicked.bind(this)}
                    //   content="More"
                    // >
                    //   <div className="icon-ellipsis-horizontal icon-ellipsis-sm d-none">
                    //     <span className="icon-ellipsis-dot"></span>
                    //   </div>
                    // </DropDownButtonComponent>
                  )}
              </div>
              {/*{!isNewView && (rafViewRow.CreatedByUID === loggedinUserId || this.isAdmin) &&*/}
              {/* {!isNewView &&
                                          <ButtonComponent type="button" onClick={() => this.CloneFilterDialogToggle()} cssClass='e-flat e-default e-outline me-2' >Save As</ButtonComponent>
                                      } */}
              {/* {this.props.isCloneView === true ? (
                    <ButtonComponent
                      type="button"
                      onClick={() => this.CloneFilterDialogToggle()}
                      cssClass="e-flat e-primary primary-btn me-2"
                    >
                      Clone
                    </ButtonComponent>
                  ) : (
                    <Fragment>
                      {allowpermission && allowpermission === true && (
                        <ButtonComponent
                          type="submit"
                          //type="button"
                          //onClick={() => this.onSubmitFormClick()}
                          cssClass="e-flat e-primary primary-btn me-2"
                        >
                          Save
                        </ButtonComponent>
                      )}
                    </Fragment>
                  )} */}
              <div className="row gx-2">
                {this.props.isCloneView === false &&
                  this.props.isRenameView === false
                  ? allowpermission &&
                  allowpermission === true && (
                    <div className="col-auto">
                      <ButtonComponent
                        type="button"
                        onClick={() => this.onSubmitFormClick()}
                        cssClass="e-flat e-primary primary-btn"
                      >
                        Save
                      </ButtonComponent>
                    </div>
                  )
                  : allowpermission &&
                  allowpermission === true && (
                    <div className="col-auto">
                      <ButtonComponent
                        type="button"
                        //onClick={() => this.onSubmitFormClick()}
                        cssClass="e-flat e-primary primary-btn"
                        onClick={() =>
                          this.rafForm && this.rafForm.form.submit()
                        }
                      >
                        Save
                      </ButtonComponent>
                    </div>
                  )}

                {this.props.isRenameView === true && (
                  <div className="col-auto">
                    <ButtonComponent
                      type="button"
                      cssClass="e-flat"
                      onClick={() => this.CloseFilterDialog()}
                    >
                      Cancel
                    </ButtonComponent>
                  </div>
                )}
              </div>
            </div>
          </div>
          {this.state.showSubmitFilterDialog && (
            <DialogComponent
              header={this.state.centerDlgHeaderTitle}
              showCloseIcon
              visible={this.state.showSubmitFilterDialog}
              cssClass="rightDialog createEditForm full-height form-center-dialog viewFilterDialog viewFilterDialog-md dlg-new-style"
              id="saveAsCopyContent_"
              content={this.saveAsCopyContent.bind(this, allowpermission)}
              overlayClick={this.cloneFilterDialogClose.bind(this)}
              isModal
              target="body"
              closeOnEscape={false}
              close={this.cloneFilterDialogClose.bind(this)}
              animationSettings={this.animationSettings}
            ></DialogComponent>
          )}
        </RAFForm>
      );
    } else {
      return <div id={`view_manage_dlg_${this.props.moduleName}`}></div>;
    }
  }
}

export default RAFViewFilterPanel;

import React, { Component } from "react";
import { isNotNullAndUndefined, IsNullOrWhiteSpace } from "../../RAFComponents/helpers/utils";

import acloading from "../../assets/LoaderIconCircle.svg";

class RAFLoadingPanel extends Component<{ loadingText?: string, loadingImage?: string; }> {

    private loadingText = this.props.loadingText;
    private loadingImage = this.props.loadingImage;

    render() {

        let loadingText = this.props.loadingText;

        return (

            <div className="row g-0 wrapper-content loadingPanel">
                <div className="d-flex flex-column align-items-center">
                    <div className="sk-spinner sk-spinner-double-bounce hidden">
                        <div className="sk-double-bounce1"></div>
                        <div className="sk-double-bounce2"></div>
                    </div>
                    {
                        isNotNullAndUndefined(this.loadingImage) ?
                            (<img src={this.loadingImage} width="52" alt="loading" />)
                            :
                            (<img src={acloading} width="52" alt="loading" />)
                    }
                    <span className="loadingText"> {IsNullOrWhiteSpace(this.loadingText) ? "Loading..." : loadingText}</span>
                </div>
            </div>
        );
    }
}

export default RAFLoadingPanel;

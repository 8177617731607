import { PropsWithChildren } from "react";
import { isNotNullAndUndefined } from "../helpers/utils";
interface IProps {
    headerTemplate?: React.ReactNode;
    footerTemplate?: React.ReactNode;
    removeContentPadding?: boolean;
    cardClassName?: string;
    cardId?: string;
    cardHeaderClassName?: string;
    cardContentClassName?: string;
    cardClickable?: boolean;

    onClick?: () => void;

    title?: string;

    enableCardClick?: boolean;
    multiCardClickable?: boolean;
    hideCardContent?: boolean;

    showCardSeparator?: boolean;

    style?: React.CSSProperties;
}
function CustomCardWidget({
    ...props
}: PropsWithChildren<IProps>) {
    const removeContentPadding = props.removeContentPadding === true ? props.removeContentPadding : false
    const cardClassName = `custom__card${isNotNullAndUndefined(props.cardClassName) ? ` ${props.cardClassName}` : ""}${props.cardClickable ? " clickable" : ""}`
    const cardHeaderClassName = `custom__card__header${isNotNullAndUndefined(props.cardHeaderClassName) ? ` ${props.cardHeaderClassName}` : ""}`
    const cardContentClassName = `custom__card__content${removeContentPadding ? ` p-0` : ""}${isNotNullAndUndefined(props.cardContentClassName) ? ` ${props.cardContentClassName}` : ""}`

    const cardSelected = (e) => {
        if (props.enableCardClick === true) {
            if (props.multiCardClickable !== true) {
                let cardDivs = document.getElementsByClassName('custom__card');
                for (let x = 0; x < cardDivs.length; x++) {
                    cardDivs[x].classList.remove('custom__card__active');
                }
            }
            let cardDiv = e.target.closest(".custom__card") as HTMLElement;
            if (isNotNullAndUndefined(cardDiv)) {
                cardDiv.classList.add('custom__card__active')
            }
        }
        if (isNotNullAndUndefined(props.onClick)) {
            props.onClick()
        }
    }

    return (
        <div className={cardClassName}
            {...(props.enableCardClick || isNotNullAndUndefined(props.onClick)) ? { onClick: (e) => cardSelected(e) } : {}}
            id={`${isNotNullAndUndefined(props.cardId) ? props.cardId : ""}`}
            style={isNotNullAndUndefined(props.style) ? props.style : {}}
        >
            {
                isNotNullAndUndefined(props.headerTemplate) ? props.headerTemplate :
                    isNotNullAndUndefined(props.title) ?
                        <div className={cardHeaderClassName}>
                            {props.title}
                        </div>
                        :
                        ""
            }
            {props.showCardSeparator && <div className="custom__card__separator custom__card__separator__light"></div>}
            {props.hideCardContent !== true &&
                <div
                    id={`${isNotNullAndUndefined(props.cardId) ? `${props.cardId}__content` : ""}`}
                    className={cardContentClassName}>
                    {props.children}
                </div>
            }
            {props.footerTemplate ? props.footerTemplate : ""}
        </div>
    )
}
export default CustomCardWidget;

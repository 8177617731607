import { PropsWithChildren } from 'react';
import { RolePermissionsContext } from '../../RAFAuthentication/RAFRolePermissionsContextProvider';
import { RAFPermissionJsonRow, RAFRolePermissionCategoryRow, getAllPermissionsFromPermissionJson } from '../../RAFModules/Common/Role/RoleHelper';
import { ContentType, StorageKey } from "../../constants/Common/Constants";
import { RAFEntityName } from '../../constants/Common/EntityConstants';
import RAFDeletedRecordState from '../Navigation/RAFDeletedRecordState';
import Aux from '../hoc/Auxiliary/Auxiliary';
import { RolePermissionRow } from "../models/Common/RolePermissionRow";
import * as repositoryActions from "../store/actions/repositoryActions";
import { IsSuperAdmin, getSessionStorage } from './AppHelper';
import { IsNullOrWhiteSpace, isNotEmptyArray, isNotNullAndUndefined } from "./utils";

export const getMyPermissions = () => {
    let url = "PermissionGroup/GetMyPermissions";
    return new Promise<RolePermissionRow>((resolve) => {
        return repositoryActions.postDataAndGetResponse(
            url,
            {},
            null,
            ContentType.applicationJson,
        ).then((response) => {
            if (isNotNullAndUndefined(response.data) && isNotNullAndUndefined(response.data.Entity)) {
                let rolePermissionRow: RolePermissionRow = new RolePermissionRow();
                rolePermissionRow = response.data.Entity;
                resolve(rolePermissionRow);
            } else {
                resolve(null);
            }
        });
    });
};

// const IsModulePermissionExist = (moduleName: string) => {
//     let isModulePermissionAdd = true;
//     const allPermissionsStorage = getSessionStorage(StorageKey.all_Permissions, true);
//     const allPermissions: RAFPermissionJsonRow[] = JSON.parse(allPermissionsStorage);
//     if (isNotEmptyArray(allPermissions)) { //check if module permission exist
//         const all_Permissions = allPermissions.map(action => action.Value);
//         const allModulePermission = new Set(all_Permissions.map(action => isNotNullAndUndefined(action) && action.split('::')[0].toLowerCase()));
//         isModulePermissionAdd = allModulePermission.has(moduleName.toLocaleLowerCase());
//     }
//     return isModulePermissionAdd;
// };

const isPermissionExist1 = (permissionName: string) => {
    let isPermissionAdd = true;
    const allPermissionsStorage = getSessionStorage(StorageKey.all_Permissions, true);
    const allPermissions: RAFPermissionJsonRow[] = JSON.parse(allPermissionsStorage);
    if (isNotEmptyArray(allPermissions) && isNotNullAndUndefined(permissionName)) { //check if permission exist in json
        const all_Permissions = allPermissions.map(action => action.Value);
        const allModulePermission = new Set(all_Permissions.map(action => isNotNullAndUndefined(action) && action.toLowerCase()));
        isPermissionAdd = allModulePermission.has(permissionName.toLowerCase());
    }
    return isPermissionAdd;
};

const isPermissionExist = (permissionName: string) => {
    let isPermissionAdd = true;
    const allPermissionsStorage = getSessionStorage(StorageKey.all_Permissions, true);
    const allPermissions: RAFRolePermissionCategoryRow[] = JSON.parse(allPermissionsStorage);
    if (isNotEmptyArray(allPermissions) && isNotNullAndUndefined(permissionName)) { //check if permission exist in json
        const all_Permissions = getAllPermissionsFromPermissionJson(allPermissions);
        const allModulePermission = new Set(all_Permissions.map(action => isNotNullAndUndefined(action) && action.toLowerCase()));
        isPermissionAdd = allModulePermission.has(permissionName.toLowerCase());
    }
    return isPermissionAdd;
};

export const hasPermission = (rolePermissionRow?: RolePermissionRow, permissionName?: string) => {
    if (IsSuperAdmin() === true) {
        return true;
    } else {
        let retVal = false;
        if (isNotNullAndUndefined(rolePermissionRow) && isNotNullAndUndefined(rolePermissionRow.PermissionJson) && !IsNullOrWhiteSpace(permissionName)) {
            const permissionJson = rolePermissionRow.PermissionJson;
            const permissionActions = isNotNullAndUndefined(permissionJson) && isNotNullAndUndefined(permissionJson.Action) ? permissionJson.Action : null;
            if (permissionName.includes(RAFEntityName.FormLibraryStatic)) {
                permissionName = permissionName.replace(/form_library_static/g, RAFEntityName.FormLibrary);
            }
            if (isPermissionExist(permissionName)) {
                const actions = isNotEmptyArray(permissionActions) ? permissionActions.map(x => x.toLowerCase()) : [];
                const permissionNameArray = permissionName.split("::");
                //const moduleName = isNotEmptyArray(permissionNameArray) ? permissionNameArray[0] : null;

                if (isNotEmptyArray(permissionNameArray) && hasDefaultModulePermission(permissionNameArray[0]) === true) {
                    retVal = true;
                } else if (actions.includes(permissionName.toLowerCase()) === true) {
                    //console.log("rolePermissionRow",rolePermissionRow);
                    retVal = true;
                }
            } else {
                retVal = true;
            }
        } else {
            retVal = false;
        }

        return retVal;
    };
};

export const hasDefaultModulePermission = (ModuleName: string) => { //returns true for default modules
    let hasPermission = false;
    if (!IsNullOrWhiteSpace(ModuleName)) {
        const moduleName = ModuleName.toLocaleLowerCase();
        // switch (moduleName) {
        //     case RAFEntityName.Portal:
        //     case RAFEntityName.Role:
        //         hasPermission = true;
        //         break;
        //     default:
        //         hasPermission = false;
        //         break;
        // }
        hasPermission = false;
    }

    return hasPermission;
};

export const hasPermissions = (rolePermissionRow?: RolePermissionRow, permissionNames?: string[]) => {
    if (IsSuperAdmin() === true) {
        return true;
    } else {
        let retVal = false;
        if (isNotNullAndUndefined(rolePermissionRow) && isNotNullAndUndefined(rolePermissionRow.PermissionJson) && isNotEmptyArray(permissionNames)) {
            const permissionJson = rolePermissionRow.PermissionJson;
            const permissionActions = isNotNullAndUndefined(permissionJson) && isNotNullAndUndefined(permissionJson.Action) ? permissionJson.Action : null;

            const actions = isNotEmptyArray(permissionActions) ? permissionActions.map(x => x.toLowerCase()) : [];

            permissionNames.map(item => {
                let permission = isNotNullAndUndefined(item) ? item.toLowerCase() : null;
                if (item.includes(RAFEntityName.FormLibraryStatic)) {
                    permission = item.replace(/form_library_static/g, RAFEntityName.FormLibrary);
                }
                if (isPermissionExist(permission)) {
                    if (actions.includes(permission) === true) {
                        retVal = true;
                    }
                } else {
                    retVal = true;
                }
            });

        } else {
            return false;
        }
        return retVal;
    };
};

export const getDefaultNoPermissionContent = (showDefaultNoPermissionContentShowBackBtn: boolean) => {
    return (
        <div className="container-fluid px-0">
            <RAFDeletedRecordState
                title="You do not have sufficient privileges to view this item."
                showBackBtn={showDefaultNoPermissionContentShowBackBtn ?? false}
            />
        </div>
    );
};

interface IProps {
    permissionName: string;
    showDefaultNoPermissionContent?: boolean;
    showDefaultNoPermissionContentShowBackBtn?: boolean;
    noPermissionContet?: React.ReactNode;
}

function RAFPermissionRender(
    { children, ...props }: PropsWithChildren<IProps>,
) {

    return (
        <Aux>
            <RolePermissionsContext.Consumer>
                {({ permissionValue }) => {
                    let isPermission = hasPermission(permissionValue, props.permissionName);
                    if (isPermission === true) {
                        return <Aux>{children}</Aux>;
                    }
                    else {
                        if (isNotNullAndUndefined(props.noPermissionContet)) {
                            return props.noPermissionContet;
                        } else if (props.showDefaultNoPermissionContent) {
                            return getDefaultNoPermissionContent(props.showDefaultNoPermissionContentShowBackBtn);
                        } else {
                            return <Aux></Aux>;
                        }
                    }
                }
                }
            </RolePermissionsContext.Consumer>
        </Aux>
    );
}

export default RAFPermissionRender;

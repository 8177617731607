import React, { PropsWithChildren } from "react";
import { IsNotNullOrWhiteSpace, isNotNullAndUndefined } from "../../helpers/utils";
import './RAFDetailsValueWithSeparator.scss';
interface IProps {
    children?: React.ReactNode;
    outerClassName?: string;
    rowClassName?: string;
    removeEcllipse?: number[];
}
function RAFDetailsValueWithSeparator({
    ...props
}: PropsWithChildren<IProps>) {
    const outerClassName = `detailsLeftCard-second-title${isNotNullAndUndefined(props.outerClassName) ? ` ${props.outerClassName}` : ""}`
    const rowClassName = `row align-items-center${isNotNullAndUndefined(props.rowClassName) ? ` ${props.rowClassName}` : " g-2"}`
    const objChildren = props.children
        ? React.Children.map(props.children, (child, index) => {
            let unsetEecllipse: boolean = isNotNullAndUndefined(props.removeEcllipse) && props.removeEcllipse.findIndex((x) => x === index) > -1;
            if (isNotNullAndUndefined(child) && IsNotNullOrWhiteSpace(child)) {
                return (
                    <div className={`col-auto detailsLeftCard-second-title-col${unsetEecllipse ? '' : ' ecllipseFirstLine'}`}>
                        <div className="detailsLeftCard-second-title-item">
                            <div className={unsetEecllipse ? '' : ' ecllipseFirstLine'}>
                                {child}
                            </div>
                        </div>
                    </div>
                );
            }
        })
        : null;
    return (
        <div className={outerClassName}>
            <div className={rowClassName}>
                {objChildren}
            </div>
        </div>
    )
}
export default RAFDetailsValueWithSeparator;

import { PresetsModel } from "@syncfusion/ej2-react-calendars";
import moment from "moment";
import { RAFCustomFilter, RAFCustomOperator } from "../RAFViewPanels/RAFFilterColumn/RAFCustomFilter";
import { IsNotNullOrWhiteSpace, isNotNullAndUndefined, setZeroHours } from "./utils";

const today: Date = new Date(new Date().toDateString());
const last7Day = moment(today).subtract(7, "days").toDate();
const last15Days = moment(today).subtract(15, "days").toDate();
const last30Days = moment(today).subtract(30, "days").toDate();

// Get the start and end of this week
const startOfWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay());
const endOfWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() + (6 - today.getDay()));

// Get the start and end of this month
const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);

// Get the start and end of last month
const startOfLastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
const endOfLastMonth = new Date(today.getFullYear(), today.getMonth(), 0);

// Get the start and end of last year
const startOfLastYear = new Date(today.getFullYear() - 1, 0, 1);
const endOfLastYear = new Date(today.getFullYear() - 1, 11, 31);

export const dateRangeFilterType = {
    Recent: "Recent",
    Last7Days: "Last 7 Days",
    Last15Days: "Last 15 Days",
    Last30Days: "Last 30 Days",
    CustomRange: 'Custom'
};

export const dateRangeFilterValue: PresetsModel[] = [
    { label: dateRangeFilterType.Recent, start: null, end: null },
    { label: dateRangeFilterType.Last7Days, start: last7Day, end: today },
    { label: dateRangeFilterType.Last15Days, start: last15Days, end: today },
    { label: dateRangeFilterType.Last30Days, start: last30Days, end: today },
];

export const dateRangeFilterTypeDropdownValue = [
    { text: dateRangeFilterType.Recent, value: dateRangeFilterType.Recent },
    { text: dateRangeFilterType.Last7Days, value: dateRangeFilterType.Last7Days },
    { text: dateRangeFilterType.Last15Days, value: dateRangeFilterType.Last15Days },
    { text: dateRangeFilterType.Last30Days, value: dateRangeFilterType.Last30Days },
    { text: dateRangeFilterType.CustomRange, value: dateRangeFilterType.CustomRange }
];


export const presetDateModel: PresetsModel[] = [
    { label: "This Week", start: startOfWeek, end: endOfWeek },
    { label: "This Month", start: startOfMonth, end: endOfMonth },
    { label: "Last Month", start: startOfLastMonth, end: endOfLastMonth },
    { label: "Last Year", start: startOfLastYear, end: endOfLastYear },
];


//Today Yesterday Last 7days Last 14days Last 30days  
const todayEndDateTime = new Date(new Date().setHours(23, 59, 59, 999));
const yeserdayStartDateTime = moment(today).subtract(1, "days").toDate();
const yeserdayEndDateTime = new Date(new Date(yeserdayStartDateTime).setHours(23, 59, 59, 999));

export const dateRangePresetDateModel: PresetsModel[] = [
    { label: "Today", start: today, end: todayEndDateTime },
    { label: "Yesterday", start: yeserdayStartDateTime, end: yeserdayEndDateTime },
    { label: "Last 7 Days", start: last7Day, end: todayEndDateTime },
    { label: "Last 14 Days", start: last15Days, end: todayEndDateTime },
    { label: "Last 30 Days", start: last30Days, end: todayEndDateTime },
];

export const getCustomDateRangeFilters = (
    realtedDateFilterRow: {
        StartDate: Date;
        EndDate: Date;
    },
    filterFieldProperty: string
): RAFCustomFilter[] => {
    let relatedToFilters: RAFCustomFilter[] = [];
    const filterValues: {
        StartDate: Date;
        EndDate: Date;
    } = { ...realtedDateFilterRow };

    if (isNotNullAndUndefined(filterValues) && IsNotNullOrWhiteSpace(filterFieldProperty)) {
        let eValue1 = filterValues.StartDate
            ? setZeroHours(filterValues.StartDate)
            : null;
        let eValue2 = filterValues.EndDate
            ? setZeroHours(filterValues.EndDate)
            : null;

        if (isNotNullAndUndefined(eValue1) && isNotNullAndUndefined(eValue2)) {
            let evalue1Filter: RAFCustomFilter = {};
            let evalue1FilterVal: string[] = [];
            evalue1FilterVal.push(eValue1 as any); //28/12/2023 post  -- 27/12/2023/18:30:00 somthing greater than equal
            evalue1Filter.Operator = RAFCustomOperator.GreaterThanOrEqual;
            evalue1Filter.Value = evalue1FilterVal;
            evalue1Filter.Field = filterFieldProperty;
            relatedToFilters.push(evalue1Filter);

            let evalue2Filter: RAFCustomFilter = {};
            let evalue2FilterVal: string[] = [];

            let nextDay = moment(eValue2).add(1, "days").toDate();
            evalue2FilterVal.push(nextDay as any);
            evalue2Filter.Operator = RAFCustomOperator.LesserThan;
            evalue2Filter.Value = evalue2FilterVal;
            evalue2Filter.Field = filterFieldProperty;
            relatedToFilters.push(evalue2Filter);
        }
    }

    return relatedToFilters;
};

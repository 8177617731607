import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import { Fragment, PropsWithChildren } from "react";
import { Guid, IsNullOrWhiteSpace, isNotNullAndUndefined } from "../helpers/utils";

export interface tabGroupBtnObject {
    title: string;
    iconClass?: string;
    svgContent?: any;
    id: string;
}

interface IProps {
    items: tabGroupBtnObject[],
    selectedItem?: string;
    onSelect?: (item) => void;
    mode?: 'default' | 'IconOnly' | 'TextOnly' | 'IconAndText' | 'IconAndTextInColumn';
    uiType?: 'ToggleBtn' | 'RadioBtn';
    btnSize?: 'sm' | 'md' | 'lg';
    buttonStyle?: 'primary-custom' | 'secondary' | 'primary' | 'default';
    showTooltip?: boolean;
    field?: any;
}

function RAFTabGroupBtn({
    btnSize = 'md',
    items,
    ...props
}: PropsWithChildren<IProps>) {
    const newGuid = Guid.newGuid();
    const field = props.field;

    const radioButtonContent = (item, index) => {
        return (
            <Fragment key={`${index}_${item.id}`}>

                <input
                    key={`${item.title}_${item.id}`}
                    type="radio"
                    id={`${item.id}_${isNotNullAndUndefined(field) ? field : ''}`}
                    name={`tabGroupBtn_${newGuid}`}
                    value={item.title}
                    {...(isNotNullAndUndefined(props.selectedItem) ? { checked: props.selectedItem === item.id ? true : false } : {})}
                    {...(IsNullOrWhiteSpace(props.selectedItem) ? { defaultChecked: index === 0 ? true : false } : {})}
                    readOnly
                />
                <label
                    className={`e-btn custom-button-${btnSize ? `${btnSize}` : ''}${isNotNullAndUndefined(props.buttonStyle) ? ` ${props.buttonStyle}-button ${(props.selectedItem === item.id || props.selectedItem === item.title) ? ' active' : ' e-outline bg-transparent'}` : ' e-outline'}${props.mode === 'IconAndTextInColumn' ? ' border-0 bg-transparent px-2 custom-button-column' : ''}${props.mode === 'IconOnly' ? ' e-icon-btn d-flex align-items-center' : ''}`}
                    htmlFor={`${item.id}_${isNotNullAndUndefined(field) ? field : ''}`}
                    onClick={() => {
                        if (props.onSelect) {
                            props.onSelect(item);
                        }
                    }}
                >
                    {props.mode === 'IconOnly' ?
                   ( isNotNullAndUndefined(item.svgContent) ? item.svgContent :
                       (isNotNullAndUndefined(item.iconClass) && <i className={`${item.iconClass} e-btn-icon mt-0`}></i>))
                        :
                        props.mode === 'TextOnly' ?
                            <div className="ecllipseFirstLine text-wrap word-break-all">{item.title}</div> :
                            props.mode === 'IconAndTextInColumn' ?
                                <div className="d-flex align-items-center justify-content-center flex-column">
                                   {isNotNullAndUndefined(item.svgContent) ? item.svgContent : isNotNullAndUndefined(item.iconClass)&& <i className={`${item.iconClass} pt-1 e-btn-icon${props.selectedItem !== item.id ? ` text__secondary__grey` : ''}`} style={{ lineHeight: 'normal' }}></i>}
                                    <span style={{ lineHeight: 'normal' }}>{item.title}</span>
                                </div>
                                :
                                <>
                                 {isNotNullAndUndefined(item.svgContent) ? item.svgContent : 
                                 isNotNullAndUndefined(item.iconClass)&&
                                    <i className={`${item.iconClass} pe-1 e-btn-icon`}></i>
                                 }
                                    <span className={props.mode === 'IconAndText' ? "ps-1" : "d-md-inline-block d-none ps-1"}>{item.title}</span>
                                </>
                    }
                </label>
            </Fragment>
        );
    };

    return (
        props.uiType === 'RadioBtn' ?
            <div id="text" className="row gx-3">
                {items.map((item, index) => {
                    return (
                        <div className='col-auto'
                            key={`${index}_${item.id}_${props.selectedItem}`}
                        >
                            <div className={`e-radio-wrapper e-wrapper radio_button_${isNotNullAndUndefined(props.buttonStyle) ? props.buttonStyle : ''}`} key={`${index}_${item.id}`}>

                                <input
                                    key={`${item.title}_${item.id}`}
                                    type="radio"
                                    id={`${item.id}_${isNotNullAndUndefined(field) ? field : ''}`}
                                    name={`tabGroupBtn_${newGuid}`}
                                    value={item.title}
                                    className="e-control e-radio e-lib"
                                    {...(isNotNullAndUndefined(props.selectedItem) ? { checked: props.selectedItem === item.id ? true : false } : {})}
                                    {...(IsNullOrWhiteSpace(props.selectedItem) ? { defaultChecked: index === 0 ? true : false } : {})}
                                    readOnly
                                />
                                <label

                                    htmlFor={`${item.id}_${isNotNullAndUndefined(field) ? field : ''}`}
                                    onClick={() => {
                                        if (props.onSelect) {
                                            props.onSelect(item);
                                        }
                                    }}
                                >
                                    <span className="e-label">
                                        {props.mode === 'IconOnly' ?
                                           isNotNullAndUndefined(item.svgContent) ? item.svgContent : <i className={`${item.iconClass} e-btn-icon`}></i>
                                            :
                                            props.mode === 'TextOnly' ?
                                                <span>{item.title}</span>
                                                :
                                                <>
                                                     {isNotNullAndUndefined(item.svgContent) ? item.svgContent : 
                                                     isNotNullAndUndefined(item.iconClass)&&
                                                     <i className={`${item.iconClass} pe-1 e-btn-icon`}></i>
                }
                                                    <span className={props.mode === 'IconAndText' ? "ps-1" : "d-md-inline-block d-none"}>{item.title}</span></>
                                        }
                                    </span>
                                </label>
                            </div>
                        </div>
                    );
                }
                )
                }

            </div>
            :

            <div id="text" className={`e-btn-group custom-group-btn${isNotNullAndUndefined(props.buttonStyle) ? ` ${props.buttonStyle}` : ''}`}>
                {items.map((item, index) => {
                    let showTooltip = isNotNullAndUndefined(props.showTooltip) && props.showTooltip ?
                        props.showTooltip :
                        (props.mode === 'IconOnly' ? true : false);

                    if (showTooltip) {
                        return (
                            <TooltipComponent content={item.title} key={index}>
                                {radioButtonContent(item, index)}
                            </TooltipComponent>
                        );
                    }
                    return (
                        radioButtonContent(item, index)
                    );
                }
                )
                }

            </div>
    );
}

export default RAFTabGroupBtn;
import React from "react";
import { isNotNullAndUndefined, IsNotNullOrWhiteSpace, IsNullOrWhiteSpace } from "../helpers/utils";
import nodata_es from "../../assets/ES/nodata_es.svg";

export interface RAFEmptyStateProps {
    image?: string;
    iconClass?: string;
    svgContent?: any;
    title?: string;
    body?: string;
    emptyStateContainerClass?: string;
    // displayStyle?: 'imageWithTitleBody' | 'withOutImage';
    displayStyle?: 'ImageOnly' | 'TextOnly' | 'Default';
    imageStyle?: 'WithOuterCircle' | 'RafSmall';
}

const RAFEmptyState: React.FC<RAFEmptyStateProps> = props => {
    let showImage: boolean = true
    let showTitle: boolean = true
    let showBody: boolean = true
    switch (props.displayStyle) {
        case ('ImageOnly'):
            showTitle = false;
            showBody = false;
            break;
        case ('TextOnly'):
            showImage = false
            break;
        default:
            showImage = true;
            showTitle = true;
            showBody = true;
            break;

    }
    const image = isNotNullAndUndefined(props.image) ? props.image : nodata_es;
    const title = showTitle ? (isNotNullAndUndefined(props.title) ? props.title : 'No data available at the moment!') : '';
    const iconClass = isNotNullAndUndefined(props.iconClass) ? props.iconClass : 'fas fa-memo';
    return (
        <div className={isNotNullAndUndefined(props.emptyStateContainerClass) ? "empty-state-container " + props.emptyStateContainerClass : "empty-state-container"}>
            <div className="row gy-3 gx-0 flex-column align-items-center justify-content-center">
                {(image || iconClass) && showImage && <div className="col-auto">
                    <div className={isNotNullAndUndefined(iconClass) ? "empty-state-icon" : (isNotNullAndUndefined(image) ? (isNotNullAndUndefined(props.imageStyle) ? `empty-state-image ${props.imageStyle}` : "empty-state-image") : "empty-state-icon")}>
                        {isNotNullAndUndefined(props.svgContent) ? props.svgContent : (iconClass ? <span className={iconClass ? `${iconClass}` : null}></span>
                            :
                            <img src={image} alt="Empty State Icon" className="" />)
                        }
                    </div>
                </div>
                }
                <div className="col-auto">
                    <div className="row gx-0 gy-1 flex-column align-items-center justify-content-center">
                        {IsNotNullOrWhiteSpace(title) && showTitle && <div className="col-auto text-center"><div className="empty-state-title">
                            <span>{title}</span>
                        </div>
                        </div>
                        }
                        {isNotNullAndUndefined(props.body) && showBody && !IsNullOrWhiteSpace(props.body) && props.body !== "" && <div className="col-auto text-center"><div className="empty-state-content">
                            <span>{props.body}</span>
                        </div>
                        </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};


export default RAFEmptyState;
import React, { createContext, ReactNode } from "react";
import { IconList, IconPickerItem } from "react-fa-icon-picker";
import { RAFLayout } from "../../constants/Common/Constants";
import {
  isEmptyOrNull,
  isNotNullAndUndefined
} from "../helpers/utils";
import { RAFLayoutContext } from "../Inputs/RAFForm";
import "./NavigationStyle.scss";

interface IProps {
  children: React.ReactNode;
  title?: string;
  IsCollapsed?: boolean;
  leftArrow?: boolean;
  arrowPosistion?: "left" | "title" | "right";
  Collapsible?: boolean;
  removeContentCardPadding?: boolean;
  contentRowClassName?: string;
  contentCardClassName?: string;
  layout?: RAFLayout;
  headerRowClassName?: string;
  isCustomHeader?: boolean;
  iconName?: string;
  iconNameOuterClass?: string;
  customButton?: React.ReactFragment | React.ReactNode;
  customCheckbox?: React.ReactFragment | ReactNode;
  customLeftButton?: React.ReactFragment | ReactNode;
  customFooterContent?: React.ReactFragment | ReactNode;
  isCustomStye?: boolean;
  onExpandedSection?: (collapsed?: boolean) => void;
  headerBadge?: React.ReactFragment | ReactNode;
  loadChildrenOnExpand?: boolean;
  iconPickerValue?: IconList;
  customTitle?: React.ReactFragment | React.ReactNode;
  customTitleOnExpand?: React.ReactFragment | React.ReactNode;
  showSectionHeader?: boolean;
  outterSection?: React.ReactFragment | ReactNode;
  fullRowClick?: boolean;
  sectionContentClass?: string;
  colClassName?: string;
  showCardSeparator?: boolean;
  cardSeparatorUI?: "dark" | "light";
  showCustomButtonOnHover?: boolean;
  hideFooterOnCollapse?: boolean;
}

export class RAFCollapse extends React.Component<IProps> {
  state = {
    collapsed:
      this.props.IsCollapsed !== null && this.props.IsCollapsed !== undefined
        ? this.props.IsCollapsed
        : false,
  };

  componentDidUpdate(prevProps /*, prevState*/) {
    if (prevProps.IsCollapsed !== this.props.IsCollapsed) {
      this.setState({
        collapsed: this.props.IsCollapsed !== null && this.props.IsCollapsed !== undefined
          ? this.props.IsCollapsed
          : false
      });
    }
  }

  toggleCollapse = (collapsed) => {
    this.setState({ collapsed: !collapsed });

    if (isNotNullAndUndefined(this.props.onExpandedSection)) {
      //if (collapsed === true) {
      this.props.onExpandedSection(collapsed);
      //}
    }
  };

  render() {
    let titleName =
      isNotNullAndUndefined(this.props.title) &&
      this.props.title.replace(" ", "");
    let collapsible =
      this.props.Collapsible !== null && this.props.Collapsible !== undefined
        ? this.props.Collapsible
        : true;
    let isCustomStye =
      this.props.isCustomStye !== null && this.props.isCustomStye !== undefined
        ? this.props.isCustomStye
        : false;
    let title = isNotNullAndUndefined(this.props.title) && this.props.title;
    let contentRowClassName =
      this.props.contentRowClassName !== null &&
        this.props.contentRowClassName !== undefined
        ? this.props.contentRowClassName
        : "row mx-0";
    let contentCardClassName =
      this.props.contentCardClassName !== null &&
        this.props.contentCardClassName !== undefined
        ? this.props.removeContentCardPadding
          ? this.props.contentCardClassName + " position-relative border-0"
          : this.props.contentCardClassName +
          " position-relative border-0 py-3 px-3"
        : this.props.removeContentCardPadding
          ? " position-relative border-0"
          : " position-relative border-0 py-3 px-3";
    let headerRowClassName =
      this.props.headerRowClassName !== null &&
        this.props.headerRowClassName !== undefined
        ? "row g-0 sectionHeader " + this.props.headerRowClassName
        : "row g-0 sectionHeader";
    let customHeader =
      this.props.isCustomHeader !== null &&
        this.props.isCustomHeader !== undefined &&
        this.props.isCustomHeader
        ? " col d-flex align-items-center flex-wrap"
        : "col flex-wrap justify-content-between d-flex align-items-center";
    let customHeaderTitle =
      this.props.isCustomHeader !== null &&
        this.props.isCustomHeader !== undefined &&
        this.props.isCustomHeader
        ? "d-flex flex-column"
        : "d-flex align-items-center w-100";
    let showSectionHeader =
      this.props.showSectionHeader !== null &&
        this.props.showSectionHeader !== undefined
        ? this.props.showSectionHeader
        : true;

    const iconName = this.props.iconName;
    const iconPickerValue = this.props.iconPickerValue;
    const iconNameOuterClass = this.props.iconNameOuterClass;
    const leftArrow = this.props.leftArrow;
    const headerBadge = this.props.headerBadge;
    const arrowPosistion = this.props.arrowPosistion;
    let fullRowClick = isNotNullAndUndefined(this.props.fullRowClick)
      ? this.props.fullRowClick
      : true;

    const rafLayoutContextValue = createContext(RAFLayoutContext);
    let propsLayout =
      this.props.layout !== null && this.props.layout !== undefined
        ? this.props.layout
        : rafLayoutContextValue !== null && rafLayoutContextValue !== undefined
          ? rafLayoutContextValue
          : RAFLayout.OneColumnLayout;
    let objChildren = this.props.children
      ? React.Children.map(this.props.children, (child) => {
        return (
          <div
            className={`${this.props.colClassName ? this.props.colClassName : ""} 
            ${propsLayout === RAFLayout.FourColumnLayout
                ? "col-md-3"
                : propsLayout === RAFLayout.ThreeColumnLayout
                  ? "col-md-4"
                  : propsLayout === RAFLayout.TwoColumnLayout
                    ? "col-md-6"
                    : "col-md-12 px-0"}`
            }
          >
            <div className="w-100 d-flex flex-column h-100">{child}</div>
          </div>
        );
      })
      : null;

    let leftMenu = isNotNullAndUndefined(this.props.customLeftButton) ? (
      <div className="">{this.props.customLeftButton}</div>
    ) : null;

    let rightMenu = isNotNullAndUndefined(this.props.customButton) ? (
      <div className={this.props.showCustomButtonOnHover ? "hover-child-div" : ""}>{this.props.customButton}</div>
    ) : null;
    let customTitle = isNotNullAndUndefined(this.props.customTitle)
      ? this.props.customTitle
      : null;
    let customTitleOnExpand = isNotNullAndUndefined(this.props.customTitleOnExpand)
      ? this.props.customTitleOnExpand
      : null;

    let customCheckboxMenu = isNotNullAndUndefined(
      this.props.customCheckbox
    ) ? (
      <div className="">{this.props.customCheckbox}</div>
    ) : null;
    let collapsed = this.state.collapsed;
    if (
      this.props.loadChildrenOnExpand &&
      this.props.loadChildrenOnExpand === true
    ) {
      if (collapsed) {
        objChildren = null;
      }
    }

    return (
      <RAFLayoutContext.Provider value={this.props.layout}>
        <div className={this.props.showCustomButtonOnHover === true ? "hover-parent-div" : "section__container"}>
          {title !== null && title !== undefined && showSectionHeader === true ? (
            <div
              key={"RAFFormSectionHeader" + titleName}
              className={headerRowClassName}
            >
              <div className={customHeader}>
                {isCustomStye ? (
                  <div
                    className="w-100"
                    onClick={() => {
                      if (collapsible)
                        this.toggleCollapse(collapsed);
                    }}
                  >
                    <div className="centerButtonDiv">
                      <div className="borderDiv"></div>
                      <div className="centerButton">
                        <i
                          className={
                            collapsed === true
                              ? "fa fa-caret-down rotate-down me-2"
                              : "fa fa-caret-down me-2"
                          }
                        ></i>
                        <span>Completed Tasks</span>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="d-flex w-100">
                    {isNotNullAndUndefined(customCheckboxMenu) && (
                      <div className="pe-2">{customCheckboxMenu}</div>
                    )}
                    <div className="w-100">
                      <div
                        className="row g-0 g-md-2 align-items-center justify-content-between customHeader"
                        // {...(fullRowClick
                        //   ? {
                        //     onclick: collapsible
                        //       ? this.toggleCollapse(collapsed)
                        //       : false,
                        //   }
                        //   : {})}

                        onClick={() => {
                          if (fullRowClick && collapsible) {
                            this.toggleCollapse(collapsed);
                          }
                        }}
                      >
                        <div className="col">
                          <div className={customHeaderTitle}>
                            {collapsible && leftArrow === true ? (
                              <i
                                className={
                                  collapsed === true
                                    ? "fa fa-caret-down rotate"
                                    : "fa fa-caret-down"
                                }
                              ></i>
                            ) : (
                              ""
                            )}
                            {isEmptyOrNull(iconPickerValue)
                              ? isNotNullAndUndefined(iconName) && (
                                <div
                                  className={
                                    isNotNullAndUndefined(iconNameOuterClass)
                                      ? iconNameOuterClass +
                                      " me-2 position-relative"
                                      : " me-2 position-relative"
                                  }
                                >
                                  <i
                                    className={
                                      iconName !== null ? "fa fa-" + iconName : ""
                                    }
                                  ></i>
                                </div>
                              )
                              : isNotNullAndUndefined(iconPickerValue) && (
                                <div
                                  className={
                                    isNotNullAndUndefined(iconNameOuterClass)
                                      ? iconNameOuterClass +
                                      " me-2 position-relative"
                                      : " me-2 position-relative"
                                  }
                                >
                                  <IconPickerItem
                                    icon={iconPickerValue}
                                    size={18}
                                    color="#000"
                                  />
                                </div>
                              )}
                            {isNotNullAndUndefined(customTitleOnExpand) && collapsed === false ? (customTitleOnExpand) :
                              isNotNullAndUndefined(customTitle) ? (
                                customTitle
                              ) : (
                                <span className="m-0 ecllipseSecondLine">{this.props.title}</span>
                              )}
                            {headerBadge}
                            {collapsible &&
                              isNotNullAndUndefined(arrowPosistion) &&
                              arrowPosistion === "title" ? (
                              <i
                                className={
                                  collapsed === true
                                    ? "fas fa-chevron-right ms-2 mt-1 small-icon"
                                    : "fas fa-chevron-down rotate-down ms-2 small-icon"
                                }
                                onClick={() => this.toggleCollapse(collapsed)}
                              ></i>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <div className="ms-auto col-auto">
                          <div
                            id={this.props.title + "headerRightDiv"}
                            // className={
                            //   collapsible === false ||
                            //     (isNotNullAndUndefined(arrowPosistion) &&
                            //       arrowPosistion === "title")
                            //     ? "header-right-div header-toRight-div d-flex align-items-center"
                            //     : "header-right-div d-flex align-items-center me-2"
                            // }
                            className="header-right-div d-flex align-items-center"
                          >
                            <div className="row gx-2 gx-md-3">
                              {isNotNullAndUndefined(rightMenu) ?
                                <div className="col-auto">
                                  <div
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      e.preventDefault();
                                    }}
                                  >
                                    {rightMenu}
                                  </div>
                                </div>
                                :
                                ""
                              }{collapsible &&
                                !isNotNullAndUndefined(arrowPosistion) &&
                                !isNotNullAndUndefined(leftArrow) ? (
                                <div className="col-auto d-flex align-items-center">
                                  <i
                                    className={
                                      collapsed === true
                                        ? "fas fa-chevron-down small-icon"
                                        : "fas fa-chevron-down rotate-down small-icon"
                                    }
                                    onClick={() => this.toggleCollapse(collapsed)}
                                  ></i>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="header-center-div">{leftMenu}</div>
                  </div>
                )}
              </div>

            </div>
          ) : null}
          {this.props.showCardSeparator ? <div className={`${this.props.sectionContentClass} ${collapsed ? "" : `${this.props.cardSeparatorUI === 'light' ? "custom__card__separator__light " : ""}custom__card__separator`}`}></div> : ""}
          <div
            key={"RAFFormSectionContent" + titleName}
            id={"RAFFormSectionContent" + titleName}
            className={`${this.props.sectionContentClass} ${collapsed ? "collapse" : "collapse show"} section__div section__divY customScrollBar`}
          >
            <div className={contentCardClassName}>
              <div className={contentRowClassName}>
                {isNotNullAndUndefined(this.props.outterSection)
                  ? this.props.outterSection
                  : ""}
                {objChildren}
              </div>
            </div>
          </div>
          {isNotNullAndUndefined(this.props.customFooterContent) ?
            (this.props.hideFooterOnCollapse === true && collapsed === true ? "" :
              this.props.customFooterContent)
            : null}
        </div>
      </RAFLayoutContext.Provider>
    );
  }
}
export default RAFCollapse;
/*
const RAFCollapse = (props: { children: React.ReactNode; title?: string, IsCollapsed?: boolean, leftArrow?: boolean, Collapsible?: boolean, contentRowClassName?: string, contentCardClassName?: string, layout?: RAFLayout, headerRowClassName?: string, isCustomHeader?: boolean, iconName?: string, iconNameOuterClass?: string, customButton?: React.ReactFragment, customLeftButton?: React.ReactFragment, isCustomStye?: boolean, expandedSection?: void }) => {

    let titleName = isNotNullAndUndefined(props.title) && props.title.replace(" ", "");
    const [collpased, setCollpased] = useState((props.IsCollapsed !== null && props.IsCollapsed !== undefined) ? props.IsCollapsed : false);
    const collapsible = (props.Collapsible !== null && props.Collapsible !== undefined) ? props.Collapsible : true;
    const isCustomStye = (props.isCustomStye !== null && props.isCustomStye !== undefined) ? props.isCustomStye : false;
    const title = isNotNullAndUndefined(props.title) && props.title;
    const contentRowClassName = (props.contentRowClassName !== null && props.contentRowClassName !== undefined) ? props.contentRowClassName : "row mx-0";
    const contentCardClassName = (props.contentCardClassName !== null && props.contentCardClassName !== undefined) ? props.contentCardClassName + " e-card border-0 py-3 px-3" : " e-card border-0 py-3 px-3";
    const headerRowClassName = (props.headerRowClassName !== null && props.headerRowClassName !== undefined) ? "row g-0 sectionHeader " + props.headerRowClassName : "row g-0 sectionHeader"
    const customHeader = (props.isCustomHeader !== null && props.isCustomHeader !== undefined && props.isCustomHeader) ? " col d-flex align-items-center flex-wrap" : "col flex-wrap justify-content-between d-flex align-items-center"
    const customHeaderTitle = (props.isCustomHeader !== null && props.isCustomHeader !== undefined && props.isCustomHeader) ? "d-flex flex-column" : "d-flex align-items-center"
    const toggleCollapse = () => {
        setCollpased(!collpased);
        //collpased ? props.expandedSection : null;
        if (isNotNullAndUndefined(props.expandedSection))
        {
            props.expandedSection
        }
    }
    const iconName = props.iconName;
    const iconNameOuterClass = props.iconNameOuterClass;
    const leftArrow = props.leftArrow;

    const rafLayoutContextValue = useContext(RAFLayoutContext);
    let propsLayout = (props.layout !== null && props.layout !== undefined) ? props.layout : ((rafLayoutContextValue !== null && rafLayoutContextValue !== undefined) ? rafLayoutContextValue : RAFLayout.OneColumnLayout);
    let objChildren = props.children ? (
        React.Children.map(props.children, (child, i) => {
            return (
                <div className={(propsLayout === RAFLayout.FourColumnLayout) ? "col-md-3" : (propsLayout === RAFLayout.ThreeColumnLayout) ? "col-md-4" : (propsLayout === RAFLayout.TwoColumnLayout) ? "col-md-6" : "col-md-12 px-0"} >
                    <div className="w-100 d-flex flex-column">
                        {child}
                    </div>
                </div>
            )
        })
    ) : null

    let leftMenu = (isNotNullAndUndefined(props.customLeftButton) ?
        <div className="">
            {props.customLeftButton}
        </div>
        : null);

    let rightMenu = (isNotNullAndUndefined(props.customButton) ?
        <div className="">
            {props.customButton}
        </div>
        : null);

    return (
        <RAFLayoutContext.Provider value={propsLayout}>
            {(title !== null && title !== undefined) ? <div key={"RAFFormSectionHeader" + titleName} className={headerRowClassName}
            >
                <div className={customHeader}>
                    {isCustomStye ? <div className="w-100" onClick={() => collapsible ? toggleCollapse() : false}>
                        <div className="centerButtonDiv">
                            <div className="borderDiv"></div>
                            <div className="centerButton">
                                <i className={collpased === true ? 'fa fa-caret-down rotate me-2' : 'fa fa-caret-down me-2'}></i><span>Completed Tasks</span>
                            </div>
                        </div>
                    </div> :
                        <div className="d-flex align-items-center w-100 justify-content-between customHeader" onClick={() => collapsible ? toggleCollapse() : false}>
                            <div className={customHeaderTitle}>
                                {(leftArrow === true) ? <i className={collpased === true ? 'fa fa-caret-down rotate' : 'fa fa-caret-down'}></i> : ""}
                                <div className={isNotNullAndUndefined(iconNameOuterClass) ? iconNameOuterClass + " me-2 " :" me-2 "}>
                                    <i className={iconName !== null ? "fa fa-" + iconName : ""}></i>
                                    </div>
                                <span className="m-0 me-2" >{props.title}</span>
                            </div>
                            {!isNotNullAndUndefined(leftArrow) ? <i className={collpased === true ? 'down-arrow rotate ms-2' : 'down-arrow ms-2'}></i> : "" }
                           
                        </div>
                    }
                   <div className="header-center-div">
                        {leftMenu}
                        </div>
                    <div className="header-right-div">
                        {rightMenu}
                    </div>
                </div>
            </div> : null}
            <div key={"RAFFormSectionContent" + titleName} id={"RAFFormSectionContent" + titleName} className={collpased ? 'collapse' :'collapse show' }>
                <div className={contentCardClassName}>
                    <div className={contentRowClassName}>
                        {objChildren}
                    </div>
                </div>
            </div>
           
        </RAFLayoutContext.Provider>
    )

}
export default RAFCollapse;
*/

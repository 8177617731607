import { ContentType } from "../../constants/Common/Constants";
import {
  RAFCustomFilter,
  RAFCustomOperator,
} from "../RAFViewPanels/RAFFilterColumn/RAFCustomFilter";
import { ListServiceRequest } from "../models/Common/ListRequest";
import { LookUpRow } from "../models/CompositeTypes/LookUpRow";
import * as repositoryActions from "../store/actions/repositoryActions";
import {
  IsNotNullOrWhiteSpace,
  isNotEmptyArray,
  isNotNullAndUndefined,
  propertyOf,
} from "./utils";

export const getSearchedLookUpItems = (
  url?: string,
  skip?: number,
  take?: number,
  searchedText?: string,
  moduleName?: string,
  customFilter?: RAFCustomFilter,
  dataSource?: LookUpRow[],
  prevSearchedText?: string,
  additionalParams?: { key: string; value?: string | string[] }[]
) => {
  return new Promise<{
    reachedMaxLimit: boolean;
    lookUpDataItems: LookUpRow[];
  }>(async (resolve) => {
    if (
      IsNotNullOrWhiteSpace(prevSearchedText) &&
      IsNotNullOrWhiteSpace(searchedText)
    ) {
      //check is searchedText contains the prevSearchedText
      if (searchedText.toLowerCase().includes(prevSearchedText.toLowerCase())) {
        if (isNotEmptyArray(dataSource)) {
          if (dataSource.length < take) {
            const data = dataSource.filter((x) =>
              x.Value.toLowerCase().includes(searchedText.toLowerCase())
            );
            const reachedMaxLimit = dataSource.length < take ? true : false;
            resolve({
              reachedMaxLimit: reachedMaxLimit,
              lookUpDataItems: data,
            });
          } else {
            const data = await getLookUpItems(
              url,
              skip,
              take,
              searchedText,
              moduleName,
              customFilter,
              additionalParams
            );
            const reachedMaxLimit = data.length < take ? true : false;
            resolve({
              reachedMaxLimit: reachedMaxLimit,
              lookUpDataItems: data,
            });
          }
        } else {
          resolve({ reachedMaxLimit: true, lookUpDataItems: [] });
        }
      } else {
        const data = await getLookUpItems(
          url,
          skip,
          take,
          searchedText,
          moduleName,
          customFilter,
          additionalParams
        );
        const reachedMaxLimit = data.length < take ? true : false;
        resolve({ reachedMaxLimit: reachedMaxLimit, lookUpDataItems: data });
      }
    } else {
      if (
        IsNotNullOrWhiteSpace(searchedText) &&
        isNotEmptyArray(dataSource) &&
        dataSource.length < take
      ) {
        //this condition is for only filtering the data from the dataSource for first time filter
        const data = dataSource.filter((x) =>
          x.Value.toLowerCase().includes(searchedText.toLowerCase())
        );
        const reachedMaxLimit = data.length < take ? true : false;
        resolve({ reachedMaxLimit: reachedMaxLimit, lookUpDataItems: data });
      } else {
        const data = await getLookUpItems(
          url,
          skip,
          take,
          searchedText,
          moduleName,
          customFilter,
          additionalParams
        );
        const reachedMaxLimit = data.length < take ? true : false;
        resolve({ reachedMaxLimit: reachedMaxLimit, lookUpDataItems: data });
      }
    }
  });
};

export const getLookUpItems = (
  url?: string,
  skip?: number,
  take?: number,
  searchedText?: string,
  moduleName?: string,
  customFilter?: RAFCustomFilter,
  additionalParams?: { key: string; value?: string | string[] }[]
) => {
  return new Promise<LookUpRow[]>(async (resolve) => {
    if (isNotNullAndUndefined(url)) {
      let listServiceRequest = new ListServiceRequest();
      listServiceRequest.EntityName = moduleName;
      listServiceRequest.Skip = skip;
      listServiceRequest.Take = take;
      if (IsNotNullOrWhiteSpace(customFilter)) {
        listServiceRequest.CustomFilter = customFilter;
      }

      if (IsNotNullOrWhiteSpace(searchedText)) {
        listServiceRequest.ContainsText = searchedText;
      }

      if (
        isNotNullAndUndefined(additionalParams) &&
        additionalParams.length > 0
      ) {
        additionalParams.forEach((objProp) => {
          listServiceRequest[objProp.key] = objProp.value;
        });
      }

      const response = await repositoryActions.postDataAndGetResponse(
        url,
        listServiceRequest,
        null,
        ContentType.applicationJson,
        false
      );

      if (
        isNotNullAndUndefined(response) &&
        isNotNullAndUndefined(response.data)
      ) {
        const data: LookUpRow[] = isNotEmptyArray(response.data.Entities)
          ? [...response.data.Entities]
          : [];

        resolve(data);
      } else {
        resolve([]);
      }
    } else {
      resolve([]);
    }
  });
};

export const getLookUpObjectByUID = (
  url: string,
  objectUID: string,
  moduleName: string
) => {
  return new Promise<LookUpRow>(async (resolve, reject) => {
    if (isNotNullAndUndefined(objectUID) && isNotNullAndUndefined(url)) {
      let listServiceRequest = new ListServiceRequest();
      listServiceRequest.EntityName = moduleName;

      let relatedFilter: RAFCustomFilter = {};
      relatedFilter.Condition = "and";
      relatedFilter.Rules = [];

      let filter1: RAFCustomFilter = {};
      let filterVal1: string[] = [];
      filterVal1.push(objectUID);
      filter1.Operator = RAFCustomOperator.Equal;
      filter1.Value = filterVal1;
      filter1.Field = propertyOf<LookUpRow>("UID");
      relatedFilter.Rules.push(filter1);

      listServiceRequest.CustomFilter = relatedFilter;

      const response = await repositoryActions.postDataAndGetResponse(
        url,
        listServiceRequest,
        null,
        ContentType.applicationJson,
        false
      );

      if (
        isNotNullAndUndefined(response) &&
        isNotNullAndUndefined(response.data) &&
        isNotEmptyArray(response.data.Entities)
      ) {
        const responseList: LookUpRow[] = response.data.Entities;
        const data: LookUpRow = isNotEmptyArray(responseList)
          ? responseList.find((x) => x.UID === objectUID)
          : null;
        resolve(data);
      } else {
        resolve(null);
      }
    } else {
      resolve(null);
    }
  });
};

export const getUniqueFilteredLookUpDropdownItems = (
  responseData: LookUpRow[]
) => {
  if (isNotEmptyArray(responseData)) {
    const filteredData = responseData.filter(
      (item) =>
        IsNotNullOrWhiteSpace(item.Value) && IsNotNullOrWhiteSpace(item.UID)
    );

    const uniqueData = filteredData.reduce((unique, item) => {
      return unique.findIndex((uniqueItem) => uniqueItem.UID === item.UID) < 0
        ? [...unique, item]
        : unique;
    }, []);

    return uniqueData;
  } else {
    return [];
  }
};

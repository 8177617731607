import { RRule, Weekday } from "rrule";
import { IsNotNullOrWhiteSpace } from "./utils";

export enum FrequencyValueType {
    Daily = "Daily",
    Weekly = "Weekly",
    Monthly = "Monthly",
    Yearly = "Yearly",
    Occasional = "Occasional",
    AsNeeded = "As Needed",
    OnDemand = "On Demand",
}


export const RRDateTypes = [
    { id: 0, text: "Never", value: "Never" },
    { id: 1, text: "Until", value: "Until" },
];

export const RRFrequencyTypes = [
    { id: 0, text: "Daily", value: RRule.DAILY },
    { id: 1, text: "Weekly", value: RRule.WEEKLY },
];

export const RRTimeOptions = [
    { id: 0, text: "AnyTime", value: "ANYTime" },
    { id: 1, text: "Sessions", value: "SESSIONS" },
];

export const RRDays = [
    { id: 0, text: "Sun", value: RRule.SU },
    { id: 1, text: "Mon", value: RRule.MO },
    { id: 2, text: "Tue", value: RRule.TU },
    { id: 3, text: "Wed", value: RRule.WE },
    { id: 4, text: "Thu", value: RRule.TH },
    { id: 5, text: "Fri", value: RRule.FR },
    { id: 6, text: "Sat", value: RRule.SA },
];


export const getRuleByWeekday = (rruleExpression: string) => {
    if (IsNotNullOrWhiteSpace(rruleExpression)) {
        const options = getFrequencyOrigOptions(rruleExpression);
        if (IsNotNullOrWhiteSpace(options)) {
            const { until, freq, byweekday } = options;
            return byweekday as Weekday[];
        }
    }

    return null;
};

export const getRuleFrequency = (rruleExpression: string) => {
    if (IsNotNullOrWhiteSpace(rruleExpression)) {
        const options = getFrequencyOrigOptions(rruleExpression);
        if (IsNotNullOrWhiteSpace(options)) {
            const { until, freq, byweekday } = options;
            return freq;
        }
    }

    return null;
};

export const getFrequencyOrigOptions = (rruleExpression: string) => {
    if (IsNotNullOrWhiteSpace(rruleExpression)) {
        const cleanedExpression = rruleExpression.endsWith(";") ? rruleExpression.slice(0, -1) : rruleExpression;
        const rule = RRule.fromString(cleanedExpression);
        return rule.origOptions;
    }

    return null;
};
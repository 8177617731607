import React, { createContext } from "react";

export const RAFDataResultContext = createContext<{
  data?: any;
  count?: number;
  updateData?: (data, count) => void;
}>({ data: null, count: null, updateData: null });

export default function RAFDataResultProvider({ children }) {
  const [dataResult, setDataResult] = React.useState({
    data: null,
    count: null,
  });

  const updateData = (data, count) => {
    setDataResult({ data: data, count: count });
  };

  return (
    <RAFDataResultContext.Provider
      value={{
        data: dataResult.data,
        count: dataResult.count,
        updateData: updateData,
      }}
    >
      {children}
    </RAFDataResultContext.Provider>
  );
}

import { PropsWithChildren } from 'react';
import { RolePermissionsContext } from '../../RAFAuthentication/RAFRolePermissionsContextProvider';
import Aux from '../hoc/Auxiliary/Auxiliary';
import { getDefaultNoPermissionContent, hasPermissions } from './PermissionHelper';
import { isNotNullAndUndefined } from "./utils";


interface IProps {
    permissionNames: string[];
    showDefaultNoPermissionContent?: boolean;
    showDefaultNoPermissionContentShowBackBtn?: boolean;
    noPermissionContet?: React.ReactNode;
}

function RAFPermissionsRender(
    { children, ...props }: PropsWithChildren<IProps>,
) {

    return (
        <Aux>
            <RolePermissionsContext.Consumer>
                {({ permissionValue }) => {
                    let isPermission = hasPermissions(permissionValue, props.permissionNames);
                    if (isPermission === true) {
                        return <Aux>{children}</Aux>;
                    }
                    else {
                        if (isNotNullAndUndefined(props.noPermissionContet)) {
                            return props.noPermissionContet;
                        } else if (props.showDefaultNoPermissionContent) {
                            return getDefaultNoPermissionContent(props.showDefaultNoPermissionContentShowBackBtn);
                        } else {
                            return <Aux></Aux>;
                        }
                    }
                }
                }
            </RolePermissionsContext.Consumer>
        </Aux>
    );
}

export default RAFPermissionsRender;

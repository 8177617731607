import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { debounce } from "@syncfusion/ej2-base";
import React, { PropsWithChildren, Reducer, useReducer } from "react";
import { Constants } from "../../constants/Common/Constants";
import { RAFCustomFilter } from "../RAFViewPanels/RAFFilterColumn/RAFCustomFilter";
import { createInstance } from '../Utility/FormUtility';
import { ILookupRow } from "../models/CompositeTypes/ILookupRow";
import { LookUpRow } from "../models/CompositeTypes/LookUpRow";
import { getSearchedLookUpItems, getUniqueFilteredLookUpDropdownItems } from './AutoCompleteMUIHelper';
import { IsNotNullOrWhiteSpace, isNotEmptyArray, isNotNullAndUndefined } from "./utils";

interface IProps {
    inputName: string;
    inputValue: string;
    placeholder: string;
    onChanged: (label: string, value: string) => void;
    moduleName: string;
    customFilter: RAFCustomFilter,
    url: string,
    type?: (typeof ILookupRow),
    preventOnChangeOnBlur: boolean;
    setUIDAsValueField?: boolean;
}

interface IState {
    dropdownState: boolean;
    dataSource: LookUpRow[];
    take: number;
    skip: number;
    prevSearchedText: string,
}

function RAFAutoCompleteMUITextBox({
    inputName,
    inputValue,
    onChanged,
    ...props
}: PropsWithChildren<IProps>) {
    const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
        (state, newState) => ({ ...state, ...newState }),
        {
            dropdownState: false,
            dataSource: [],
            take: 500,
            skip: 0,
            prevSearchedText: null,
        }
    );

    const objLookupRow = isNotNullAndUndefined(props.type)
        ? (createInstance(props.type) as ILookupRow)
        : null;

    const url = isNotNullAndUndefined(props.url)
        ? Constants.baseAPIUrl + props.url
        : Constants.baseAPIUrl + objLookupRow.getLookupUrl();

    const onOpenAutoDropdown = (action: boolean) => {
        if (action !== state.dropdownState) {
            setState({ dropdownState: action });
        }
    };

    const setHandleInputDebounce = debounce(async (searchedText) => {
        if (IsNotNullOrWhiteSpace(searchedText)) {
            const responseLookUpItems = await getSearchedLookUpItems(
                url,
                state.skip,
                state.take,
                searchedText,
                props.moduleName,
                props.customFilter,
                state.dataSource,
                state.prevSearchedText
            );

            const lookUpItems = isNotNullAndUndefined(responseLookUpItems) ? responseLookUpItems.lookUpDataItems : [];

            if (
                isNotEmptyArray(lookUpItems)
            ) {
                const data: LookUpRow[] = [...lookUpItems];

                const uniqueData = getUniqueFilteredLookUpDropdownItems(data);

                setState({ dataSource: uniqueData, dropdownState: true, prevSearchedText: searchedText });
            }
            else {
                setState({ dropdownState: false, dataSource: [], prevSearchedText: searchedText });
            }
        } else {
            setState({ dropdownState: false, dataSource: [], prevSearchedText: null });
        }
    }, 500);


    const handleInputChange = (event, value) => { //calls when text input changes
        if (isNotNullAndUndefined(event) && event.isTrusted === true && event.type === 'change') {
            setHandleInputDebounce(value);
        }
    };

    const onChangeSearchText = (selectedValue: LookUpRow | string) => {
        if (typeof selectedValue === "string") {
            //on enter key press
            const existingItem =
                IsNotNullOrWhiteSpace(selectedValue) &&
                    isNotEmptyArray(state.dataSource)
                    ? state.dataSource.find(
                        (x) =>
                            isNotNullAndUndefined(x.Value) &&
                            x.Value.toLowerCase() ===
                            selectedValue.toLowerCase()
                    )
                    : null;

            if (isNotNullAndUndefined(existingItem)) {
                onChangeInput(existingItem);
            } else {
                onChangeInput(null, selectedValue);
            }
        } else {
            onChangeInput(selectedValue);
        }
    };

    const onChangeInput = (newValue: LookUpRow, inputText?: string) => {
        if (IsNotNullOrWhiteSpace(inputText)) {
            if (isNotNullAndUndefined(onChanged)) {
                onChanged(inputText, null);
            }
        } else {
            const itemUID = isNotNullAndUndefined(newValue) ? newValue.UID : null;
            const itemValue = isNotNullAndUndefined(newValue) ? newValue.Value : null;
            if (isNotNullAndUndefined(onChanged)) {
                onChanged(itemValue, itemUID);
            }
        }
    };

    const loadMoreOptions = () => {
        //alert('Load more options here and update the options state');
    };

    //const loading = state.dropdownState && state.dataSource.length === 0;

    return (
        <Autocomplete
            id={inputName}
            value={inputValue}
            freeSolo
            open={state.dropdownState}
            onOpen={() => {
                onOpenAutoDropdown(true);
            }}
            onClose={() => {
                onOpenAutoDropdown(false);
            }}
            options={state.dataSource}
            isOptionEqualToValue={(option, value) => option.Value === value}
            getOptionLabel={(option) =>
                typeof option === 'string' ? option : option.Value
            }
            getOptionKey={(option) =>
                typeof option === "string" ? option : option.UID
            }
            // loading={loading}
            onInputChange={handleInputChange}
            onChange={(event, newValue) => {
                onChangeSearchText(newValue);
            }}
            size="small"
            renderInput={(params) => (
                <TextField
                    {...params}
                    // label={props.placeholder}
                    margin="none"
                    variant="outlined"
                    placeholder={props.placeholder}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {/* {loading ? <CircularProgress color="inherit" size={20} /> : null} */}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
            ListboxProps={{
                onScroll: (event) => {
                    const listboxNode = event.currentTarget;
                    if (listboxNode.scrollTop + listboxNode.clientHeight === listboxNode.scrollHeight) {
                        // The scroll has hit the bottom, load more options
                        loadMoreOptions();
                    }
                },
            }}
            onBlur={(event) => {
                if (props.preventOnChangeOnBlur === true) {
                } else {
                    const searchText = event.target['value'];
                    onChangeSearchText(searchText);
                }
            }}
            clearIcon={null}
        />
    );
}

export default React.memo(RAFAutoCompleteMUITextBox);
import { DatePicker, DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import { DropDownList, DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import moment from 'moment';
import React from "react";
import { MomentFormats, RAFDatePickerViewFormat, RAFDatePresets } from "../../constants/Common/Constants";
import { IsNotNullOrWhiteSpace, isArray, isNotNullAndUndefined, isString } from "../helpers/utils";

interface IProps {
    values?: string[] | string;
    //dataSource?: { [key: string]: Object }[] | string[] | number[] | boolean[];
    //fileds?: object;
    onChange?: (value: string[] | string, element: Element) => void,
    presetDateModel?: { value: string, label: string, GroupName?: string; }[],
}

interface IState {
    showDatePicker: boolean,
    presetDropdownValue: string,
    startDate: Date,
    endDate: Date,
}

class RAFCustomDateFilter extends React.Component<IProps, IState> {
    private presetDateModel;
    private dateModel = [
        //day
        { value: "custom", label: "Custom", GroupName: "Custom" },
        { value: RAFDatePresets.Yesterday, label: "Yesterday", GroupName: "Day" },
        { value: RAFDatePresets.Today, label: "Today", GroupName: "Day" },
        { value: RAFDatePresets.Tomorrow, label: "Tomorrow", GroupName: "Day" },
        { value: RAFDatePresets.TillYesterday, label: "till Yesterday", GroupName: "Day" },
        { value: RAFDatePresets.TillToday, label: "till Today", GroupName: "Day" },
        { value: RAFDatePresets.TillNow, label: "till Now", GroupName: "Day" },
        { value: RAFDatePresets.Last7Days, label: "Last 7 Days", GroupName: "Day" },
        { value: RAFDatePresets.Last15Days, label: "Last 15 Days", GroupName: "Day" },
        { value: RAFDatePresets.Last30Days, label: "Last 30 Days", GroupName: "Day" },
        //{ value: RAFDatePresets.Last45Days, label: "Last 45 Days", GroupName: "Day" },
        { value: RAFDatePresets.Last60Days, label: "Last 60 Days", GroupName: "Day" },
        { value: RAFDatePresets.Last90Days, label: "Last 90 Days", GroupName: "Day" },
        //{ value: RAFDatePresets.Last120Days, label: "Last 120 Days", GroupName: "Day" },
        { value: RAFDatePresets.Last180Days, label: "Last 180 Days", GroupName: "Day" },
        { value: RAFDatePresets.Next7Days, label: "Next 7 Days", GroupName: "Day" },
        { value: RAFDatePresets.Next15Days, label: "Next 15 Days", GroupName: "Day" },
        { value: RAFDatePresets.Next30Days, label: "Next 30 Days", GroupName: "Day" },
        //{ value: RAFDatePresets.Next45Days, label: "Next 45 Days", GroupName: "Day" },
        //{ value: RAFDatePresets.Next60Days, label: "Next 60 Days", GroupName: "Day" },
        //{ value: RAFDatePresets.Next90Days, label: "Next 90 Days", GroupName: "Day" },
        //{ value: RAFDatePresets.Next120Days, label: "Next 120 Days", GroupName: "Day" },
        //Calendar Week
        { value: RAFDatePresets.LastWeek, label: "Last Week", GroupName: "Calendar Week" },
        { value: RAFDatePresets.ThisWeek, label: "This Week", GroupName: "Calendar Week" },
        { value: RAFDatePresets.NextWeek, label: "Next Week", GroupName: "Calendar Week" },
        //Calendar Month
        { value: RAFDatePresets.LastMonth, label: "Last Month", GroupName: "Calendar Month" },
        { value: RAFDatePresets.ThisMonth, label: "This Month", GroupName: "Calendar Month" },
        { value: RAFDatePresets.NextMonth, label: "Next Month", GroupName: "Calendar Month" },
        //{ value: RAFDatePresets.CurrentAndPreviousMonth, label: "Current and Previous Month", GroupName: "Calendar Month" },
        //{ value: RAFDatePresets.CurrentAndNextMonth, label: "Current and Next Month", GroupName: "Calendar Month" },
        //Calendar Year
        { value: RAFDatePresets.CurrentCalendarYear, label: "This Year", GroupName: "Calendar Year" },
        { value: RAFDatePresets.PreviousCalendarYear, label: "Last Year", GroupName: "Calendar Year" },
        //{ value: RAFDatePresets.Previous2CalendarYear, label: "Previous 2 CY", GroupName: "Calendar Year" },
        //{ value: RAFDatePresets.Ago2CalendarYear, label: "2 CY Ago", GroupName: "Calendar Year" },
        { value: RAFDatePresets.NextCalendarYear, label: "Next Year", GroupName: "Calendar Year" },
        //{ value: RAFDatePresets.CurrentAndPreviousCalendarYear, label: "Current and Previous CY", GroupName: "Calendar Year" },
        //{ value: RAFDatePresets.CurrentAndPrevious2CalendarYear, label: "Current and Previous 2 CY", GroupName: "Calendar Year" },
        //{ value: RAFDatePresets.CurrentAndNextCalendarYear, label: "Current and Next CY", GroupName: "Calendar Year" },
        //Calendar Quarter
        //{ value: RAFDatePresets.CurrentCalendarQuarter, label: "Current CQ", GroupName: "Calendar Quarter" },
        //{ value: RAFDatePresets.CurrentAndNextCalendarQuarter, label: "Current and Next CQ", GroupName: "Calendar Quarter" },
        //{ value: RAFDatePresets.CurrentAndPreviousCalendarQuarter, label: "Current and Previous CQ", GroupName: "Calendar Quarter" },
        //{ value: RAFDatePresets.NextCalendarQuarter, label: "Next CQ", GroupName: "Calendar Quarter" },
        //{ value: RAFDatePresets.PreviousCalendarQuarter, label: "Previous CQ", GroupName: "Calendar Quarter" },
        //{ value: RAFDatePresets.CurrentAndNext3CalendarQuarter, label: "Current and Next 3 CQ", GroupName: "Calendar Quarter" },



    ];

    constructor(props?: IProps) {
        super(props);
        if (isNotNullAndUndefined(this.props.presetDateModel)) {
            this.presetDateModel = this.props.presetDateModel;
        } else {
            this.presetDateModel = this.dateModel;
        }
        this.state = {
            presetDropdownValue: "custom",
            showDatePicker: true,
            startDate: null,
            endDate: null,

        };
    }


    datePickerObj: DatePicker;
    dropDownObj: DropDownList;
    fields = { text: 'label', value: 'value' };

    componentDidMount() {
        let startDate: Date = null;
        let endDate: Date = null;
        let presetDropdownValue = 'custom';
        if (isNotNullAndUndefined(this.props.values)) {
            if (!isArray(this.props.values) || isString(this.props.values)) {
                const dateRange: string[] = this.props.values.toString().split(",");
                if (isNotNullAndUndefined(dateRange)) {
                    if (dateRange.length > 1) {
                        startDate = moment(dateRange[0], MomentFormats.DATE, true).toDate();
                        endDate = moment(dateRange[1], MomentFormats.DATE, true).toDate();
                    }
                    else if (dateRange.length === 1) {
                        const isPreset: boolean = this.presetDateModel.findIndex(x => x.value === dateRange[0]) >= 0 ? true : false;
                        if (isPreset) {
                            presetDropdownValue = dateRange[0];
                        }
                        else {
                            startDate = moment(dateRange[0], MomentFormats.DATE, true).toDate();
                        }
                    }
                }
            }
            else {
                const dateRange: string[] = this.props.values as string[];
                if (isNotNullAndUndefined(dateRange)) {
                    if (dateRange.length > 1) {
                        startDate = moment(dateRange[0], MomentFormats.DATE, true).toDate();
                        endDate = moment(dateRange[1], MomentFormats.DATE, true).toDate();
                    }
                    else if (dateRange.length === 1) {
                        const isPreset: boolean = this.presetDateModel.findIndex(x => x.value === dateRange[0]) >= 0 ? true : false;
                        if (isPreset) {
                            presetDropdownValue = dateRange[0];
                        }
                        else {
                            startDate = moment(dateRange[0], MomentFormats.DATE, true).toDate();
                        }
                    }
                }
            }
        }
        this.setState({
            startDate,
            endDate,
            presetDropdownValue,
            showDatePicker: presetDropdownValue === "custom" ? true : false
        }, () => {

        });
    }

    public onSelect = (value) => {
        if (isNotNullAndUndefined(value) && value === 'custom') {
            this.setState({ showDatePicker: true, presetDropdownValue: value });
        }
        else {
            this.setState({ showDatePicker: false, presetDropdownValue: value });
        }
    };

    datePresetsDropdownChanged = (label, value, element: Element) => {
        if (isNotNullAndUndefined(value) && value === 'custom') {
            this.setState({ showDatePicker: true, presetDropdownValue: value, startDate: null },
                () => {
                    if (this.props.onChange)
                        this.props.onChange(null, element);
                }
            );
        }
        else {
            this.setState({ showDatePicker: false, presetDropdownValue: value, startDate: null },
                () => {
                    if (this.props.onChange)
                        this.props.onChange(value, element);
                }
            );
        }
    };

    removeFocus() {
        if (isNotNullAndUndefined(this.datePickerObj)) {
            this.datePickerObj.focusOut();
        }
    }

    render() {
        let showDatePicker = this.state.showDatePicker;
        //let predefinedTemplate = this.props.dataSource;
        //let values = this.props.values;
        return <div className="d-flex ">
            <div>
                <DropDownListComponent
                    value={this.state.presetDropdownValue}
                    //select={e => this.onSelect(e.itemData['Value'])}
                    //change={(e) => { this.props.notifyChange(e.value, e.element) }}

                    change={(e) => {
                        if (isNotNullAndUndefined(e.itemData) && isNotNullAndUndefined(e.element)) {
                            this.datePresetsDropdownChanged(e.itemData['label'], e.itemData.value, e.element.closest('.e-template'));
                        }
                    }}
                    dataSource={this.presetDateModel} fields={this.fields} allowFiltering />

            </div>
            {showDatePicker &&
                <div className="ps-2">
                    <DatePickerComponent
                        ref={(r) => (this.datePickerObj = r)}
                        value={IsNotNullOrWhiteSpace(this.state.startDate) ? this.state.startDate : null}
                        change={(e) => {
                            if (e.isInteracted) {
                                this.removeFocus();
                                if (this.props.onChange) {
                                    this.props.onChange(moment(e.value).format(MomentFormats.DATE).toString(), e.element.closest('.e-template'));
                                }
                            }
                        }}
                        strictMode
                        //openOnFocus
                        onBlur={(e) => {
                            this.datePickerObj.hide();
                            this.removeFocus();
                        }}
                        format={RAFDatePickerViewFormat.DATE}
                        enableMask={true}
                        placeholder={"DD/MM/YYYY"}
                        maskPlaceholder={{ day: 'DD', month: 'MM', year: 'YYYY' }}
                    >
                        {/* <Inject services={[MaskedDateTime]} /> */}
                    </DatePickerComponent>
                </div>
            }
        </div>;
    }
}
/*

function RAFCustomDateFilter(values, dataSource, fields, notifyChange) {

    const [showDatePicker, setshowDatePicker] = useState(false);

    const onSelect = (value) => {
        if (isNotNullAndUndefined(value) && value === 'custom') {
            setshowDatePicker(true);
        }
        else {
            setshowDatePicker(false);
        }
    }
    console.log('custom date filter', values, dataSource, fields, notifyChange)
    let dropDownObj: DropDownList;

    /*return  <div className="d-flex flex-column">
            <div>
                <DropDownListComponent value={values} select={e => onSelect(e.itemData['Value'])}
                    change={(e) => {
                        if (notifyChange)
                            notifyChange(e.value, e.element)
                    }}
                    dataSource={dataSource} fields={fileds} />
            </div>
            {showDatePicker &&
                <div>
                    <DatePickerComponent value={values} change={(e) => {
                        if (notifyChange)
                            notifyChange(moment(e.value).format(MomentFormats.DATE).toString(), e.element);
                    }}
                        format={RAFDatePickerViewFormat.DATE} />
                </div>
            }

        </div>*/
/*
    return <div>
        {
            dropDownObj = new DropDownList({
                dataSource: dataSource,
                                fields: fields,
                                value: values,
                                select: (e: any) => {
                                    onSelect(e.itemData['Value'])
                                },
                                change: (e: any) => {
                                    notifyChange(e.value, e.element);
                                }
                            })
        }
        </div>
}
*/

export default RAFCustomDateFilter;

import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import React, { PropsWithChildren, ReactNode } from "react";
import { Field } from "react-final-form";
import { isNotNullAndUndefined } from "../helpers/utils";
import "./NavigationStyle.scss";
import RAFDetailField from "./RAFDetailField";

interface IProps {
    value?: string;
    label?: string,
    fieldName?: string,
    emptyString?: string,
    position?: any,
    required?: boolean,
    disabled?: boolean,
    showLabel?: boolean,
    customContent?: React.ReactFragment | ReactNode;
    customValue?: React.ReactFragment | ReactNode;
    tooltipContent?: React.ReactFragment | ReactNode;
}


function RAFSummaryDetailInlineEdit(
    { //value, 
        label,
        fieldName,
        showLabel,
        //customContent, 
        customValue,
        tooltipContent,
        disabled,
        required,
        ...props }: PropsWithChildren<IProps>,
) {
    let tooltipComponent: TooltipComponent;

    const onClickToolTip = (args) => {
        if (args.target && (args.target as any).id === "btnclose") {
            if (isNotNullAndUndefined(tooltipComponent)) {

                tooltipComponent && tooltipComponent.close();
                let customScrollBarDiv = document.querySelector('#businessProcessContentDiv');
                if (isNotNullAndUndefined(customScrollBarDiv)) {
                    customScrollBarDiv.classList.remove('noScroll');
                }

                let openTooltipsBtn = document.querySelectorAll('.summaryTooltip');
                if (isNotNullAndUndefined(openTooltipsBtn)) {
                    openTooltipsBtn.forEach((x) => {
                        x.classList.remove('disabled');
                    });
                }
            }
        }

        if (args.target && (args.target as any).id === "btnSave") {
            if (isNotNullAndUndefined(tooltipComponent)) {
                tooltipComponent && tooltipComponent.close();
                let customScrollBarDiv = document.querySelector('#businessProcessContentDiv');
                if (isNotNullAndUndefined(customScrollBarDiv)) {
                    customScrollBarDiv.classList.remove('noScroll');
                }

                let openTooltipsBtn = document.querySelectorAll('.summaryTooltip');
                if (isNotNullAndUndefined(openTooltipsBtn)) {
                    openTooltipsBtn.forEach((x) => {
                        x.classList.remove('disabled');
                    });
                }
            }
        }
    };

    const onTooltipClicked = (args) => {
        if (args.target && (args.target as any).id === "tooltipDiv") {
            tooltipComponent && tooltipComponent.open(args.target);
        }

        let openTooltipsBtn = document.querySelectorAll('.summaryTooltip');
        if (isNotNullAndUndefined(openTooltipsBtn)) {
            openTooltipsBtn.forEach((x) => {
                x.classList.add('disabled');
            });
        }

        let customScrollBarDiv = document.querySelector('#businessProcessContentDiv');
        if (isNotNullAndUndefined(customScrollBarDiv)) {
            customScrollBarDiv.classList.add('noScroll');
        }
    };

    return (
        <div className="formcontent-outer-div" id={fieldName + "FormContent"}>
            <div className="summaryCount-div">
                <div className="summarySection p-0">
                    <div className="summaryText">
                        {showLabel !== false ?
                            <span className="key_header">
                                <label className={required ? "required m-0" : "m-0"}>
                                    {label}
                                </label>
                            </span>
                            :
                            null
                        }
                        {disabled === true ?
                            <div className="key_value_div key_value-disabled">
                                <div className="key_value">
                                    {isNotNullAndUndefined(customValue) ?
                                        customValue
                                        :
                                        <Field name={fieldName} allowNull parse={value => (value === "" ? null : value)} >
                                            {({ input }) => {
                                                return (<div>
                                                    <RAFDetailField labelDivClassName="hidden" value={input.value}
                                                        {...props.emptyString ? { emptyString: props.emptyString } : {}}
                                                    ></RAFDetailField>
                                                </div>);
                                            }}
                                        </Field>
                                    }
                                </div>
                            </div>
                            :
                            <div className="key_value_div">
                                {isNotNullAndUndefined(tooltipContent) ?
                                    <TooltipComponent target="#businessProcessContentDiv" position={isNotNullAndUndefined(props.position) ? props.position : "BottomCenter"} content={tooltipContent}
                                        opensOn='Custom' cssClass='e-tooltip-template-css' className="tooltiptarget"
                                        onClick={(args) => onClickToolTip(args)}
                                        ref={a => tooltipComponent = a} key={Math.random()}
                                    >
                                        <div className="w-100">
                                            <button className="summaryTooltip" id="tooltipDiv" type="button" onClick={onTooltipClicked.bind(this)}>
                                                <div className="key_value w-50" style={{ pointerEvents: 'none' }}>
                                                    {isNotNullAndUndefined(customValue) ?
                                                        customValue
                                                        :
                                                        <Field name={fieldName} allowNull parse={value => (value === "" ? null : value)} >
                                                            {({ input }) => {
                                                                return (<div>
                                                                    <RAFDetailField labelDivClassName="hidden" value={input.value}
                                                                        {...props.emptyString ? { emptyString: props.emptyString } : {}}
                                                                    ></RAFDetailField>
                                                                </div>);
                                                            }}
                                                        </Field>
                                                    }
                                                    <span className="fal fa-pencil"></span>
                                                </div>
                                            </button>
                                        </div>
                                    </TooltipComponent>
                                    : ""}

                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RAFSummaryDetailInlineEdit;

import {
  ToastAnimationSettingsModel,
  ToastAnimationsModel,
  ToastComponent,
  ToastPositionModel,
} from "@syncfusion/ej2-react-notifications";
import { Fragment } from "react";

let toastInstance: ToastComponent;

let successToastInstance: ToastComponent;
let warningToastInstance: ToastComponent;
let errorToastInstance: ToastComponent;
let informationToastInstance: ToastComponent;

let toastPosition: ToastPositionModel = { X: "Center", Y: "Top" };
let showtoastAnimation: ToastAnimationsModel = {
  duration: 500,
  effect: "SlideRightIn",
};
let hidetoastAnimation: ToastAnimationsModel = {
  duration: 500,
  effect: "SlideRightOut",
};
let toastAnimation: ToastAnimationSettingsModel = {
  show: showtoastAnimation,
  hide: hidetoastAnimation,
};

export const showToast = (
  message: string | HTMLElement,
  title: string,
  timeOut?: number
) => {
  if (toastInstance) toastInstance.show({ content: message, title: title });
};

export const showSuccessToast = (
  message: string | HTMLElement,
  title?: string,
  timeOut?: number
) => {
  if (successToastInstance)
    successToastInstance.show({
      content: message,
      title: title || "Success!",
      cssClass: "e-toast-success custom-toast icon-bg-color",
      icon: "far fa-circle-check h-auto",
      showCloseButton: true,
      timeOut: timeOut || 2000,
      position: toastPosition,
    });
};

export const showWarningToast = (
  message: string | HTMLElement,
  title?: string,
  timeOut?: number
) => {
  if (warningToastInstance)
    warningToastInstance.show({
      content: message,
      title: title || "Warning!",
      cssClass: "e-toast-warning custom-toast icon-bg-color",
      icon: "fas fa-triangle-exclamation h-auto",
      showCloseButton: true,
      timeOut: timeOut || 3000,
      position: toastPosition,
      animation: toastAnimation,
    });
};

export const showErrorToast = (
  message: string | HTMLElement,
  title?: string,
  timeOut?: number
) => {
  if (errorToastInstance)
    errorToastInstance.show({
      content: message,
      title: title || "Error!",
      cssClass: "e-toast-danger custom-toast icon-bg-color",
      icon: "far fa-circle-xmark h-auto",
      showCloseButton: true,
      timeOut: timeOut || 3000,
      position: toastPosition,
    });
};

export const showInformationToast = (
  message: string | HTMLElement,
  title?: string,
  timeOut?: number
) => {
  if (informationToastInstance)
    informationToastInstance.show({
      content: message,
      title: title || "Information!",
      cssClass: "e-toast-info custom-toast icon-bg-color",
      icon: "fas fa-circle-exclamation h-auto",
      showCloseButton: true,
      timeOut: timeOut || 2000,
      position: toastPosition,
    });
};

export const hideToast = () => {
  if (toastInstance) toastInstance.hide();
  if (successToastInstance) successToastInstance.hide();
  if (warningToastInstance) warningToastInstance.hide();
  if (errorToastInstance) errorToastInstance.hide();
  if (informationToastInstance) informationToastInstance.hide();
};

function RAFToastComponent() {
  return (
    <Fragment>
      <ToastComponent
        ref={(toast) => (toastInstance = toast!)}
        title="Title"
        content="Content"
        position={{ X: "Right", Y: "Top" }}
      />
      <ToastComponent
        ref={(toast) => (successToastInstance = toast!)}
        title="Title"
        content="Content"
        position={{ X: "Right", Y: "Top" }}
      />
      <ToastComponent
        ref={(toast) => (errorToastInstance = toast!)}
        title="Title"
        content="Content"
        position={{ X: "Right", Y: "Top" }}
      />
      <ToastComponent
        ref={(toast) => (warningToastInstance = toast!)}
        title="Title"
        content="Content"
        position={{ X: "Right", Y: "Top" }}
      />
      <ToastComponent
        ref={(toast) => (informationToastInstance = toast!)}
        title="Title"
        content="Content"
        position={{ X: "Right", Y: "Top" }}
      />
    </Fragment>
  );
}

export default RAFToastComponent;

import React, { useContext } from 'react';
import { RAFLayout } from '../../constants/Common/Constants';
import { RAFLayoutContext } from '../Inputs/RAFForm';
//import { RAFLayoutContext } from '../../RAFComponents/helpers/utils';
import "./NavigationStyle.scss";

const RAFPanel = (props: { children: React.ReactNode; className?: string, layout?: RAFLayout, id?: string; }) => {

    const className = (props.className !== null && props.className !== undefined) ? props.className + " row g-0" : "row g-0";
    const id = (props.id !== null && props.id !== undefined) ? props.id : "";

    const rafLayoutContextValue = useContext(RAFLayoutContext);

    let propsLayout = (props.layout !== null && props.layout !== undefined) ? props.layout : ((rafLayoutContextValue !== null && rafLayoutContextValue !== undefined) ? rafLayoutContextValue : RAFLayout.OneColumnLayout);
    let objChildren = props.children ? (
        React.Children.map(props.children, (child) => {
            return (
                <div className={(propsLayout === RAFLayout.FourColumnLayout) ? "col-sm-3" : (propsLayout === RAFLayout.ThreeColumnLayout) ? "col-sm-4" : (propsLayout === RAFLayout.TwoColumnLayout) ? "col-sm-6" : "col-sm-12"} >
                    <div className="w-100 d-flex flex-column">
                        {child}
                    </div>
                </div>
            );
        })
    ) : null;

    return (
        <RAFLayoutContext.Provider value={propsLayout}>
            <div className={className} id={id}>
                {objChildren}
            </div>
        </RAFLayoutContext.Provider>
    );

};
export default RAFPanel;
import { PropsWithChildren } from "react";
import { FieldArray } from 'react-final-form-arrays';
import { RAFDataType } from '../../RAFComponents/models/Common/RAFDataType';
import RAFChoiceOption from "../Inputs/RAFChoiceOption";
import RAFDropdownCC from "../Inputs/RAFDropdownCC";
import { RAFFieldProps } from "../Inputs/RFFUtils";
import RAFExpressionChooser from "../Navigation/RAFExpressionChooser";
import { QueryAttributeJM } from "../models/Common/QueryAttributeJM";

interface IProps {
    showAdd?: boolean;
    showRemove?: boolean;
    attributesRow?: QueryAttributeJM[];
}

function RAFGroupSortBy<T>(
    { field, attributesRow,
    }: PropsWithChildren<RAFFieldProps<T> & IProps>,
) {

    return (
        <div className="app-container p-0" id={"rafdiv" + field.toString()} >
            <div className="list">
                <FieldArray name={field.toString()}>
                    {({ fields }) =>
                        fields.map((name, index) => {
                            let selectedFieldName = fields.value[index]['Field'];
                            let selectedFieldRow = attributesRow && attributesRow.find(x => x.PropertyName === selectedFieldName);
                            return (
                                <div key={index} className="w-100">
                                    <RAFDropdownCC field={`${name}.Field`} showLabel label={index === 0 ? "Group By" : "Then By"} placeholder="Select Field" allowFiltering
                                    >
                                        {attributesRow && attributesRow.map(item => {
                                            return <RAFChoiceOption key={item.AttributeUID} label={item.DisplayName} value={item.PropertyName} />;
                                        })}
                                    </RAFDropdownCC>
                                    {selectedFieldRow && selectedFieldRow.DataType === RAFDataType.Date &&
                                        <RAFExpressionChooser field={`${name}.AggregateFunction`} label={"Expression"} showLabel placeholder="Select Expression" selectedField={selectedFieldName} selectedFieldsList={attributesRow} onChanged={(label, value) => null} />
                                    }
                                </div>
                            );
                        })
                    }
                </FieldArray>
            </div>
        </div>
    );
}

export default RAFGroupSortBy;


import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { Component } from 'react';
import { FormRenderProps } from "react-final-form";
import { RolePermissionsContext } from "../../RAFAuthentication/RAFRolePermissionsContextProvider";
import { ContentType } from "../../constants/Common/Constants";
import RAFManageTagItems from "../Inputs/RAFManageTagItems";
import { setFormValue } from "../Inputs/RFFUtils";
import { showWarningToast } from "../Utility/RAFToastComponent";
import { RolePermissionRow } from "../models/Common/RolePermissionRow";
import * as repositoryActions from "../store/actions/repositoryActions";
import { hideProgress, showProgress } from "./AppHelper";
import { hasPermission } from "./PermissionHelper";
import { IDialogProps, RAFFormComponentProps, getSaveRequest, isNotNullAndUndefined } from "./utils";

interface IProps {
    initialTags?: string[];
    relatedToUID?: string;
    moduleName?: string;
    submitURL?: string;
    showSaveButton?: boolean;
    disabled?: boolean;
    label?: string;
    detailsPanel?: boolean;
}

class RAFTagDetails extends Component<IProps & RAFFormComponentProps & IDialogProps> {

    _isMounted = false;
    public rafForm: FormRenderProps | null;
    static defaultProps: { disabled: boolean; showSaveButton: boolean; };

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
        };
    }

    componentDidMount = () => {
        this._isMounted = true;
    };

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidUpdate() {
        //if (!deepEqual(prevState. ,  )) {
        //}
    }

    onSubmitTagListClicked = (value, permissionValue: RolePermissionRow) => {
        if (hasPermission(permissionValue, isNotNullAndUndefined(this.props.relatedToUID) ? `${this.props.moduleName}::Update` : `${this.props.moduleName}::Add`)) {
            let url = isNotNullAndUndefined(this.props.submitURL) ? this.props.submitURL :
                isNotNullAndUndefined(this.props.moduleName) ?
                    this.props.moduleName.replace(/_/g, '').replace(/\s/g, '') + '/Save'
                    : null;

            if (isNotNullAndUndefined(url) && isNotNullAndUndefined(value) && isNotNullAndUndefined(this.props.relatedToUID)) {
                let progressDiv = showProgress('body', true);
                repositoryActions.postDataAndGetResponse(url, getSaveRequest(value, this.props.relatedToUID), null, ContentType.applicationJson)
                    .then((response) => {
                        hideProgress(progressDiv);
                        if (isNotNullAndUndefined(response) && isNotNullAndUndefined(response.data) && isNotNullAndUndefined(response.data.EntityId)) {
                            if (this.props.onSave) {
                                this.props.onSave(response.data.EntityId, response.data.objectName);
                                let cancelSubmitbtn = document.getElementById('cancelSubmitbtn');
                                if (isNotNullAndUndefined(cancelSubmitbtn))
                                    cancelSubmitbtn.classList.add('hidden');
                            }
                        } else {
                            showWarningToast('Sorry unable to save the tag(s).');
                            this.onCancelClick();
                        }
                    })
                    .catch((error) => error);
            } else {
                showWarningToast('Sorry unable to save the tag(s).');
                this.onCancelClick();
            }
        } else {
            showWarningToast('Sorry, you do not have permission to perform this action.');
            setFormValue(this.rafForm, 'TagsListJson', this.props.initialTags);
        }
    };

    arrayEquals(a, b) {
        return Array.isArray(a) &&
            Array.isArray(b) &&
            a.length === b.length &&
            a.every((val, index) => val === b[index]);
    }

    onChangeTagListItems = (value, permissionValue: RolePermissionRow) => {
        let cancelSubmitbtn = document.getElementById('cancelSubmitbtn');
        if (isNotNullAndUndefined(cancelSubmitbtn)) {
            if (!this.arrayEquals(this.props.initialTags, value)) {
                cancelSubmitbtn.classList.remove('hidden');
            } else {
                cancelSubmitbtn.classList.add('hidden');
            }
        }
        const values = { 'UID': this.props.relatedToUID, 'TagsListJson': value };
        this.onSubmitTagListClicked(values, permissionValue);
        if (this.props.showSaveButton !== true && isNotNullAndUndefined(this.rafForm)) {
            // this.rafForm.form.submit();
        }
    };

    onCancelClick = () => {
        let cancelSubmitbtn = document.getElementById('cancelSubmitbtn');
        if (isNotNullAndUndefined(cancelSubmitbtn))
            cancelSubmitbtn.classList.add('hidden');
        setFormValue(this.rafForm, 'TagsListJson', this.props.initialTags);
    };

    render() {
        //let hasOwnProperty = isNotNullAndUndefined(initialValues) ? initialValues.hasOwnProperty('TagsListJson') : false;

        if (isNotNullAndUndefined(this.props.relatedToUID)) {
            return (
                <RolePermissionsContext.Consumer>
                    {({ permissionValue }) => {
                        const hasPermissionToEdit = hasPermission(permissionValue, isNotNullAndUndefined(this.props.relatedToUID) ?
                            `${this.props.moduleName}::Update` : `${this.props.moduleName}::Add`);
                        return (
                            <div className="w-100 d-flex">
                                {this.props.detailsPanel === true && isNotNullAndUndefined(this.props.label) ?
                                    <label className="form-label pe-1 text-nowrap d-inline-block">
                                        <span className="fas fa-tag me-1"></span>
                                        <span style={{ fontSize: "14px" }} > Tags</span>
                                    </label>
                                    :
                                    ""
                                }
                                {/* <RAFForm className='d-inline-block'
                        initialValues={{ 'UID': this.props.relatedToUID, 'TagsListJson': this.props.initialTags }}
                        layout={RAFLayout.TwoColumnLayout}
                        formRef={g => this.rafForm = g}
                        submitOnEnterKey={false}
                        onSubmit={this.onSubmitTagListClicked} > */}
                                <RAFManageTagItems field="TagsListJson" label="TagsListJson"
                                    placeholder=" TagsListJson"
                                    onChanged={(value) => this.onChangeTagListItems(value, permissionValue)}
                                    showAddBtn
                                    disabled={hasPermissionToEdit === false ? true : this.props.disabled}
                                    moduleName={this.props.moduleName}
                                    inputValue={this.props.initialTags}
                                />
                                {this.props.showSaveButton === true && (
                                    <div className='hidden' id='cancelSubmitbtn'>
                                        <ButtonComponent type="button" cssClass='form-custom-button e-flat e-info'>Save</ButtonComponent>
                                        <ButtonComponent type="button" cssClass='form-custom-button e-flat' onClick={() => this.onCancelClick()}>Cancel</ButtonComponent>
                                    </div>
                                )}
                                {/* </RAFForm> */}
                            </div>
                        );
                    }}
                </RolePermissionsContext.Consumer>
            );
        } else {
            return (
                <div>
                </div>
            );
        }
    }
}

RAFTagDetails.defaultProps = {
    disabled: false,
    showSaveButton: false,
};

//RAFTagDetails.contextType = RolePermissionsContext;

export default RAFTagDetails;
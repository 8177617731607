import React from "react";
import Aux from "../../RAFComponents/hoc/Auxiliary/Auxiliary";
import { isNotNullAndUndefined } from "../helpers/utils";
import "./NavigationStyle.scss";

const RAFDetailsFieldWithIcon = (props: { value?: any, iconName?: string, iconClassName?: string, inputFieldClassName?: string }) => {
    let value = props.value;
    let iconName = props.iconName;
    let iconClassName = props.iconClassName;
    let inputFieldClassName = props.inputFieldClassName;

    return <Aux>
        <div className="w-100">
            <div className="g-1 row flex-nowrap">
                <div className="col-auto d-flex align-items-center justify-content-center">
                    <span className={iconClassName !== null ? iconClassName : "detailsSectionIcon text-center"}>
                        <i className={iconName !== null ? "fa fa-" + iconName : ""}> </i>
                        {/*<MDBIcon icon={iconName !== null ? iconName : ""} />*/}
                    </span>
                </div>
                <div className={isNotNullAndUndefined(inputFieldClassName) ? inputFieldClassName + " col-auto d-flex align-items-center justify-content-center" : "col-auto d-flex align-items-center justify-content-center"}>
                    <span className="m-0"> {(value !== null && value !== undefined && value.toString() !== '') ? String(value) : '\u00A0'}
                    </span>
                </div>
            </div>
        </div>

    </Aux>;
};
export default RAFDetailsFieldWithIcon;

import {
  InPlaceEditorComponent,
  Inject,
  Rte,
} from "@syncfusion/ej2-react-inplace-editor";
import React, { PropsWithChildren, ReactNode } from "react";
import { Constants, RAFInputType } from "../../constants/Common/Constants";
import {
  IsNullOrWhiteSpace,
  convertUTCDateToLocalTimezone,
  deepEqual,
  isNotEmptyArray,
  isNotNullAndUndefined,
} from "../../RAFComponents/helpers/utils";
import {
  RAFDataManager,
  RAFUrlAdaptor,
} from "../../RAFComponents/Inputs/RAFUrlAdaptor";
import { getQueryAttribute } from "../helpers/AppHelper";
import { QueryAttributeJM, ValueJson } from "../models/Common/QueryAttributeJM";
import { RAFDataType } from "../models/Common/RAFDataType";
import "./NavigationStyle.scss";
import RAFFieldLabel from "../Inputs/RAFFieldLabel";
import { RAFDefaultFieldClassName } from "../Inputs/RFFUtils";

interface IProps {
  title?: string;
  emptyText?: string;
  value?: any;
  labelClass?: string;
  labelClassName?: string;
  onChange?: (e: any) => void;
  placeholder?: string;
  valueClassName?: string;
  type?: string;
  dataSoure?: any;
  url?: string;
  required?: boolean;
  name?: string;
  moduleName?: string;
  disabled?: boolean;
  isColorOption?: boolean;
  fieldName?: string;
  iconName?: string;
  showLabel?: boolean;
  formGroupClassName?: string;
  rowClassName?: string;
  description?: string;
  descriptionAsLabel?: boolean;
}
export class RAFDetailFieldEditableCustom extends React.Component<
  PropsWithChildren<IProps>
> {
  state = {
    dropdownDatasoure: [],
    colorCode: "",
    displayName: "",
    detailFieldOuterDivKey: Math.random(),
  };
  private _isMounted = false;

  private rteModel = {
    toolbarSettings: {
      enableFloating: false,
      items: [
        "Bold",
        "Italic",
        "Underline",
        "FontColor",
        "BackgroundColor",
        "LowerCase",
        "UpperCase",
        "|",
        "OrderedList",
        "UnorderedList",
      ],
    },
  };
  private popupSettings = { model: { width: 300 } };
  private saveButton = { content: "Save", cssClass: "e-outline" };
  private cancelButton = { content: "Cancel", cssClass: "e-outline" };

  handleChange = (e) => {
    this.props.onChange(e);
  };

  public changed(e: any): void {
    this.props.onChange(e);
  }

  componentDidMount = () => {
    this._isMounted = true;
    this.refresh();
  };

  componentDidUpdate(prevProps /*, prevState*/) {
    if (!deepEqual(prevProps.value, this.props.value)) {
      this.refresh();
    }
  }

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  refresh1() {
    this.getColorCode();
    let dataSource = [];
    if (this.props.type === RAFInputType.DropDownList) {
      getQueryAttribute(this.props.moduleName, this.props.fieldName)
        .then((queryAttribute) => {
          let items = this.getValueJson(queryAttribute);
          items.forEach((item) => {
            dataSource.push({
              label: item.DisplayName,
              value: item.DisplayName,
            });
          });
          this.setState({ dropdownDatasoure: dataSource });
        })
        .catch((error) => error);
    }
  }

  refresh() {
    this.getColorCode();
  }

  getColorCode = () => {
    getQueryAttribute(this.props.moduleName, this.props.fieldName)
      .then((queryAttribute) => {
        if (
          isNotNullAndUndefined(queryAttribute) &&
          queryAttribute.DataType === RAFDataType.Dropdown &&
          this.props.isColorOption === true
        ) {
          let items = this.getDataFromChildren(queryAttribute);
          let objRow =
            isNotNullAndUndefined(items) && items.length > 0
              ? items.find((x) => x.DisplayName === this.props.value)
              : null;
          let dataSource = [];
          let dropdownItems = this.getValueJson(queryAttribute);
          if (isNotNullAndUndefined(dropdownItems)) {
            dropdownItems.forEach((item) => {
              dataSource.push({
                label: item.DisplayName,
                value: item.DisplayName,
              });
            });
          }

          if (isNotNullAndUndefined(objRow)) {
            if (this._isMounted) {
              this.setState({
                detailFieldOuterDivKey: Math.random(),
                colorCode: objRow.ColorCode,
                displayName: objRow.DisplayName,
                dropdownDatasoure: dataSource,
              });
            }
          } else {
            if (this._isMounted) {
              this.setState({
                detailFieldOuterDivKey: Math.random(),
                colorCode: null,
                displayName: "Not set",
                dropdownDatasoure: dataSource,
              });
            }
          }
        } else if (
          isNotNullAndUndefined(queryAttribute) &&
          queryAttribute.DataType === RAFDataType.Dropdown
        ) {
          let items = this.getValueJson(queryAttribute);
          let dataSource = [];
          items.forEach((item) => {
            dataSource.push({
              label: item.DisplayName,
              value: item.DisplayName,
            });
          });
          this.setState({
            detailFieldOuterDivKey: Math.random(),
            dropdownDatasoure: dataSource,
          });
        } else {
          if (this._isMounted) {
            this.setState({ detailFieldOuterDivKey: Math.random() });
          }
        }
      })
      .catch((error) => error);
  };

  getDataFromChildren = (attributeJM?: QueryAttributeJM) => {
    let retVal: ValueJson[] = [];
    if (isNotNullAndUndefined(attributeJM)) {
      if (isNotNullAndUndefined(attributeJM.ValueJson)) {
        retVal.push(...attributeJM.ValueJson);
      }
    }
    return retVal;
  };

  getValueJson = (attributeJM?: QueryAttributeJM) => {
    let retVal: ValueJson[] = [];
    if (isNotNullAndUndefined(attributeJM)) {
      if (isNotNullAndUndefined(attributeJM.ValueJson)) {
        retVal.push(...attributeJM.ValueJson);
      }
    }
    return retVal;
  };

  getInplaceEditor = (type?: string, url?: string, name?: string) => {
    let editor;
    const dataSource: RAFDataManager = new RAFDataManager({
      adaptor: new RAFUrlAdaptor({
        Skip: 0,
        Take: Constants.DropdownFetchCount,
      }),
      crossDomain: true,
      url: isNotNullAndUndefined(url) ? Constants.baseAPIUrl + url : null,
      requestType: "POST",
      headers: [],
    });

    switch (type) {
      case RAFInputType.Text:
      case RAFDataType.Text:
        //case RAFDataType.AutoNumber:
        editor = (
          <InPlaceEditorComponent
            className="surface_neutral_base"
            mode="Inline"
            name={name}
            disabled={this.props.disabled}
            type="Text"
            validationRules={
              this.props.required ? { [name]: { required: [true, ""] } } : null
            }
            value={this.props.value}
            {...(isNotNullAndUndefined(this.props.value) &&
              IsNullOrWhiteSpace(this.props.value)
              ? {
                emptyText:
                  this.props.emptyText !== null
                    ? this.props.emptyText
                    : "Not set",
              }
              : {})}
            data-underline="false"
            actionSuccess={this.changed}
            actionOnBlur="Submit"
            showButtons={false}
          >
            <Inject services={[Rte]} />
          </InPlaceEditorComponent>
        );
        break;
      case RAFDataType.Multiline:
        editor = (
          <InPlaceEditorComponent
            className="surface_neutral_base custom_inline_editor"
            id="inplace_comment_editor"
            name={name}
            disabled={this.props.disabled}
            data-underline="false"
            mode="Inline"
            type="RTE"
            editableOn="EditIconClick"
            submitOnEnter={false}
            actionSuccess={this.changed}
            actionOnBlur="Submit"
            value={this.props.value}
            {...(isNotNullAndUndefined(this.props.value) &&
              IsNullOrWhiteSpace(this.props.value)
              ? {
                emptyText:
                  this.props.emptyText !== null
                    ? this.props.emptyText
                    : "Not set",
              }
              : {})}
            model={this.rteModel}
            popupSettings={this.popupSettings}
            saveButton={this.saveButton}
            cancelButton={this.cancelButton}
          >
            <Inject services={[Rte]} />
          </InPlaceEditorComponent>
        );
        break;
      case RAFInputType.Boolean:
      case RAFDataType.Boolean:
        editor = (
          <InPlaceEditorComponent
            mode="Inline"
            name={name}
            disabled={this.props.disabled}
            type="Text"
            validationRules={
              this.props.required ? { [name]: { required: [true, ""] } } : null
            }
            value={this.props.value ? "Yes" : "No"}
            emptyText="No"
            data-underline="false"
            actionSuccess={this.changed}
            actionOnBlur="Submit"
          />
        );
        break;
      case RAFInputType.DropDownList:
      case RAFDataType.Dropdown:
        editor = (
          <InPlaceEditorComponent
            mode="Inline"
            name={name}
            disabled={this.props.disabled}
            type="DropDownList"
            validationRules={
              this.props.required ? { [name]: { required: [true, ""] } } : null
            }
            model={{
              dataSource: this.state.dropdownDatasoure,
              fields: { text: "label", value: "value" },
            }}
            value={this.props.value}
            emptyText="Not set"
            data-underline="false"
            actionSuccess={this.changed}
            actionOnBlur="Submit"
          />
        );
        break;
      case RAFInputType.LookupDropdownList:
        let displayText = isNotEmptyArray(this.props.value)
          ? this.props.value.map((x) => x.Value)
          : "Not set";
        editor = (
          <InPlaceEditorComponent
            mode="Inline"
            name={name}
            disabled={this.props.disabled}
            type="DropDownList"
            validationRules={
              this.props.required ? { [name]: { required: [true, ""] } } : null
            }
            model={{
              dataSource: dataSource,
              fields: { text: "Value", value: "Value" },
              allowFiltering: true,
            }}
            value={displayText}
            emptyText="Not set"
            data-underline="false"
            actionSuccess={this.changed}
            actionOnBlur="Submit"
          />
        );
        break;
      case RAFDataType.Lookup:
        editor = (
          <InPlaceEditorComponent
            mode="Inline"
            name={name}
            disabled={this.props.disabled}
            type="DropDownList"
            validationRules={
              this.props.required ? { [name]: { required: [true, ""] } } : null
            }
            model={{
              dataSource: dataSource,
              fields: { text: "Value", value: "Value" },
              allowFiltering: true,
            }}
            value={this.props.value}
            emptyText="Not set"
            data-underline="false"
            actionSuccess={this.changed}
            actionOnBlur="Submit"
          />
        );
        break;
      case RAFInputType.AutoComplete:
        editor = (
          <InPlaceEditorComponent
            mode="Inline"
            name={name}
            disabled={this.props.disabled}
            type="AutoComplete"
            validationRules={
              this.props.required ? { [name]: { required: [true, ""] } } : null
            }
            model={{
              dataSource: dataSource,
              fields: { text: "Value", value: "UID" },
            }}
            value={this.props.value}
            emptyText="Not set"
            data-underline="false"
            actionSuccess={this.changed}
            actionOnBlur="Submit"
          />
        );
        break;
      case RAFInputType.Date:
      case RAFDataType.Date:
        editor = (
          <InPlaceEditorComponent
            mode="Inline"
            name={name}
            disabled={this.props.disabled}
            type="Date"
            validationRules={
              this.props.required ? { [name]: { required: [true, ""] } } : null
            }
            model={{ format: "dd/MM/yyyy" }}
            value={convertUTCDateToLocalTimezone(this.props.value)}
            emptyText=" Not set"
            data-underline="false"
            actionSuccess={this.changed}
            actionOnBlur="Submit"
          />
        );
        break;
      default:
        break;
    }
    return editor;
  };

  render() {
    this.changed = this.changed.bind(this);
    let labelClass = `form-label${isNotNullAndUndefined(this.props.labelClass) ? ` ${this.props.labelClass}` : ""
      }${this.props.required ? " required" : ""}`;

    let rowClassName = isNotNullAndUndefined(this.props.rowClassName)
      ? `${this.props.rowClassName} row`
      : RAFDefaultFieldClassName.rowClassName;


    let inputFieldClassName = isNotNullAndUndefined(this.props.valueClassName)
      ? this.props.valueClassName
      : 'col-12';

    return (
      <div
        className={
          isNotNullAndUndefined(this.props.formGroupClassName)
            ? this.props.formGroupClassName + " form-group"
            : "form-group"
        }
      >
        <div className={rowClassName}
        >
          <div
            className={`${isNotNullAndUndefined(this.props.labelClassName) && this.props.labelClassName !== ""
              ? this.props.labelClassName
              : "col-12"
              } d-flex form-label-container`}
          >
            {isNotNullAndUndefined(this.props.title) && (
              <div className="col-12">
                <label className={labelClass}>{this.props.title}</label>
              </div>
            )}

          </div>
          {/* {this.props.showLabel !== false && (
            <RAFFieldLabel
              field={this.props.fieldName}
              label={this.props.title}
              required={this.props.required}
              labelClassName={this.props.labelClassName}
              description={this.props.description}
              descriptionAsLabel={this.props.descriptionAsLabel}
            ></RAFFieldLabel>
          )} */}
          <div className={inputFieldClassName}>
            {this.getInplaceEditor(
              this.props.type,
              this.props.url,
              this.props.name
            )}
          </div>
        </div>
      </div>
      // <div className="row" key={this.state.detailFieldOuterDivKey}>
      //   {this.props.title !== null && this.props.title !== "" && (
      //     <div className={"col-12 editable-detailsField-label mb-1"}>
      //       <span className={"body_2"}>{this.props.title}</span>
      //     </div>
      //   )}
      //   <div className={"col-12"}>
      //     {this.getInplaceEditor(
      //       this.props.type,
      //       this.props.url,
      //       this.props.name
      //     )}
      //   </div>
      // </div>
    );
  }
}
export default RAFDetailFieldEditableCustom;

import { MenuComponent, MenuItemModel } from "@syncfusion/ej2-react-navigations";
import { ToolbarClickEventArgs } from "@syncfusion/ej2-react-richtexteditor";
import { Component } from 'react';
import { RolePermissionsContext } from "../../RAFAuthentication/RAFRolePermissionsContextProvider";
import Aux from '../../RAFComponents/hoc/Auxiliary/Auxiliary';
import { NavigateParamsProps } from "../../router";
import { IsSuperAdmin } from "../helpers/AppHelper";
import { hasPermission } from "../helpers/PermissionHelper";
import { deepEqual, isNotNullAndUndefined } from "../helpers/utils";
import { RolePermissionRow } from "../models/Common/RolePermissionRow";

export interface MenuComponentData {
    lgDropDownMenuItems: MenuItemModel[],
    smDropDownMenuItems: MenuItemModel[],
}

interface IProps {
    permissionsName?: string[];
    menuItems?: MenuItemModel[];
    smMenuItems?: MenuItemModel[];
    toolbarItemClicked?: (e: ToolbarClickEventArgs) => void,
    pageType?: string,
    cssClass?: string,
    moduleName?: string,
    btnMode?: 'IconBtn' | 'TextBtn';
}

interface IState {
    permissionsName: string[];
    lgMenuComponentKey: number,
    smMenuComponentKey: number,
    menuItems: MenuItemModel[];
    smMenuItems: MenuItemModel[];
}

class RAFMenuComponent extends Component<NavigateParamsProps & any, IState, IProps> {

    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            menuItems: isNotNullAndUndefined(this.props.menuItems) ? this.props.menuItems : null,
            smMenuItems: isNotNullAndUndefined(this.props.smMenuItems) ? this.props.smMenuItems : null,
            permissionsName: isNotNullAndUndefined(this.props.permissionsName) ? this.props.permissionsName : [],
            lgMenuComponentKey: Math.random(),
            smMenuComponentKey: Math.random(),
        };
    }

    componentDidMount = () => {
        this._isMounted = true;
    };

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidUpdate = (prevProps) => {
        if (!deepEqual(prevProps.permissionsName, this.props.permissionsName)) {
            if (this._isMounted) {
                this.setState({ lgMenuComponentKey: Math.random(), smMenuComponentKey: Math.random(), permissionsName: isNotNullAndUndefined(this.props.permissionsName) ? this.props.permissionsName : [] });
            }
        }
        if (!deepEqual(prevProps.menuItems, this.props.menuItems)) {
            if (this._isMounted) {
                this.setState({ lgMenuComponentKey: Math.random(), smMenuComponentKey: Math.random(), menuItems: isNotNullAndUndefined(this.props.menuItems) ? this.props.menuItems : [] });
            }
        }
        if (!deepEqual(prevProps.smMenuItems, this.props.smMenuItems)) {
            if (this._isMounted) {
                this.setState({ lgMenuComponentKey: Math.random(), smMenuComponentKey: Math.random(), smMenuItems: isNotNullAndUndefined(this.props.smMenuItems) ? this.props.smMenuItems : [] });
            }
        }
    };

    toolbarItemClicked = (e: ToolbarClickEventArgs) => {
        if (this.props.toolbarItemClicked) {
            this.props.toolbarItemClicked(e);
        }
    };

    hideMenuComponetItems = (rolePermissionRow?: RolePermissionRow) => {
        const { menuItems, smMenuItems, permissionsName } = this.state;
        if (isNotNullAndUndefined(rolePermissionRow) && isNotNullAndUndefined(permissionsName)) {
            let menuComponentData: MenuComponentData = { lgDropDownMenuItems: null, smDropDownMenuItems: null };
            let hideItems = [];

            permissionsName && permissionsName.map(item => {
                if (!hasPermission(rolePermissionRow, item)) {
                    let splits = item.split('::');
                    if (splits[1] === 'Update') {
                        if (!hideItems.includes('Edit')) {
                            if (isNotNullAndUndefined(this.props.pageType) && this.props.pageType === "documentGridPage") {
                                hideItems.push('Upload New Version');
                            }
                            hideItems.push('Edit');
                        }
                    }

                    else if (splits[1] === 'Create') {
                        if (!hideItems.includes('Create')) {
                            if (isNotNullAndUndefined(this.props.pageType) && this.props.pageType === "documentGridPage") {
                                hideItems.push('Upload New Version');
                            }
                            hideItems.push('Create');
                        }
                    } else {
                        if (!hideItems.includes(splits[1])) {
                            hideItems.push(splits[1]);
                        }
                    }
                }
            });

            if (isNotNullAndUndefined(menuItems) && menuItems.length > 0 && isNotNullAndUndefined(menuItems[0].items)) {
                hideItems.forEach(hideItem => {
                    menuItems[0].items = menuItems[0].items.filter(x => x.text !== hideItem);
                });
            }
            if (isNotNullAndUndefined(smMenuItems) && smMenuItems.length > 0 && isNotNullAndUndefined(smMenuItems[0].items)) {
                hideItems.forEach(hideItem => {
                    smMenuItems[0].items = smMenuItems[0].items.filter(x => x.text !== hideItem);
                });
            }

            menuComponentData.lgDropDownMenuItems = menuItems;
            menuComponentData.smDropDownMenuItems = smMenuItems;

            return (menuComponentData);
        } else if (IsSuperAdmin()) {
            const menuComponentData: MenuComponentData = { lgDropDownMenuItems: menuItems, smDropDownMenuItems: smMenuItems };
            return (menuComponentData);
        } else {
            let menuComponentData: MenuComponentData = { lgDropDownMenuItems: null, smDropDownMenuItems: null };
            return (menuComponentData);
        }
    };

    getMenuComponentItemLength = (menuData: MenuItemModel[]) => {
        let length = 0;
        if (isNotNullAndUndefined(menuData) && menuData.length > 0 && isNotNullAndUndefined(menuData[0]) && isNotNullAndUndefined(menuData[0].items)) {
            length = menuData[0].items.length;
        }
        return length;
    };

    textBtnContent = (menuComponentData) => {
        return (
            <MenuComponent
                key={this.state.lgMenuComponentKey}
                showItemOnClick
                items={menuComponentData.lgDropDownMenuItems}
                select={this.toolbarItemClicked.bind(this)}
                // id="listPageMoreMenuBtn"
                id={`mec_moreAction_${this.props.moduleName}`}
                cssClass={this.props.cssClass}
            />
        );
    };

    iconBtnContent = (menuComponentData) => {
        return (
            <MenuComponent
                key={this.state.smMenuComponentKey}
                cssClass={this.props.cssClass + " raf-iconbtn"}
                showItemOnClick
                items={menuComponentData.smDropDownMenuItems}
                select={this.toolbarItemClicked.bind(this)}
                id={`mec_mob_moreAction_${this.props.moduleName}`}
            // id="listPageMoreMenuIconBtn"
            />
        );
    };

    BtnContent = (menuComponentData, menuItemsLength: number, smMenuItemsLength: number) => {
        const { pageType } = this.props;
        if (this.props.btnMode === 'TextBtn') {
            return (
                this.textBtnContent(menuComponentData)
            );
        }
        else if (this.props.btnMode === 'IconBtn') {
            return (
                this.iconBtnContent(menuComponentData)
            );
        }
        else {
            return (
                <div>
                    {menuItemsLength > 0 && pageType !== 'documentGridPage' &&
                        <div className="d-none d-sm-flex align-items-center">
                            {this.textBtnContent(menuComponentData)}
                        </div>
                    }
                    {smMenuItemsLength > 0 &&
                        <div className={pageType === 'listGridPage' ? "d-sm-none d-flex align-items-center" : pageType === 'documentGridPage' ? "d-flex align-items-center" : "d-sm-none d-flex align-items-center"}>
                            {this.iconBtnContent(menuComponentData)}
                        </div>
                    }
                </div>
            );
        }
    };

    render() {
        return (
            <Aux>
                <RolePermissionsContext.Consumer>
                    {({ permissionValue }) => {
                        const menuData: MenuComponentData = this.hideMenuComponetItems(permissionValue);
                        const menuComponentData: MenuComponentData = { lgDropDownMenuItems: menuData.lgDropDownMenuItems, smDropDownMenuItems: menuData.smDropDownMenuItems };
                        const menuItemsLength = isNotNullAndUndefined(menuComponentData) ? this.getMenuComponentItemLength(menuComponentData.lgDropDownMenuItems) : 0;
                        const smMenuItemsLength = isNotNullAndUndefined(menuComponentData) ? this.getMenuComponentItemLength(menuComponentData.smDropDownMenuItems) : 0;
                        return (
                            this.BtnContent(menuComponentData, menuItemsLength, smMenuItemsLength)
                        );
                    }}
                </RolePermissionsContext.Consumer>
            </Aux>
        );
    }
}

export default RAFMenuComponent;

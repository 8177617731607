import {
  ChipDirective,
  ChipListComponent,
  ChipsDirective,
} from "@syncfusion/ej2-react-buttons";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { ListViewComponent } from "@syncfusion/ej2-react-lists";
import { DropDownButtonComponent } from "@syncfusion/ej2-react-splitbuttons";
import DOMPurify from "dompurify";
import moment from "moment";
import React, {
  PropsWithChildren,
  ReactNode,
  Reducer,
  useEffect,
  useReducer,
  useRef,
} from "react";
import {
  RAFButtonConstant,
  RAFInputType,
} from "../../constants/Common/Constants";
import { RAFDetailFieldFormat } from "../Inputs/RFFUtils";
import { getQueryAttribute } from "../helpers/AppHelper";
import {
  Guid,
  IsNotNullOrWhiteSpace,
  convertUTCDateToLocalTimezone,
  currencyFormatting,
  hexToRGBA,
  isDate,
  isNotEmptyArray,
  isNotNullAndUndefined,
  isNullOrUndefined,
} from "../helpers/utils";
import { QueryAttributeJM, ValueJson } from "../models/Common/QueryAttributeJM";
import { RAFDataType, RAFUIType } from "../models/Common/RAFDataType";
import "./NavigationStyle.scss";
//import ReactHtmlParser from "react-html-parser";
//import { sanitize } from "dompurify"

interface IProps {
  value?: any;
  displayValue?: any;
  title?: string;
  labelDivClassName?: string;
  valueDivClassName?: string;
  customValue?: React.ReactFragment | ReactNode;
  labelClassName?: string;
  valueClassName?: string;
  rowClassName?: string;
  field?: string;
  fieldFormat?: RAFDetailFieldFormat;
  emptyString?: string;
  isSanitized?: boolean;
  colClassName?: string;
  moduleName?: string;
  isColorOption?: boolean;
  mode?:
    | "ringView"
    | "discView"
    | "squareView"
    | "iconView"
    | "textOnly"
    | "outlineView"
    | "opacityColorView";
  iconName?: string;
  labelIconName?: string;
  showLabel?: boolean;
  enableChipArray?: {
    visible: boolean;
    uiType?: "showFirstItem" | "Badge" | "default";
    rowClass?: string;
  };
  uiType?: RAFUIType.SmileyToggle;
  customRightSectionContent?: React.ReactFragment | ReactNode;
  labelRightContent?: React.ReactFragment | ReactNode;
}

interface IState {
  dropdownDatasoure: string[];
  iconName: string;
  colorCode: string;
  displayName: string;
  detailFieldOuterDivKey: number;
}

const RAFDetailFieldCustom = ({
  value,
  title,
  labelClassName,
  valueClassName,
  customValue,
  fieldFormat,
  ...props
}: PropsWithChildren<IProps>) => {
  const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      dropdownDatasoure: [],
      iconName: "",
      colorCode: "",
      displayName: "",
      detailFieldOuterDivKey: Math.random(),
    }
  );
  let listViewComponent = useRef<ListViewComponent>(null);
  let dropDownListComponent = useRef<DropDownListComponent>(null);
  let fields = { text: "Value", tooltip: "Value", id: "UID" };
  let itemId = Guid.newGuid();

  useEffect(() => {
    getColorCode();
    if (
      isNotNullAndUndefined(props.uiType) &&
      props.uiType === RAFUIType.SmileyToggle
    ) {
      getSmileyIcon();
    }
  }, [value]);

  const getColorCode = () => {
    getQueryAttribute(props.moduleName, props.field)
      .then((queryAttribute) => {
        if (
          isNotNullAndUndefined(queryAttribute) &&
          queryAttribute.DataType === RAFDataType.Dropdown &&
          props.isColorOption === true
        ) {
          let items = getDataFromChildren(queryAttribute);
          let objRow =
            isNotNullAndUndefined(items) && items.length > 0
              ? items.find((x) => x.DisplayName === value)
              : null;
          let dataSource = [];
          let dropdownItems = getValueJson(queryAttribute);
          if (isNotNullAndUndefined(dropdownItems)) {
            dropdownItems.forEach((item) => {
              dataSource.push({
                label: item.DisplayName,
                value: item.DisplayName,
              });
            });
          }

          if (isNotNullAndUndefined(objRow)) {
            setState({
              colorCode: objRow.ColorCode,
              displayName: objRow.DisplayName,
              dropdownDatasoure: dataSource,
            });
          } else {
            setState({
              colorCode: null,
              displayName: isNotNullAndUndefined(value) ? value : "Not set",
              dropdownDatasoure: dataSource,
            });
          }
        } else if (
          isNotNullAndUndefined(queryAttribute) &&
          queryAttribute.DataType === RAFDataType.Dropdown
        ) {
          let items = getValueJson(queryAttribute);
          let objRow =
            isNotNullAndUndefined(items) && items.length > 0
              ? items.find((x) => x.Name === value || x.DisplayName === value)
              : null;
          let dataSource = [];
          items.forEach((item) => {
            dataSource.push({
              label: item.DisplayName,
              value: item.DisplayName,
            });
          });
          setState({
            detailFieldOuterDivKey: Math.random(),
            dropdownDatasoure: dataSource,
            displayName: isNotNullAndUndefined(objRow.DisplayName)
              ? objRow.DisplayName
              : "Not set",
          });
        } else if (
          isNotNullAndUndefined(queryAttribute) &&
          queryAttribute.DataType === RAFDataType.Boolean
        ) {
          setState({
            displayName:
              isNotNullAndUndefined(value) && value === true ? "Yes" : "No",
          });
        } else {
          setState({ detailFieldOuterDivKey: Math.random() });
        }
      })
      .catch((error) => error);
  };
  const getSmileyIcon = () => {
    getQueryAttribute(props.moduleName, props.field)
      .then((queryAttribute) => {
        if (
          isNotNullAndUndefined(queryAttribute) &&
          queryAttribute.DataType === RAFDataType.Dropdown &&
          (isNullOrUndefined(fieldFormat) ||
            fieldFormat.format !== RAFInputType.LookupDropdownList)
        ) {
          let items = getDataFromChildren(queryAttribute);
          let objRow =
            isNotNullAndUndefined(items) && items.length > 0
              ? items.find((x) => x.DisplayName === value || x.Name === value)
              : null;

          let dataSource = [];
          let dropdownItems = getValueJson(queryAttribute);
          if (isNotNullAndUndefined(dropdownItems)) {
            dropdownItems.forEach((item) => {
              dataSource.push({
                label: item.DisplayName,
                value: item.DisplayName,
              });
            });
          }
          if (isNotNullAndUndefined(objRow)) {
            setState({
              iconName: objRow.Icon,
              colorCode: objRow.ColorCode,
              displayName: objRow.DisplayName,
              dropdownDatasoure: dataSource,
            });
          } else {
            setState({
              iconName: "",
              colorCode: null,
              displayName: isNotNullAndUndefined(value) ? value : "Not set",
              dropdownDatasoure: dataSource,
            });
          }
        } else if (
          isNotNullAndUndefined(queryAttribute) &&
          queryAttribute.DataType === RAFDataType.Dropdown &&
          (isNullOrUndefined(fieldFormat) ||
            fieldFormat.format !== RAFInputType.LookupDropdownList)
        ) {
          let items = getValueJson(queryAttribute);
          let dataSource = [];
          items.forEach((item) => {
            dataSource.push({
              label: item.DisplayName,
              value: item.DisplayName,
            });
          });
          setState({
            detailFieldOuterDivKey: Math.random(),
            dropdownDatasoure: dataSource,
            displayName: isNotNullAndUndefined(value) ? value : "Not set",
          });
        } else {
          setState({ detailFieldOuterDivKey: Math.random() });
        }
      })
      .catch((error) => error);
  };
  const getDataFromChildren = (attributeJM?: QueryAttributeJM) => {
    let retVal: ValueJson[] = [];
    if (isNotNullAndUndefined(attributeJM)) {
      if (isNotNullAndUndefined(attributeJM.ValueJson)) {
        retVal.push(...attributeJM.ValueJson);
      }
    }
    return retVal;
  };
  const getValueJson = (attributeJM?: QueryAttributeJM) => {
    let retVal: ValueJson[] = [];
    if (isNotNullAndUndefined(attributeJM)) {
      if (isNotNullAndUndefined(attributeJM.ValueJson)) {
        retVal.push(...attributeJM.ValueJson);
      }
    }
    return retVal;
  };
  if (
    isNotNullAndUndefined(fieldFormat) &&
    IsNotNullOrWhiteSpace(value) &&
    (fieldFormat.type === RAFDataType.Date ||
      fieldFormat.type === RAFDataType.DateTime)
  ) {
    const dateValue =
      typeof value === "string"
        ? value.toLowerCase() === "notset"
          ? null
          : new Date(value)
        : value;
    if (
      isNotNullAndUndefined(fieldFormat.type) &&
      isDate(dateValue) &&
      dateValue.toString() !== "Invalid Date"
    ) {
      if (fieldFormat.type === RAFDataType.Date) {
        // if (moment(inputValue, moment.ISO_8601, true).isValid()) {
        //   inputValue = moment(new Date(inputValue + "Z")).format(
        //     fieldFormat.format
        //   );
        // }
        const dateFormat = isNotNullAndUndefined(fieldFormat.format)
          ? fieldFormat.format
          : "DD/MM/YYYY";
        let localDate = convertUTCDateToLocalTimezone(value);

        value = moment(localDate).format(dateFormat);
      }
    } else {
      value = "Not set";
    }
  }
  if (
    isNotNullAndUndefined(fieldFormat) &&
    fieldFormat.type === RAFDataType.Dropdown &&
    fieldFormat.format === RAFInputType.LookupDropdownList
  ) {
    if (isNotEmptyArray(value) && IsNotNullOrWhiteSpace(value)) {
      let objValue = [];
      value.map((x) => {
        objValue.push(x.Value);
      });
      //value = joinStringArray(objValue, `, `);
      value = objValue.join(", ");
    } else {
      value = "Not set";
    }
  }

  // if (props.uiType === RAFUIType.SmileyToggle) {
  // }
  return (
    <>
      <div className={`${props.colClassName ? props.colClassName : ""}`}>
        <div
          className={`${props.rowClassName ? props.rowClassName : "g-0"} row`}
        >
          {props.showLabel !== false &&
            (isNotNullAndUndefined(props.labelIconName) ? (
              <div
                className={
                  isNotNullAndUndefined(props.labelDivClassName)
                    ? props.labelDivClassName
                    : "col-auto"
                }
              >
                <span
                  className={`${
                    isNotNullAndUndefined(labelClassName)
                      ? labelClassName
                      : "details-label"
                  }`}
                >
                  <i className={props.labelIconName}></i>
                </span>
              </div>
            ) : (
              <div
                className={
                  isNotNullAndUndefined(props.labelDivClassName)
                    ? props.labelDivClassName
                    : "col-12"
                }
                style={{
                  display:
                    isNotNullAndUndefined(title) && title !== "" ? "" : "none",
                  lineHeight: "normal",
                }}
              >
                <div className="d-flex align-items-center">
                  <span
                    className={`${
                      isNotNullAndUndefined(labelClassName)
                        ? labelClassName
                        : "details-label"
                    }`}
                  >
                    {title}
                  </span>
                  {isNotNullAndUndefined(props.labelRightContent)
                    ? props.labelRightContent
                    : ""}
                </div>
              </div>
            ))}
          {isNotNullAndUndefined(customValue) ? (
            customValue
          ) : (
            <div
              className={
                isNotNullAndUndefined(props.valueDivClassName)
                  ? props.valueDivClassName
                  : "col-12"
              }
              style={{ lineHeight: "normal" }}
            >
              {props.isColorOption ? (
                isNotNullAndUndefined(props.mode) &&
                props.mode !== "outlineView" &&
                props.mode !== "opacityColorView" &&
                props.mode !== "textOnly" ? (
                  <div className="d-flex align-items-center justify-content-start colorIcon">
                    {props.mode === "squareView" ? (
                      <i
                        className="fas fa-square fa-xxsm"
                        style={{
                          color:
                            props.isColorOption === true &&
                            isNotNullAndUndefined(value) &&
                            value !== "None"
                              ? state.colorCode
                              : "#333",
                        }}
                      ></i>
                    ) : props.mode === "ringView" ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="8"
                        height="8"
                        viewBox="0 0 8 8"
                        fill="none"
                      >
                        <circle
                          cx="4"
                          cy="4"
                          r="4"
                          fill={
                            props.isColorOption === true &&
                            isNotNullAndUndefined(value) &&
                            value !== "None" &&
                            isNotNullAndUndefined(state.colorCode)
                              ? hexToRGBA(state.colorCode, 0.4)
                              : hexToRGBA("#333", 0.4)
                          }
                        />
                        <circle
                          cx="4"
                          cy="4"
                          r="2"
                          fill={
                            props.isColorOption === true &&
                            isNotNullAndUndefined(value) &&
                            value !== "None" &&
                            isNotNullAndUndefined(state.colorCode)
                              ? state.colorCode
                              : hexToRGBA("#333", 0.4)
                          }
                        />
                      </svg>
                    ) : props.mode === "iconView" ? (
                      <i
                        className={props.iconName}
                        style={{
                          color:
                            props.isColorOption === true &&
                            isNotNullAndUndefined(value) &&
                            value !== "None"
                              ? state.colorCode
                              : "#333",
                        }}
                      ></i>
                    ) : (
                      <i
                        className="fas fa-circle fa-xxsm"
                        style={{
                          color:
                            props.isColorOption === true &&
                            isNotNullAndUndefined(value) &&
                            value !== "None"
                              ? state.colorCode
                              : "#333",
                        }}
                      ></i>
                    )}
                    <span
                      className={`${
                        IsNotNullOrWhiteSpace(valueClassName)
                          ? valueClassName
                          : IsNotNullOrWhiteSpace(props.mode)
                          ? "custom_key_value raf-icon-view"
                          : "custom_key_value"
                      }`}
                      {...(props.mode === "ringView"
                        ? { style: { color: state.colorCode } }
                        : {})}
                    >
                      {value !== null &&
                      value !== undefined &&
                      value.toString() !== "" &&
                      value !== "None"
                        ? String(
                            props.isColorOption === true
                              ? isNotNullAndUndefined(props.displayValue)
                                ? props.displayValue
                                : state.displayName
                              : isNotNullAndUndefined(props.displayValue)
                              ? props.displayValue
                              : value
                          )
                        : isNotNullAndUndefined(props.emptyString)
                        ? props.emptyString
                        : "Not set"}
                    </span>
                  </div>
                ) : (
                  <div className="d-flex justify-content-start custom_dropdown ">
                    <div
                      style={{
                        background:
                          props.mode === "outlineView" ||
                          props.mode === "opacityColorView"
                            ? hexToRGBA(state.colorCode, 0.1)
                            : props.mode === "textOnly"
                            ? "transparent"
                            : state.colorCode,
                        color: isNotNullAndUndefined(state.colorCode)
                          ? props.mode === "outlineView" ||
                            props.mode === "opacityColorView" ||
                            props.mode === "textOnly"
                            ? state.colorCode
                            : "white"
                          : null,
                        border:
                          props.mode === "outlineView"
                            ? `1px solid ${state.colorCode}`
                            : "none",
                      }}
                      className={`${
                        IsNotNullOrWhiteSpace(valueClassName)
                          ? `${valueClassName} `
                          : ""
                      }${
                        props.mode === "outlineView" ||
                        props.mode === "opacityColorView"
                          ? "raf-outline "
                          : ""
                      }${
                        props.mode === "textOnly" ? "p-0 bold " : ""
                      }custom_key_value`}
                      // "custom_key_value ecllipseFirstLine"
                    >
                      {isNotNullAndUndefined(props.displayValue)
                        ? props.displayValue
                        : state.displayName}
                    </div>
                  </div>
                )
              ) : isNotNullAndUndefined(props.enableChipArray) &&
                props.enableChipArray.visible === true ? (
                props.enableChipArray.uiType === "Badge" ? (
                  <div className="col-12">
                    <div
                      className={
                        isNotNullAndUndefined(props.enableChipArray.rowClass)
                          ? props.enableChipArray.rowClass
                          : "row gx-2 gy-1 flex-wrap"
                      }
                    >
                      {isNotNullAndUndefined(value) &&
                      isNotEmptyArray(value) ? (
                        value.map((item, index) => {
                          return (
                            <div className="col-auto" key={`${item}_${index}`}>
                              <div className="raf_badge raf_badge_card">
                                <span
                                  className={`${
                                    isNotNullAndUndefined(valueClassName)
                                      ? valueClassName
                                      : "details-value"
                                  }`}
                                >
                                  {props.field === "TagsListJson"
                                    ? item
                                    : item.Value}
                                </span>
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <div className="col-12">
                          <span
                            className={`${
                              isNotNullAndUndefined(valueClassName)
                                ? valueClassName
                                : "details-value"
                            }`}
                          >
                            NA
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="col-auto customChiplist">
                    {isNotNullAndUndefined(value) ? (
                      <div className="d-flex align-items-center">
                        <ChipListComponent
                          id="chip-avatar" //cssClass="e-outline"
                          enableDelete={false}
                        >
                          <ChipsDirective>
                            {value.map((item, index) => {
                              if (
                                props.enableChipArray.uiType ===
                                  "showFirstItem" &&
                                index === 0
                              ) {
                                return (
                                  <ChipDirective
                                    key={index}
                                    text={item.Value}
                                  ></ChipDirective>
                                );
                              } else if (
                                props.enableChipArray.uiType ===
                                  "showFirstItem" &&
                                index !== 0
                              ) {
                                return;
                              } else {
                                return (
                                  <ChipDirective
                                    key={index}
                                    text={item}
                                  ></ChipDirective>
                                );
                              }
                            })}
                          </ChipsDirective>
                        </ChipListComponent>
                        {isNotNullAndUndefined(value) && value.length > 1 && (
                          <div className="noRowclick">
                            <div id={`drodownBtnComponent_items_${itemId}`}>
                              <ListViewComponent
                                id={`drodownBtnComponent_items_${itemId}_list`}
                                // ref={listViewComponent}
                                dataSource={value}
                                fields={fields}
                              ></ListViewComponent>
                            </div>
                            <div>
                              <DropDownButtonComponent
                                id={`listdropdownBtn_${itemId}`}
                                target={`#drodownBtnComponent_items_${itemId}`}
                                cssClass={
                                  "link-button e-caret-hide badge-button ms-2"
                                }
                                iconCss={
                                  RAFButtonConstant.MoreHorizontal.IconCss
                                }
                              ></DropDownButtonComponent>
                            </div>
                          </div>
                        )}
                      </div>
                    ) : (
                      <span
                        className={`${
                          isNotNullAndUndefined(valueClassName)
                            ? valueClassName
                            : "details-value"
                        }`}
                      >
                        {isNotNullAndUndefined(props.emptyString)
                          ? props.emptyString
                          : "Not set"}
                      </span>
                    )}
                  </div>
                )
              ) : isNotNullAndUndefined(props.uiType) &&
                props.uiType === RAFUIType.SmileyToggle ? (
                <div className="d-flex align-items-center">
                  {isNotNullAndUndefined(state.iconName) &&
                  state.iconName !== "" ? (
                    <i className={`${state.iconName} pe-1`}></i>
                  ) : (
                    ""
                  )}
                  <span
                    className={`${
                      isNotNullAndUndefined(valueClassName)
                        ? valueClassName
                        : "details-value"
                    }`}
                  >
                    {value !== null &&
                    value !== undefined &&
                    value.toString() !== "" &&
                    value !== "None"
                      ? String(
                          isNotNullAndUndefined(props.uiType) &&
                            props.uiType === RAFUIType.SmileyToggle
                            ? isNotNullAndUndefined(props.displayValue)
                              ? props.displayValue
                              : state.displayName
                            : isNotNullAndUndefined(props.displayValue)
                            ? props.displayValue
                            : value
                        )
                      : isNotNullAndUndefined(props.emptyString)
                      ? props.emptyString
                      : "Not set"}
                  </span>
                </div>
              ) : isNotNullAndUndefined(fieldFormat) &&
                isNotNullAndUndefined(fieldFormat.type) &&
                fieldFormat.type === RAFDataType.Currency ? (
                <span
                  className={`${
                    isNotNullAndUndefined(valueClassName)
                      ? valueClassName
                      : "details-value"
                  }`}
                >
                  {currencyFormatting(value, "USD")}
                </span>
              ) : (
                <span
                  className={`${
                    isNotNullAndUndefined(valueClassName)
                      ? valueClassName
                      : "details-value"
                  }`}
                >
                  {isNotNullAndUndefined(props.displayValue) ? (
                    props.displayValue
                  ) : IsNotNullOrWhiteSpace(state.displayName) ? (
                    state.displayName
                  ) : isNotNullAndUndefined(value) ? (
                    <span
                      dangerouslySetInnerHTML={{
                        __html:
                          isNotNullAndUndefined(value) &&
                          IsNotNullOrWhiteSpace(value)
                            ? DOMPurify.sanitize(value)
                            : "Not set",
                      }}
                    ></span>
                  ) : isNotNullAndUndefined(props.emptyString) ? (
                    props.emptyString
                  ) : (
                    "Not set"
                  )}
                </span>
              )}
            </div>
          )}
        </div>
      </div>
      {isNotNullAndUndefined(props.customRightSectionContent) &&
        props.customRightSectionContent}
    </>
  );
};
export default React.memo(RAFDetailFieldCustom);

import React from "react";
import { PropsWithChildren } from "react";
import { RAFDataType } from "../../RAFComponents/models/Common/RAFDataType";
import {
  RAFAggregeateFunction,
  RAFDateFunction,
} from "../../constants/Common/Constants";
import RAFChoiceOption from "../Inputs/RAFChoiceOption";
import RAFDropdownCC from "../Inputs/RAFDropdownCC";
import { QueryAttributeJM } from "../models/Common/QueryAttributeJM";

//const RAFTextBox = ({ field, label, required }) => {

interface IProps {
  field?: string;
  label?: string;
  showLabel?: boolean;
  placeholder?: string;
  required?: boolean;
  allowFiltering?: boolean;
  selectedField?: string;
  selectedFieldsList?: QueryAttributeJM[];
  onChanged?: (label, value) => void;
}

function RAFExpressionChooser({
  field,
  label,
  placeholder,
  required,
  showLabel,
  selectedField,
  selectedFieldsList,
  allowFiltering,
  onChanged,
}: PropsWithChildren<IProps>) {
  const getExpressionByDataType = (selectedFields: QueryAttributeJM[]) => {
    let selectField =
      selectedFields &&
      selectedFields.find((x) => x.PropertyName === selectedField);
    let expressionDropdown;
    if (
      selectField &&
      (selectField.DataType === RAFDataType.Date ||
        selectField.DataType === RAFDataType.DateTime)
    ) {
      expressionDropdown = (
        <RAFDropdownCC
          field={field}
          label={label}
          placeholder={placeholder}
          required={required}
          showLabel={showLabel}
          allowFiltering={allowFiltering}
          onChanged={(label, value) => {
            onChanged(label, value);
          }}
          showClearButton={false}
        >
          <RAFChoiceOption label="Day" value={RAFDateFunction.Day} />
          <RAFChoiceOption label="Day Name" value={RAFDateFunction.DayName} />
          <RAFChoiceOption label="Week" value={RAFDateFunction.Week} />
          <RAFChoiceOption label="Month" value={RAFDateFunction.Month} />
          <RAFChoiceOption
            label="Month Name"
            value={RAFDateFunction.MonthName}
          />
          <RAFChoiceOption
            label="Month Name Year"
            value={RAFDateFunction.MonthNameYear}
          />
          <RAFChoiceOption label="Quarter" value={RAFDateFunction.Quarter} />
          <RAFChoiceOption label="Year" value="Year" />
        </RAFDropdownCC>
      );
    } else if (
      selectField &&
      (selectField.DataType === RAFDataType.Number ||
        selectField.DataType === RAFDataType.Currency ||
        selectField.DataType === RAFDataType.Double)
    ) {
      expressionDropdown = (
        <RAFDropdownCC
          field={field}
          label={label}
          placeholder={placeholder}
          required={required}
          showLabel={showLabel}
          allowFiltering={allowFiltering}
          onChanged={(label, value) => {
            onChanged(label, value);
          }}
          showClearButton={false}
        >
          <RAFChoiceOption label="Sum" value={RAFAggregeateFunction.Sum} />
          <RAFChoiceOption label="Average" value={RAFAggregeateFunction.Avg} />
          <RAFChoiceOption
            label="Minimum Value"
            value={RAFAggregeateFunction.Min}
          />
          <RAFChoiceOption
            label="Maximum Value"
            value={RAFAggregeateFunction.Max}
          />
        </RAFDropdownCC>
      );
    } else {
      expressionDropdown = (
        <RAFDropdownCC
          field={field}
          label={label}
          placeholder={placeholder}
          required={required}
          showLabel={showLabel}
          allowFiltering={allowFiltering}
          onChanged={(label, value) => {
            onChanged(label, value);
          }}
          showClearButton={false}
        >
          <RAFChoiceOption label="Count" value={RAFAggregeateFunction.Count} />
        </RAFDropdownCC>
      );
    }
    return expressionDropdown;
  };

  return <div>{getExpressionByDataType(selectedFieldsList)}</div>;
}

export default RAFExpressionChooser;

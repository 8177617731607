import React, {
  PropsWithChildren,
  Reducer,
  useContext,
  useEffect,
  useReducer,
} from "react";
import { RAFLayout } from "../../../constants/Common/Constants";
import { NavigateParamsProps } from "../../../router";
import { RAFLayoutContext } from "../../Inputs/RAFForm";
import {
  IsNotNullOrWhiteSpace,
  isNotNullAndUndefined,
} from "../../helpers/utils";
import RAFButtonComponent from "../RAFButtonComponent";
import "./RAFCollapseWithPlusIcon.scss";

interface IProps {
  children: React.ReactNode;
  title?: string;
  layout?: RAFLayout;
  colClassName?: string;
  collapsePanelRowClass?: string;
  collapsePanelContentClassname?: string;
  collapsePanelHeaderClass?: string;
  collapsePanelHeaderSpanClass?: string;
  expandedStateIcon?: string;
  collapsedStateIcon?: string;
  toggleArrowIcon?: "Arrow" | "default";
  onExpandedSection?: (collapsed?: boolean) => void;
  headerFixedHeight?: boolean;
  customTitle?: React.ReactFragment | React.ReactNode;
  customButton?: React.ReactFragment | React.ReactNode;
  isCollapsed?: boolean;
  collapsible?: boolean;
  allowFullRowClick?: boolean;
  showSeparator?: boolean;
  loadChildOnExpand?: boolean;
  headerUIMode?:
  | "default"
  | "arrowNearTitle"
  | "arrowBeforeTitle"
  | "arrowButtonLeftAligned";
  badge?: { count: number; show: boolean; className?: string; };
  showHeader?: boolean;
}

interface IState {
  collapsed: boolean;
}

function RAFCollapseWithPlusIcon({
  allowFullRowClick = false,
  showSeparator = true,
  headerUIMode = "default",
  showHeader = true,
  collapsible = true,
  badge = { count: 0, show: false },
  navigate,
  ...props
}: PropsWithChildren<IProps & NavigateParamsProps>) {
  const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      collapsed: props.isCollapsed ? true : false,
    }
  );

  useEffect(() => {
    setState({ collapsed: props.isCollapsed ? true : false });
  }, [props.isCollapsed]);

  const rafLayoutContextValue = useContext(RAFLayoutContext);
  const propsLayout = isNotNullAndUndefined(props.layout)
    ? props.layout
    : isNotNullAndUndefined(rafLayoutContextValue)
      ? rafLayoutContextValue
      : RAFLayout.OneColumnLayout;

  const toggleCollapse = (collapsed) => {
    if (collapsible !== false) {
      setState({ collapsed: !collapsed });
      if (isNotNullAndUndefined(props.onExpandedSection)) {
        props.onExpandedSection(collapsed);
      }
    }
  };

  //Class name code start

  const collapsePanelHeaderClassname = `collapsePanel__header${props.headerFixedHeight === true
    ? ` collapsePanel__header__fixedHeight`
    : ""
    }${isNotNullAndUndefined(props.collapsePanelHeaderClass)
      ? ` ${props.collapsePanelHeaderClass}`
      : ""
    }${allowFullRowClick && collapsible ? ` pointer` : ""}`;
  const collapsePanelContentClassname = `collapsePanel__content${isNotNullAndUndefined(props.collapsePanelContentClassname)
    ? ` ${props.collapsePanelContentClassname}`
    : ""
    }${state.collapsed ? " collapse" : " collapse show"}`;
  const toggleBtnClassname = `collapsePanel__toggleBtn`;
  // let toggleBtnClassname = `collapsePanel__toggleBtn${isNotNullAndUndefined(props.headerFixedHeight) ? ` collapsePanel__toggleBtn__lg` : ''}`
  //Class name code end

  const defaultCollapsedIcon =
    props.toggleArrowIcon === "Arrow"
      ? "fas fa-chevron-down small-icon"
      : "fas fa-plus";
  const defaultExpandedIcon =
    props.toggleArrowIcon === "Arrow"
      ? "fas fa-chevron-up small-icon"
      : "fas fa-minus";

  let collapsedStateIcon = isNotNullAndUndefined(props.collapsedStateIcon)
    ? props.collapsedStateIcon
    : defaultCollapsedIcon;
  let expandedStateIcon = isNotNullAndUndefined(props.expandedStateIcon)
    ? props.expandedStateIcon
    : defaultExpandedIcon;

  if (allowFullRowClick === true) {
    collapsedStateIcon += " text-center fa-sm px-2";
    expandedStateIcon += " text-center fa-sm px-2";
  }

  const arrowContent = () => {
    if (collapsible !== false) {
      return (
        <div
          {...(allowFullRowClick === false && {
            onClick: (e) => e.stopPropagation(),
          })}
        >
          {allowFullRowClick === true ? (
            <i
              className={
                state.collapsed === true
                  ? collapsedStateIcon
                  : expandedStateIcon
              }
            ></i>
          ) : (
            <RAFButtonComponent
              iconCss={
                state.collapsed === true
                  ? collapsedStateIcon
                  : expandedStateIcon
              }
              onClick={() => toggleCollapse(state.collapsed)}
              type="button"
              className={toggleBtnClassname}
            />
          )}
        </div>
      );
    } else {
      return null;
    }
  };

  const titleContent = () => {
    let count = badge.count;
    let badgeVisible = badge.show;
    let badgeClassName = badge.className;
    let collapsePanelHeaderSpanClass = props.collapsePanelHeaderSpanClass;
    return isNotNullAndUndefined(props.customTitle) ? (
      props.customTitle
    ) : (
      <div className="d-flex align-items-center">
        <span
          className={
            IsNotNullOrWhiteSpace(collapsePanelHeaderSpanClass)
              ? collapsePanelHeaderSpanClass
              : "subtitle_1"
          }
        >
          {props.title}
        </span>
        {badgeVisible === true &&
          (isNotNullAndUndefined(count) && count > 0 ? (
            <span
              className={
                isNotNullAndUndefined(badgeClassName)
                  ? badgeClassName
                  : "ms-2 e-badge e-badge-primary e-badge-circle"
              }
            >
              {count}
            </span>
          ) : (
            <span className="ms-1 e-badge e-badge-circle e-badge-dark">0</span>
          ))}
      </div>
    );
  };

  const customButtonContent = () => {
    return (
      <div onClick={(e) => e.stopPropagation()} className="">
        {isNotNullAndUndefined(props.customButton) ? props.customButton : ""}
      </div>
    );
  };

  const headerContent = () => {
    if (headerUIMode === "default") {
      return (
        <div className="row gx-2 align-items-center flex-nowrap">
          <div className="col">{titleContent()}</div>
          <div className="col-auto">
            <div className="row gx-2 align-items-center">
              {isNotNullAndUndefined(props.customButton)
                ? customButtonContent()
                : null}
            </div>
          </div>
          <div className="col-auto">{arrowContent()}</div>
        </div>
      );
    } else if (
      headerUIMode === "arrowNearTitle" ||
      headerUIMode === "arrowButtonLeftAligned"
    ) {
      return (
        <div className="row gx-0 align-items-center flex-nowrap">
          <div className="col-auto">{titleContent()}</div>
          <div className="col-auto">{arrowContent()}</div>
          {isNotNullAndUndefined(props.customButton) && (
            <div
              className={
                headerUIMode === "arrowButtonLeftAligned"
                  ? "col-auto"
                  : "col-auto ms-auto"
              }
            >
              {customButtonContent()}
            </div>
          )}
        </div>
      );
    } else if (headerUIMode === "arrowBeforeTitle") {
      return (
        <div className="row gx-0 align-items-center flex-nowrap">
          <div className="col-auto">{arrowContent()}</div>
          <div className="col">{titleContent()}</div>
          {isNotNullAndUndefined(props.customButton) && (
            <div className="col-auto ms-auto">{customButtonContent()}</div>
          )}
        </div>
      );
    }
  };

  const getObjChilrenContent = () => {

    const objChildren = props.children
      ? React.Children.map(props.children, (child) => {
        if (isNotNullAndUndefined(child) && child !== "" && child !== " ") {
          return (
            <div
              className={`${props.colClassName ? props.colClassName : ""} 
      ${propsLayout === RAFLayout.FourColumnLayout
                  ? "col-md-3"
                  : propsLayout === RAFLayout.ThreeColumnLayout
                    ? "col-md-4"
                    : propsLayout === RAFLayout.TwoColumnLayout
                      ? "col-md-6"
                      : "col-md-12"
                }`}
            >
              {child}
            </div>
          );
        }
      })
      : null;
    return (
      <div className={collapsePanelContentClassname}>
        <div
          className={`row ${isNotNullAndUndefined(props.collapsePanelRowClass)
            ? props.collapsePanelRowClass
            : "g-3"
            }`}
        >
          {objChildren}
        </div>
      </div>
    );
  };

  return (
    <div className="collapsePanel">
      {showHeader === true && (
        <div
          className={collapsePanelHeaderClassname}
          {...(allowFullRowClick === true &&
            collapsible === true && {
            onClick: (e) => {
              e.stopPropagation();
              e.preventDefault();
              toggleCollapse(state.collapsed);
            },
          })}
        >
          <div className="w-100">{headerContent()}</div>
        </div>
      )}
      {showSeparator && showHeader && (
        <div
          className={`${state.collapsed ? "" : "collapsePanel__separator"}`}
        ></div>
      )}
      {props.loadChildOnExpand === true && state.collapsed
        ? null
        : getObjChilrenContent()}
    </div>
  );
}

export default React.memo(RAFCollapseWithPlusIcon);

import React, { ReactNode } from "react";
import { isNotNullAndUndefined } from "../helpers/utils";
import RAFDetailsField from "../Inputs/RAFDetailsField";
import { RAFDetailFieldFormat } from "../Inputs/RFFUtils";
import "./NavigationStyle.scss";
//import ReactHtmlParser from "react-html-parser";
//import { sanitize } from "dompurify"

const RAFDetailField = (props: {
  value?: any;
  title?: string;
  labelDivClassName?: string;
  valueDivClassName?: string;
  customValue?: React.ReactFragment | ReactNode;
  labelClassName?: string;
  valueClassName?: string;
  field?: string;
  fieldFormat?: RAFDetailFieldFormat;
  emptyString?: string;
  isSanitized?: boolean;
}) => {
  let value = props.value;
  let title = props.title;
  let labelClassName = props.labelClassName;
  let valueClassName = props.valueClassName;
  //let sanitizedHTML = sanitize(value);
  let customValue = props.customValue;
  return (
    <div className="g-0 w-100 row">
      <div
        className={
          isNotNullAndUndefined(props.labelDivClassName)
            ? props.labelDivClassName
            : "col-12"
        }
        style={{ display: isNotNullAndUndefined(title) && title !== "" ? "" : "none" }}
      >
        <small
          className={
            labelClassName !== null
              ? labelClassName + " m-0 labelSecondaryText"
              : "m-0 labelSecondaryText"
          }
        >
          {title}
        </small>
      </div>
      {isNotNullAndUndefined(customValue) ? (
        <div
          className={
            isNotNullAndUndefined(props.valueDivClassName)
              ? props.valueDivClassName + " py-1 px-2 detailsField-value-div"
              : "col-12 px-2 detailsField-value-div"
          }
        >
          {customValue}
        </div>
      ) : (
        <div
          className={
            isNotNullAndUndefined(props.valueDivClassName)
              ? props.valueDivClassName + " py-1 px-2 detailsField-value-div"
              : "col-12 px-2 detailsField-value-div"
          }
        >
          <span>
            {isNotNullAndUndefined(props.field) ? (
              <RAFDetailsField
                field={props.field}
                fieldFormat={props.fieldFormat}
                emptyString={props.emptyString}
                isSanitized={props.isSanitized}
                valueClassName={props.valueClassName}
              />
            ) : (
              /*<span className="m-0 detailsValue"> {(value !== null && value !== undefined && value.toString() !== '') ? ReactHtmlParser(sanitizedHTML) : isNotNullAndUndefined(props.emptyString) ? props.emptyString : */ /*'\u00A0' +*/ /* "Not set"}</span>*/
              <span
                className={
                  valueClassName !== null
                    ? valueClassName
                    : "detailsValue"
                }
              >
                {" "}
                {value !== null && value !== undefined && value.toString() !== ""
                  ? value
                  : isNotNullAndUndefined(props.emptyString)
                    ? props.emptyString
                    : /*'\u00A0' +*/ "Not set"}
              </span>
            )}
          </span>
        </div>
      )}
    </div>
  );
};
export default RAFDetailField;

import { InPlaceEditorComponent } from "@syncfusion/ej2-react-inplace-editor";
import React, { PropsWithChildren, ReactNode } from "react";
import { Constants, RAFInputType } from "../../constants/Common/Constants";
import {
  convertUTCDateToLocalTimezone,
  deepEqual,
  isNotEmptyArray,
  isNotNullAndUndefined,
} from "../../RAFComponents/helpers/utils";
import {
  RAFDataManager,
  RAFUrlAdaptor,
} from "../../RAFComponents/Inputs/RAFUrlAdaptor";
import { getQueryAttribute } from "../helpers/AppHelper";
import { QueryAttributeJM, ValueJson } from "../models/Common/QueryAttributeJM";
import { RAFDataType } from "../models/Common/RAFDataType";
import "./NavigationStyle.scss";

interface IProps {
  title?: string;
  emptyText?: string;
  value?: any;
  labelClassName?: string;
  onChange?: (e: any) => void;
  placeholder?: string;
  valueClassName?: string;
  type?: string;
  dataSoure?: any;
  url?: string;
  required?: boolean;
  name?: string;
  moduleName?: string;
  disabled?: boolean;
  isColorOption?: boolean;
  fieldName?: string;
  iconName?: string;
  emptyString?: string;
  customValue?: React.ReactFragment | ReactNode;
  mode?: "discView" | "squareView" | "iconView";
  columnCount?: number;
}
export class RAFDetailFieldEditable extends React.Component<PropsWithChildren<IProps>> {
  state = {
    dropdownDatasoure: [],
    colorCode: "",
    displayName: "",
    detailFieldOuterDivKey: Math.random(),
  };
  private _isMounted = false;
  handleChange = (e) => {
    this.props.onChange(e);
  };

  public changed(e: any): void {
    this.props.onChange(e);
  }

  componentDidMount = () => {
    this._isMounted = true;
    this.refresh();
  };

  componentDidUpdate(prevProps /*, prevState*/) {
    if (!deepEqual(prevProps.value, this.props.value)) {
      this.refresh();
    }
  }

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  refresh1() {
    this.getColorCode();
    let dataSource = [];
    if (this.props.type === RAFInputType.DropDownList) {
      getQueryAttribute(this.props.moduleName, this.props.fieldName)
        .then((queryAttribute) => {
          let items = this.getValueJson(queryAttribute);
          items.forEach((item) => {
            dataSource.push({
              label: item.DisplayName,
              value: item.DisplayName,
            });
          });
          this.setState({ dropdownDatasoure: dataSource });
        })
        .catch((error) => error);
    }
  }

  refresh() {
    this.getColorCode();
  }

  getColorCode = () => {
    getQueryAttribute(this.props.moduleName, this.props.fieldName)
      .then((queryAttribute) => {
        if (
          isNotNullAndUndefined(queryAttribute) &&
          (queryAttribute.DataType === RAFDataType.Dropdown) &&
          this.props.isColorOption === true
        ) {
          let items = this.getDataFromChildren(queryAttribute);
          let objRow =
            isNotNullAndUndefined(items) && items.length > 0
              ? items.find((x) => x.DisplayName === this.props.value)
              : null;
          let dataSource = [];
          let dropdownItems = this.getValueJson(queryAttribute);
          if (isNotNullAndUndefined(dropdownItems)) {
            dropdownItems.forEach((item) => {
              dataSource.push({
                label: item.DisplayName,
                value: item.DisplayName,
              });
            });
          }

          if (isNotNullAndUndefined(objRow)) {
            if (this._isMounted) {
              this.setState({
                detailFieldOuterDivKey: Math.random(),
                colorCode: objRow.ColorCode,
                displayName: objRow.DisplayName,
                dropdownDatasoure: dataSource,
              });
            }
          } else {
            if (this._isMounted) {
              this.setState({
                detailFieldOuterDivKey: Math.random(),
                colorCode: null,
                displayName: "Not set",
                dropdownDatasoure: dataSource,
              });
            }
          }
        } else if (
          isNotNullAndUndefined(queryAttribute) &&
          queryAttribute.DataType === RAFDataType.Dropdown
        ) {
          let items = this.getValueJson(queryAttribute);
          let dataSource = [];
          items.forEach((item) => {
            dataSource.push({
              label: item.DisplayName,
              value: item.DisplayName,
            });
          });
          this.setState({
            detailFieldOuterDivKey: Math.random(),
            dropdownDatasoure: dataSource,
          });
        } else {
          if (this._isMounted) {
            this.setState({ detailFieldOuterDivKey: Math.random() });
          }
        }
      })
      .catch((error) => error);
  };

  getDataFromChildren = (attributeJM?: QueryAttributeJM) => {
    let retVal: ValueJson[] = [];
    if (isNotNullAndUndefined(attributeJM)) {
      if (isNotNullAndUndefined(attributeJM.ValueJson)) {
        retVal.push(...attributeJM.ValueJson);
      }
    }
    return retVal;
  };

  getValueJson = (attributeJM?: QueryAttributeJM) => {
    let retVal: ValueJson[] = [];
    if (isNotNullAndUndefined(attributeJM)) {
      if (isNotNullAndUndefined(attributeJM.ValueJson)) {
        retVal.push(...attributeJM.ValueJson);
      }
    }
    return retVal;
  };

  getInplaceEditor = (type, url, name) => {
    let editor;
    const dataSource: RAFDataManager = new RAFDataManager({
      adaptor: new RAFUrlAdaptor({
        Skip: 0,
        Take: Constants.DropdownFetchCount,
      }),
      crossDomain: true,
      url: isNotNullAndUndefined(url) ? Constants.baseAPIUrl + url : null,
      requestType: "POST",
      headers: [],
    });

    switch (type) {
      case RAFInputType.Text:
      case RAFDataType.Text:
      case RAFDataType.AutoNumber:
        editor = (
          <InPlaceEditorComponent
            mode="Inline"
            name={name}
            disabled={this.props.disabled}
            type="Text"
            validationRules={
              this.props.required ? { [name]: { required: [true, ""] } } : null
            }
            value={
              isNotNullAndUndefined(this.props.value)
                ? this.props.value
                : isNotNullAndUndefined(this.props.emptyText)
                  ? this.props.emptyText
                  : "Not set"
            }
            emptyText={
              this.props.emptyText !== null ? this.props.emptyText : "Not set"
            }
            data-underline="false"
            actionSuccess={this.changed}
            actionOnBlur="Submit"
          />
        );
        break;
      case RAFInputType.Boolean:
      case RAFDataType.Boolean:
        editor = (
          <InPlaceEditorComponent
            mode="Inline"
            name={name}
            disabled={this.props.disabled}
            type="Text"
            validationRules={
              this.props.required ? { [name]: { required: [true, ""] } } : null
            }
            value={this.props.value ? "Yes" : "No"}
            emptyText="No"
            data-underline="false"
            actionSuccess={this.changed}
            actionOnBlur="Submit"
          />
        );
        break;
      case RAFInputType.DropDownList:
      case RAFDataType.Dropdown:
        editor = (
          <InPlaceEditorComponent
            mode="Inline"
            name={name}
            disabled={this.props.disabled}
            type="DropDownList"
            validationRules={
              this.props.required ? { [name]: { required: [true, ""] } } : null
            }
            model={{
              dataSource: this.state.dropdownDatasoure,
              fields: { text: "label", value: "value" },
            }}
            value={this.props.value}
            emptyText="Not set"
            data-underline="false"
            actionSuccess={this.changed}
            actionOnBlur="Submit"
          />
        );
        break;
      case RAFInputType.LookupDropdownList:
        let displayText = isNotEmptyArray(this.props.value) ? this.props.value.map(x => x.Value) : "Not set";
        editor = (
          <InPlaceEditorComponent
            mode="Inline"
            name={name}
            disabled={this.props.disabled}
            type="DropDownList"
            validationRules={
              this.props.required ? { [name]: { required: [true, ""] } } : null
            }
            model={{
              dataSource: dataSource,
              fields: { text: "Value", value: "Value" },
              allowFiltering: true,
            }}
            value={displayText}
            emptyText="Not set"
            data-underline="false"
            actionSuccess={this.changed}
            actionOnBlur="Submit"
          />
        );
        break;
      case RAFDataType.Lookup:
        editor = (
          <InPlaceEditorComponent
            mode="Inline"
            name={name}
            disabled={this.props.disabled}
            type="DropDownList"
            validationRules={
              this.props.required ? { [name]: { required: [true, ""] } } : null
            }
            model={{
              dataSource: dataSource,
              fields: { text: "Value", value: "Value" },
              allowFiltering: true,
            }}
            value={this.props.value}
            emptyText="Not set"
            data-underline="false"
            actionSuccess={this.changed}
            actionOnBlur="Submit"
          />
        );
        break;
      case RAFInputType.AutoComplete:
        editor = (
          <InPlaceEditorComponent
            mode="Inline"
            name={name}
            disabled={this.props.disabled}
            type="AutoComplete"
            validationRules={
              this.props.required ? { [name]: { required: [true, ""] } } : null
            }
            model={{
              dataSource: dataSource,
              fields: { text: "Value", value: "UID" },
            }}
            value={this.props.value}
            emptyText="Not set"
            data-underline="false"
            actionSuccess={this.changed}
            actionOnBlur="Submit"
          />
        );
        break;
      case RAFInputType.Date:
      case RAFDataType.Date:
        editor = (
          <InPlaceEditorComponent
            mode="Inline"
            name={name}
            disabled={this.props.disabled}
            type="Date"
            validationRules={
              this.props.required ? { [name]: { required: [true, ""] } } : null
            }
            model={{ format: "dd/MM/yyyy" }}
            value={convertUTCDateToLocalTimezone(this.props.value)}
            emptyText=" Not set"
            data-underline="false"
            actionSuccess={this.changed}
            actionOnBlur="Submit"
          />
        );
        break;
      default:
        break;
    }
    return editor;
  };

  render() {
    this.changed = this.changed.bind(this);
    return (
      <div
        className="row detailsFieldDiv g-0 h-100"
        key={this.state.detailFieldOuterDivKey}
      >
        <div
          className={
            this.props.columnCount && this.props.columnCount > 2
              ? "col-12 editable-detailsField-label"
              : "col-12 editable-detailsField-label"
          }
          style={{
            display:
              this.props.title !== null && this.props.title !== ""
                ? ""
                : "none",
          }}
        >
          <small
            className={
              isNotNullAndUndefined(this.props.labelClassName)
                ? this.props.labelClassName
                : "m-0 labelSecondaryText"
            }
          >
            {this.props.title}
          </small>
        </div>
        <div
          className={
            (this.props.title !== null && this.props.title !== "") ||
              (this.props.columnCount && this.props.columnCount < 3)
              ? "col-12 d-flex editable-detailsField-value"
              : "col-12 d-flex editable-detailsField-value"
          }
        >
          {isNotNullAndUndefined(this.props.customValue) ? (
            this.props.customValue
          ) : this.props.isColorOption ? (
            isNotNullAndUndefined(this.props.mode) ? (
              <div className="d-flex align-items-center colorIcon py-1">
                {this.props.mode === "squareView" ? (
                  <i
                    className="fas fa-square"
                    style={{
                      color:
                        this.props.isColorOption === true &&
                          isNotNullAndUndefined(this.props.value) &&
                          this.props.value !== "None"
                          ? this.state.colorCode
                          : "#333",
                    }}
                  ></i>
                ) : this.props.mode === "iconView" ? (
                  <i
                    className={this.props.iconName}
                    style={{
                      color:
                        this.props.isColorOption === true &&
                          isNotNullAndUndefined(this.props.value) &&
                          this.props.value !== "None"
                          ? this.state.colorCode
                          : "#333",
                    }}
                  ></i>
                ) : (
                  <i
                    className="fas fa-circle"
                    style={{
                      color:
                        this.props.isColorOption === true &&
                          isNotNullAndUndefined(this.props.value) &&
                          this.props.value !== "None"
                          ? this.state.colorCode
                          : "#333",
                    }}
                  ></i>
                )}
                <span
                  className={
                    this.props.isColorOption === true &&
                      isNotNullAndUndefined(this.state.colorCode)
                      ? "custom_key_value"
                      : "custom_key_value"
                  }
                >
                  {this.props.value !== null &&
                    this.props.value !== undefined &&
                    this.props.value.toString() !== "" &&
                    this.props.value !== "None"
                    ? String(
                      this.props.isColorOption === true
                        ? this.state.displayName
                        : this.props.value
                    )
                    : isNotNullAndUndefined(this.props.emptyString)
                      ? this.props.emptyString
                      : "Not set"}
                </span>
              </div>
            ) : (
              <div className="d-flex justify-content-center custom_dropdown py-1">
                <div
                  style={{
                    background: this.state.colorCode,
                    color: isNotNullAndUndefined(this.state.colorCode)
                      ? "white"
                      : null,
                  }}
                  className="custom_key_value"
                >
                  {this.state.displayName}
                </div>
              </div>
            )
          ) : (
            this.getInplaceEditor(
              this.props.type,
              this.props.url,
              this.props.name
            )
          )}
        </div>
      </div>
    );
  }
}
export default RAFDetailFieldEditable;

import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { PropsWithChildren, useContext } from "react";
import { FormRenderProps } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import RAFCheckBox from "../Inputs/RAFCheckBox";
import RAFChoiceOption from "../Inputs/RAFChoiceOption";
import RAFDropdownCC from "../Inputs/RAFDropdownCC";
import { RAFFieldProps, RAFFormContext } from "../Inputs/RFFUtils";
import { isNotNullAndUndefined } from "../helpers/utils";
import { QueryAttributeJM } from "../models/Common/QueryAttributeJM";

interface IProps {
    showAdd?: boolean;
    showRemove?: boolean;
    attributesRow?: QueryAttributeJM[];
}

function RAFSortBy<T>(
    { field, attributesRow,
        showAdd = false,
        showRemove = false,
    }: PropsWithChildren<RAFFieldProps<T> & IProps>,
) {
    const rafFormContextValue: FormRenderProps = useContext(RAFFormContext);
    const onAddClick = (field: keyof T | string) => {
        if (isNotNullAndUndefined(rafFormContextValue) && isNotNullAndUndefined(rafFormContextValue.form) && isNotNullAndUndefined(rafFormContextValue.form.mutators)) {
            rafFormContextValue.form.mutators.push(field.toString(), undefined);
        }
    };

    return (
        <div className="app-container p-0" id={"rafdiv" + field.toString()} >
            <div className="d-flex align-items-center sortbySection" style={{ width: '100%' }}>
                <div className="me-4 w-50">
                    <label className="form-label">Sort By </label>
                </div>
                <div className="w-25">
                    <label className="form-label">Descending </label>
                </div>
            </div>
            <div className="list">
                <FieldArray name={field.toString()}>
                    {({ fields }) =>
                        fields.map((name, index) => (
                            <div key={name}>
                                <div className="d-flex align-items-center" style={{ width: '100%' }}>
                                    <div className="me-4 w-50">
                                        <RAFDropdownCC field={`${name}.Field`} showLabel={false} label="Sort By" placeholder="Select Field" allowFiltering>
                                            {attributesRow.map(item => {
                                                return <RAFChoiceOption key={item.AttributeUID} label={item.DisplayName} value={item.PropertyName} />;
                                            })}
                                        </RAFDropdownCC>
                                    </div>
                                    <div className="w-25 d-flex align-items-center justify-content-between">
                                        <div>
                                            <RAFCheckBox field={`${name}.Descending`} showLabel={false} label="Descending" />
                                        </div>
                                        {showRemove &&
                                            <div className="" style={{ marginTop: '-10px' }}>
                                                <ButtonComponent type="button" iconCss="fas fa-xmark" onClick={() => fields.remove(index)} title="Click to remove" className="removeItem">
                                                </ButtonComponent>
                                            </div>
                                        }
                                    </div>

                                </div>
                            </div>
                        ))
                    }
                </FieldArray>
                {showAdd &&
                    <div>
                        <button type="button" className="link-button" onClick={() => onAddClick(field)}> Add Sortby Field </button>
                    </div>
                }
            </div>
        </div>
    );
}

export default RAFSortBy;


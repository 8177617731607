import { ButtonComponent, FabComponent } from "@syncfusion/ej2-react-buttons";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import React, {
  Fragment,
  PropsWithChildren,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";
import { RAFButtonConstant } from "../../constants/Common/Constants";
import {
  ConvertSystemName,
  IsNotNullOrWhiteSpace,
  IsNullOrWhiteSpace,
  isNotNullAndUndefined,
  isNullOrUndefined
} from "../helpers/utils";

//const RAFTextBox = ({ field, label, required }) => {

interface IProps {
  idString?: string;
  type?: "submit" | "reset" | "button";
  className?: string;
  action?: any;
  showIcon?: boolean;
  iconBtn?: boolean;
  isPrimary?: boolean;
  disabled?: boolean;
  iconPosition?: "right" | "left";
  onClick?: (args) => void;
  btnContent?: string;
  iconCss?: string;
  id?: string;
  // ref?: any;
  btnContentTemplate?: React.ReactNode;
  uiMode?: "FooterBtn" | "FooterOutlineBtn" | "FooterDashedOutlineBtn" | "default";
  displayMode?: "FabComponent" | 'DialogCloseBtn' | "default";
  enableToolTip?: boolean;
}

const RAFButtonComponent = forwardRef(function RAFButtonComponent(
  {
    type = "button",
    className,
    action,
    showIcon,
    iconBtn,
    isPrimary,
    idString,
    disabled,
    ...props
  }: PropsWithChildren<IProps>,
  ref
) {
  let buttonComponentRef = useRef<ButtonComponent>(null);

  showIcon =
    iconBtn === true
      ? true
      : isNotNullAndUndefined(showIcon)
        ? showIcon
        : isNotNullAndUndefined(action) &&
          (action.Id === RAFButtonConstant.Save.Id ||
            action.Id === RAFButtonConstant.Cancel.Id)
          ? false
          : true;

  function onClick(args) {
    if (isNotNullAndUndefined(props.onClick) && disabled !== true) {
      props.onClick(args);
    }
  }

  useImperativeHandle(ref, () => ({
    toggleBtnPermission(isDisabled) {
      if (
        isNotNullAndUndefined(buttonComponentRef) &&
        isNotNullAndUndefined(buttonComponentRef.current)
      ) {
        buttonComponentRef.current.disabled = isDisabled ?? false;
      }
    },
  }));

  if (props.uiMode) {
    if (props.uiMode === "FooterBtn") {
      className = `${IsNotNullOrWhiteSpace(className) ? `${className} ` : ''}btn-roundedCorner btn-max-width w-100`;
    }
    if (props.uiMode === "FooterOutlineBtn") {
      className = `${IsNotNullOrWhiteSpace(className) ? `${className} ` : ''}btn-roundedCorner btn-max-width w-100 e-outline`;
    }
    if (props.uiMode === "FooterDashedOutlineBtn") {
      className = `${IsNotNullOrWhiteSpace(className) ? `${className} ` : ''}btn-dashed btn-roundedCorner btn-max-width w-100 e-outline`;
    }
  }
  else if (props.displayMode === 'DialogCloseBtn') {
    className = `dialog_close_btn${isNotNullAndUndefined(className) ? ` ${className}` : ''}`;
  }

  const enableToolTip = isNotNullAndUndefined(props.enableToolTip) ? props.enableToolTip : iconBtn === true ? true : false;

  const iconCss = isNotNullAndUndefined(props.iconCss)
    ? props.iconCss
    : isNotNullAndUndefined(action)
      ? action.IconCss
      : null;

  const displayName = isNotNullAndUndefined(props.btnContent)
    ? props.btnContent
    : isNotNullAndUndefined(action)
      ? action.DisplayName
      : null;

  const id = isNotNullAndUndefined(props.id)
    ? props.id
    : isNotNullAndUndefined(action)
      ? action.Id
      : null;

  const btnKeyID =
    `btn_${IsNotNullOrWhiteSpace(id) ? id : ""}${iconBtn === true ? "_iconBtn" : ""
    }${IsNotNullOrWhiteSpace(idString) ? `_${idString}` :
      (IsNullOrWhiteSpace(id) && IsNullOrWhiteSpace(idString)) ?
        IsNotNullOrWhiteSpace(props.btnContent) ? ConvertSystemName(props.btnContent) : "_default" : ""}`;

  if (props.displayMode === "FabComponent") {
    return (
      <FabComponent
        type={type}
        key={btnKeyID}
        id={btnKeyID}
        {...((isNotNullAndUndefined(showIcon) && showIcon === false) ||
          isNullOrUndefined(iconCss)
          ? {}
          : {
            iconCss: iconCss,
          })}
        {...((isNotNullAndUndefined(iconBtn) && iconBtn === true) ||
          isNullOrUndefined(displayName) ||
          isNotNullAndUndefined(props.btnContentTemplate)
          ? {}
          : {
            content: displayName,
          })}
        {...(isNotNullAndUndefined(isPrimary) && isPrimary === true
          ? {
            isPrimary,
          }
          : {})}
        // {...( disabled === true
        //   ? {
        //       disabled:disabled,
        //     }
        //   : {})}
        disabled={
          disabled === true ? true : false
        }
        iconPosition={props.iconPosition}
        onClick={onClick}
      ></FabComponent>
    );
  }
  else if (props.displayMode === "DialogCloseBtn") {
    return (
      <div className={className}
        {...IsNotNullOrWhiteSpace(onClick) ? { onClick: onClick } : {}}
      >
        {IsNotNullOrWhiteSpace(iconCss) ?
          <i className={iconCss}></i>
          :
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path d="M12 4L4 12" stroke="inherit" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M4 4L12 12" stroke="inherit" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          </svg>}
      </div>
    );
  }
  else {
    const buttonContent = () => {
      return (
        <ButtonComponent
          ref={buttonComponentRef}
          type={type}
          {...(isNotNullAndUndefined(className)
            ? {
              cssClass: className,
            }
            : {})}
          // {...isNotNullAndUndefined(ref) ? {
          //   ref: ref
          // } : {}}
          {...((isNotNullAndUndefined(showIcon) && showIcon === false) ||
            isNullOrUndefined(iconCss)
            ? {}
            : {
              iconCss: iconCss,
            })}
          {...(isNotNullAndUndefined(isPrimary) && isPrimary === true
            ? {
              isPrimary,
            }
            : {})}
          // {...( disabled === true
          //   ? {
          //       disabled,
          //     }
          //   : {})}
          disabled={
            disabled === true ? true : false
          }
          {...((isNotNullAndUndefined(iconBtn) && iconBtn === true) ||
            isNullOrUndefined(displayName) ||
            isNotNullAndUndefined(props.btnContentTemplate)
            ? {}
            : {
              content: displayName,
            })}
          key={btnKeyID}
          id={btnKeyID}
          iconPosition={props.iconPosition}
          onClick={onClick}
        >
          {isNotNullAndUndefined(props.btnContentTemplate)
            ? props.btnContentTemplate
            : ""}
        </ButtonComponent>
      );
    };

    if (enableToolTip) {
      return (
        <Fragment key={`${btnKeyID}_${disabled === true ? 'true' : 'false'}`}>
          <TooltipComponent title={displayName}>
            {buttonContent()}
          </TooltipComponent>
        </Fragment>
      );
    }
    return (
      <Fragment key={`${btnKeyID}_${disabled === true ? 'true' : 'false'}`}>
        {buttonContent()}
      </Fragment>
    );
  }
});

export default React.memo(RAFButtonComponent);

import React, { PropsWithChildren } from 'react';
import { getDisplayNameByModuleName } from '../../helpers/RAFMenuHelper';
import { IsNotNullOrWhiteSpace, isNotNullAndUndefined } from '../../helpers/utils';
import "./RAFRelatedToWithIcon.scss";
import { getModuleAvatarClass, getModuleAvatarIcon } from '../../../helpers/Common/AvatarHelper';

interface IProps {
    item?: any;
    showTextAsLink?: boolean;
    relatedToType?: string;
    relatedTo?: string;
}


function RAFRelatedToWithIcon(
    { relatedToType, relatedTo, showTextAsLink, item, ...props }: PropsWithChildren<IProps>,) {

    function RelatedToIcon(moduleName) {
        let moduleIconClass = getModuleAvatarIcon(moduleName);
        let moduleAvatarClass = getModuleAvatarClass(moduleName);
        return (
            <span
                className={moduleAvatarClass + " avatar-text-square"}
                style={{
                    height: "28px",
                    width: "28px",
                    minHeight: "28px",
                    minWidth: "28px",
                    fontSize: "0.875rem",
                    marginRight: "0.3125rem",
                }}
            >
                <i className={`fa fa-${isNotNullAndUndefined(moduleIconClass) && IsNotNullOrWhiteSpace(moduleIconClass) ? moduleIconClass : 'file'}`}></i>
            </span>
        );
    };

    return (
        <div className="w-100 rafrelatedTo overflow-hidden"
        >
            <div className="row g-1 flex-nowrap align-items-center">
                <div className="col-auto"
                >
                    {
                        isNotNullAndUndefined(item) && isNotNullAndUndefined(item.RelatedToType) ?
                            RelatedToIcon(item.RelatedToType)
                            :
                            isNotNullAndUndefined(relatedToType) ?
                                RelatedToIcon(relatedToType)
                                :
                                null

                    }
                </div>
                <div className="col overflow-auto">
                    <div className="row g-0">
                        <div className="col-12">
                            <div className="rafrelatedTo__Content d-flex">
                                <div className="rafrelatedTo__Content__secondary ecllipseFirstLine">
                                    <span>
                                        {
                                            isNotNullAndUndefined(item) && isNotNullAndUndefined(item.RelatedToType) ?
                                                getDisplayNameByModuleName(item.RelatedToType)

                                                :
                                                isNotNullAndUndefined(relatedToType) ?
                                                    getDisplayNameByModuleName(relatedToType)
                                                    :
                                                    ""

                                        }
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="swapTextwithHyperlink rafrelatedTo__Content d-flex">
                                <span className={showTextAsLink && ((isNotNullAndUndefined(item) && isNotNullAndUndefined(item.RelatedTo)) || isNotNullAndUndefined(relatedTo)) ? "rafrelatedTo__Content__primary ecllipseFirstLine text-wrap blue-text word-break-all" : "rafrelatedTo__Content__primary ecllipseFirstLine"}>
                                    {
                                        isNotNullAndUndefined(item) && isNotNullAndUndefined(item.RelatedTo) ?
                                            item.RelatedTo
                                            :
                                            isNotNullAndUndefined(relatedTo) ?
                                                relatedTo
                                                :
                                                "Not set"

                                    }
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default React.memo(RAFRelatedToWithIcon);
